import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import './messages.css'

const MessagesBanner = (props) => {
    const callShowChat = (status) =>{
        props.onMessageClick(status)
    }
    return (
        <div onClick={()=>callShowChat(true)} className="messages-banner {active} d-flex align-items-center">
            <div className="message-img">
                <img src={props.image} alt='' className='img-fluid' />
            </div>
            <div className="message-data">
                <div className="message-head d-flex align-items-center justify-content-between">
                    <span className="name">{props.name}</span>
                    <span className="time">{props.time}</span>
                </div>
                <p>{props.para}</p>
                <span className="message-dot"><FontAwesomeIcon icon={faCircle} /></span>
            </div>
        </div>
    )
}

export default MessagesBanner