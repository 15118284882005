import { BEFORE_FOOTER, GET_FOOTER, SUBMIT_FOOTER, SUBMIT_CONTACT } from '../types';

const initialState = {
    getFooter: {},
    submitRes: {},
    getFooterAuth: false,
    submitAuth: false,
    submitContactAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FOOTER:
            return {
                ...state,
                getFooter: action.payload,
                getFooterAuth: true
            }
        case SUBMIT_FOOTER:
            return {
                ...state,
                submitAuth: true,
                submitRes: action.payload
            }
        case SUBMIT_CONTACT:
            return {
                ...state,
                submitContactAuth: true
            }
        case BEFORE_FOOTER:
            return {
                ...state,
                getFooter: {},
                submitRes: {},
                getFooterAuth: false,
                submitAuth: false,
                submitContactAuth: false
            }
        default:
            return {
                ...state
            }
    }
}