import React from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header_1/Header";
import Footer from "../components/footer_1/Footer";
import { useLocation} from "react-router-dom";

const Layout3 = (props) => {
    const { children } = props;
    let location = useLocation();

    return (
        <div className="main-wrapper">
            <Header/>
            {/* <Container fluid className={`${location.pathname === "/terms-condition" ? "bg-background" : "main-page"}`}> */}
            <div className={(location.pathname === "/terms-condition") || (location.pathname === "/privacy-policy") ? "bg-background" : "main-page"}>
                {children}
            </div>
            <Footer/>
        </div>
    )
}
export default Layout3;