import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import communityBg from '../../../assets/images/community-bg.png'
import './community.css'
const Community = () => {
    return (
        <section className='community-area'>
            <Container fluid>
                <Row>
                    <h3  className='custom-heading text-center'> Enhance Your Community’s Potential</h3>
                    <Col lg={12}>
                    
                        <div className='community-content'>
                            <div className='animated-layer'></div>
                            <div className='comminity-bg'><img src={communityBg} className='img-fluid h-100' alt='image'/></div>
                            <div className='box-area'>
                                <div className='custom-box one'>
                                    <h4>Crowdfunding</h4>
                                    <p>Obtain and allocate funds for a project through the collective efforts of your community in a transparent and auditable manner.</p>
                                </div>
                                <div className='custom-box two'>
                                    <h4>Community-Driven Meetups</h4>
                                    <p>Let communities manage events by implementing layers of governing protocols, assigning tasks through bounties, and gathering input through polls.</p>
                                </div>
                                <div className='custom-box three'>
                                    <h4>Multisig Wallet</h4>
                                    <p>Get more secure storage for your funds with MutoDAO as a multi-signature vault. Stake your funds and earn rewards while saving your money.</p>
                                    <div className='pt-3 custom-box-btn'><Link to="/app" type='button' className='custom-btn btn  py-2' href="">Launch App</Link></div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Community