import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Proposal from "../proposal/proposal";
import VotingThreshold from "../votingThreshold/votingThreshold";
import { connect } from 'react-redux';
import { getProposals, beforeProposal, clearProposal } from '../../redux/proposal/proposal.action';
import FullPageLoader from "../full-page-loader/full-page-loader";
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { ENV } from "../../config/config";
import moment from "moment";
import {
    Button,
    Card,
    Form,
    Table,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Overlay,
    Tooltip,
    Modal,
    FormGroup,
} from "react-bootstrap";
import Select from 'react-select'

function ProposalList(props) {
    const navigate = useNavigate()
    const [proposals, setProposals] = useState([])
    const [page, setPage] = useState(1)
    const [loader, setLoader] = useState(true)
    const [pagination, setPagination] = useState({})
    const [filter, setFilter] = useState({ communityName: '', proposerAddr: '', status: '', daoId: "", type: '' })
    const [resetButton, setResetButton] = useState(false)

    const proposal_status = [{ label: "Approved", value: "2" }, { label: "Failed", value: "3" }]
    const proposal_type = [{ value: "1", label: "Transfer/Bounty" }, { value: "2", label: "Governance" }, { value: "3", label: "Claim Bounty" }, { value: "4", label: "Create Group/ Change Member of DAO" }, { value: "5", label: "Vote" }, { value: "6", label: "Funtion Call" }]

    useEffect(() => {
        if (props.from == "lsitingPage") {
            setLoader(true)
            props.clearProposal()
            props.getProposals()
        }
    }, [])

    useEffect(() => {
        if (props.from == "proposalTab" && props.daoId !== undefined) {
            let payload = { daoId: props.daoId }
            setFilter({ ...filter, daoId: props.daoId })
            let qs = ENV.objectToQueryString(payload)
            props.clearProposal()
            props.getProposals(qs)
        }
    }, [props.daoId])

    useEffect(() => {
        if (props.proposal.getProposalsAuth) {
            setProposals([...props.proposal.proposals])
            setPagination(props.proposal.paginationData)
            setLoader(false)
        } else {
            setLoader(true)
        }
    }, [props.proposal.getProposalsAuth])

    const calculateVotePercentage = (totalVotes, val) => {
        if (val > 0) {
            console.log("vote percentage = ", val / totalVotes * 100)
            return (val / totalVotes * 100).toFixed(2)
        } else {
            return 0
        }
    }

    const onPageChange = async (page) => {
        setLoader(true)
        page = page + 1;
        let qs;
        // let daoId = props.daoId
        qs = ENV.objectToQueryString({ ...filter, page })
        props.beforeProposal()
        props.getProposals(qs)
    }

    const checkKeyCode = (e) => {
        if (e.keyCode === 13) {
            searchProposals("");
            e.preventDefault()
        }
    }

    const searchProposals = () => {
        setLoader(true)
        let payload = { ...filter }

        let qs = ENV.objectToQueryString(payload)
        props.getProposals(qs)
        props.clearProposal()
        setResetButton(true)
    }

    const reset = () => {
        setResetButton(false)
        setFilter({ communityName: '', proposerAddr: '', status: '', type: '' })
        setLoader(true)
        props.clearProposal()
        props.getProposals()
    }

    console.log('props.proposal', props.proposal, props.userData.loginAuth)

    return (
        <div className="">
            {loader ? <FullPageLoader /> : ''}
            <>
                <div className="community-search-form">
                    <Row>
                        {
                            props.daoId ? "" :
                                <Col md={4} className="mb-3">

                                    <Form className="search-form position-relative proposal-form">
                                        <div className="input-holder input">
                                            <input
                                                type="text"
                                                className=".explore-communities .community-search-form .form-control"
                                                placeholder="Search By Community"
                                                onKeyDown={(e) => checkKeyCode(e)}
                                                value={filter.communityName}
                                                onChange={(e) => setFilter({ ...filter, communityName: e.target.value })}
                                            />
                                        </div>
                                    </Form>
                                </Col>
                        }
                        <Col md={4} className="mb-3">
                            <Form className="search-form position-relative proposal-form">
                                <div className="input-holder input">
                                    <input
                                        type="text"
                                        className=".explore-communities .community-search-form .form-control"
                                        placeholder="Search By Proposer Address"
                                        onKeyDown={(e) => checkKeyCode(e)}
                                        value={filter.proposerAddr}
                                        onChange={(e) => setFilter({ ...filter, proposerAddr: e.target.value })}
                                    />
                                </div>
                            </Form>
                        </Col>
                        <Col md={4} className="search-wrap mb-3">
                            <Form className="search-form position-relative">
                                <Form.Group className="create-proposal">
                                    <Select className="w-100 h-100" placeholder="Search by Status" options={proposal_status}classNamePrefix="freedom-select"  value={proposal_status?.filter(status => status.value === filter.status)}
                                    onChange={(e) => {setFilter({ ...filter, status: e.value })}}
                                    />
                                    {/* <Form.Control
                                        as="select"
                                        className="select-status"
                                        onChange={(e) => setFilter({ ...filter, status: e.target.value === "Approved" ? 2 : e.target.value === "Failed" ? 3 : '' })}
                                        value={filter.status === 2 ? "Approved" : filter.status === 3 ? "Failed" : ''}
                                    >
                                        <option value="--">Search by Status</option>
                                        <option>Approved</option>
                                        <option>Failed</option>
                                    </Form.Control> */}
                                </Form.Group>
                            </Form>
                        </Col>

                        <Col md={4} className="search-wrap mb-3">
                            <Form className="search-form position-relative">
                                <Form.Group className="create-proposal">
                                    <Select className="w-100" placeholder="Search by Type" classNamePrefix="freedom-select"
                                        onChange={(e) => setFilter({ ...filter, type: e.value })} value={proposal_type?.filter
                                        (type => type.value === filter.type)}
                                        options={proposal_type}
                                    />
                                    {/* <Form.Control
                                        as="select"
                                        className="select-status"
                                        onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                                        value={filter.type}
                                    >
                                        <option value="--">Search by Type</option>
                                        <option value="1">Transfer/Bounty</option>
                                        <option value="2">Governance</option>
                                        <option value="3">Claim Bounty</option>
                                        <option value="4">Create Group/ Change Member of DAO</option>
                                        <option value="5">Vote</option>
                                        <option value="6">Funtion Call</option>
                                    </Form.Control> */}
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col md={4}>
                            <div className="d-flex mb-4">
                                <div className="me-3"><button onClick={() => searchProposals()} className=" freedom-btn freedom-solid-btn transition">Search</button></div>
                                <div>{resetButton && <button onClick={() => reset()} className=" freedom-btn freedom-outline-btn transition">Reset</button>}</div>
                            </div>
                        </Col>
                        {/* <Col xxl={2} xl={3} lg={4} md={4}
                            className="mb-3">
                            <button onClick={() => searchProposals()} className="w-100 freedom-btn freedom-solid-btn transition">Search</button>
                        </Col>
                        <Col
                            xxl={2} xl={3} lg={4} md={4} className="mb-3">
                            {resetButton && <button onClick={() => reset()} className="w-100 freedom-btn freedom-outline-btn transition">Reset</button>}
                        </Col> */}
                    </Row>
                </div>
                {
                    props?.proposal?.proposals?.length > 0 ?
                        <>
                            {
                                props?.proposal?.proposals?.map((e, index) => {
                                    const { disLikedVotes, likedVotes, abstainVotes, voteFloor } = e.vote
                                    const totalVote = disLikedVotes + likedVotes + abstainVotes
                                    return (
                                        <div className="poolList-1 pooling1" key={e._id}>
                                            <div >
                                                <div className="content pb-0"> <Proposal data={e} /></div>
                                                <div className="content pt-0">
                                                    <div className="footer">
                                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                            <div className="d-flex justify-content-center align-items-center flex-wrap">
                                                                {
                                                                    e?.status === 1 && moment(e?.endTime) >= Date.now() && props.userData.loginAuth ?
                                                                        <button onClick={() => navigate(`/proposal-vote/${e._id}`)} className="freedom-solid-btn  transition mb-3 mb-md-0 me-3"> Vote <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M14.4301 18.8191C14.2401 18.8191 14.0501 18.7491 13.9001 18.5991C13.6101 18.3091 13.6101 17.8291 13.9001 17.5391L19.4401 11.9991L13.9001 6.45914C13.6101 6.16914 13.6101 5.68914 13.9001 5.39914C14.1901 5.10914 14.6701 5.10914 14.9601 5.39914L21.0301 11.4691C21.3201 11.7591 21.3201 12.2391 21.0301 12.5291L14.9601 18.5991C14.8101 18.7491 14.6201 18.8191 14.4301 18.8191Z" />
                                                                            <path d="M20.33 12.75H3.5C3.09 12.75 2.75 12.41 2.75 12C2.75 11.59 3.09 11.25 3.5 11.25H20.33C20.74 11.25 21.08 11.59 21.08 12C21.08 12.41 20.74 12.75 20.33 12.75Z" />
                                                                        </svg></button> : ''
                                                                }
                                                                <button className="freedom-outline-btn transition mb-3 mb-md-0 me-3" onClick={() => navigate(`/proposal-detail/${e._id}`)}>View Detail</button>
                                                            </div>
                                                            <VotingThreshold like={calculateVotePercentage(totalVote, likedVotes)} dislike={calculateVotePercentage(totalVote, disLikedVotes)} abstain={calculateVotePercentage(totalVote, abstainVotes)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {pagination.page + 1 > pagination.pages ? "" : <div className="text-center"><button className='freedom-btn freedom-solid-btn transition' onClick={() => onPageChange(pagination.page)}>Load More</button></div>}

                        </> :
                        <div className="d-flex justify-content-center align-items-center not-found">
                            <p>No proposal available!</p>
                        </div>
                }
            </>
        </div>
    )
}

const mapStateToProps = state => ({
    proposal: state.proposal,
    userData: state.loginKey
})

export default connect(mapStateToProps, { getProposals, beforeProposal, clearProposal })(ProposalList)