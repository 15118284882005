import React, { useEffect } from "react";
import './proposal.css'
import ProposalList from "../../components/proposalList/proposalList";

function ProposalListing(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="proposal-listing">
            <div className="top-heading d-flex justify-content-between flex-wrap">
                <div className="page-header d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-0 mb-md-4">
                    <h2 className="me-0 me-md-2 mb-3 mb-md-0 font-weight-bold">Proposals - MUTO Network</h2>
                </div>
                {/* <Link to="/create-proposal" className="freedom-btn freedom-solid-btn transition text-center">Submit Proposals</Link> */}
            </div>
            <ProposalList from="lsitingPage" />
        </div>
    )
}

export default ProposalListing