import { BEFORE_INVESTORS, INVESTOR_SIGNUP, GET_INVESTORS, GET_ERRORS, CHECK_OTP_VALIDATION } from "../types";
import { ENV } from "../../config/config";
import { emptyError } from '../shared/error/error.action';

export const beforeInvestors = () => {
    return {
        type: BEFORE_INVESTORS
    }
}

export const createInvestor = (body, method = 'POST') => dispatch => {
    dispatch(emptyError());

    const url = `${ENV.url}investor/signup`;
    fetch(url, {
        method,
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'content-type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data) {
            dispatch({
                type: INVESTOR_SIGNUP,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const getInvestors = () => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}investor/all`;
    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_INVESTORS,  
                payload: data.investors
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const checkOtpValidation = (body, method = 'POST') => dispatch => {
    dispatch(emptyError());

    const url = `${ENV.url}investor/otp-valdiation`;
    fetch(url, {
        method,
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'content-type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            console.log("check validation = ", data);
            dispatch({
                type: CHECK_OTP_VALIDATION,
                payload: data.isValid
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};