import React from "react";
import CreatedSuccessfully from '../../assets/images/Frame.png'
import '../../pages/proposals/proposal.css'
import { useNavigate } from 'react-router-dom'

function VoteRecorded({proposalId}){
    const navigate = useNavigate()
    return(
        <div className="vote-recorded">
            <h2>Vote Recorded</h2>
            <div className="frame">
                <img src={CreatedSuccessfully} alt='' />
            </div>
            <p>Your vote was successfully recorded!</p>
            <div className="d-flex justify-content-center align-items-center flex-wrap">
                <button className="freedom-btn freedom-outline-btn transition ms-3 mb-3" onClick={()=> navigate("/proposals")}>Back To Proposal List</button>
                <button className="freedom-btn freedom-solid-btn transition ms-3 mb-3" onClick={()=> navigate(`/proposal-detail/${proposalId}`)}>View Proposal</button>
            </div>
        </div>
    )
}

export default VoteRecorded