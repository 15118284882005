import React, { useState, useEffect, useRef } from "react";
import CreatedSuccessfully from '../../assets/images/Frame.png'
import './proposal.css'
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { connect } from 'react-redux'
import { addProposal, beforeProposal, beforeCheckValidation, checkProposalValidation } from '../../redux/proposal/proposal.action'
import { createProposal, getCommunityDetail } from '../../utils/web3'
import { ENV } from "../../config/config";
import Select from 'react-select'
import { WithContext as ReactTags } from 'react-tag-input';
import proposalTypesArr from "../../utils/data/proposalTypes";
import moment from "moment";
import AlertModal from "../../components/alertModal/alertModal";
import { getCommunity, beforeCommunity } from '../../redux/communities/community.action';
import { Link } from 'react-router-dom'
import { uploadToCloudinary } from '../../utils/upload'
import Permission from "../../components/permissions/permissions";
import { Row, Col, Form, Accordion, Container } from 'react-bootstrap'
import FullPageLoader from '../../components/full-page-loader/full-page-loader'
import WalletConnect from '../../components/walletConnect/walletConnect';
import AddLinks from '../../components/addLinks/addLinks';

function CreateProposal(props) {

    const descriptionRef = useRef()
    const memberAddrRef = useRef()
    const memberRemoveRef = useRef()

    const { address } = ENV.getUserKeys('address');
    const { _daoId, addressComm } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    let type = searchParams.get('proposalType');
    if (!type || type < 0 || type > 13) { type = 1 } else { }

    const navigate = useNavigate()

    const [comm, setComm] = useState();
    const [groupsList, setGroupsList] = useState();
    const [proposalTypes, setProposalTypes] = useState([...proposalTypesArr])
    const [proposalType, setProposalType] = useState(proposalTypes[1])
    // name, description, amount, targetAcc, bountyAmount, availableClaims, daystoComplete, proposedDaoName, proposedPurpose, links,proposedCover, proposedLogo, proposedLegalStatus, proposedLegalDocs, bondsToCreateProposal, timeBeforeProposalExpires, bondsToClaimBounty, timeToUnclaimBounty, newGroupName, intialMemberAccount, targetGroupName, memberToAdd, memberToRemove, contractAddrFnCall, fnCallName, fnCallAbi
    const [proposal, setProposal] = useState({ name: proposalType?.label, description: "", amount: "", targetAcc: "", bountyAmount: "", availableClaims: "", daystoComplete: "", proposedDaoName: "", proposedPurpose: "", links: [], proposedCover: "", proposedLogo: "", proposedLegalStatus: "", proposedLegalDocs: "", bountyId: 0, newGroupName: "", intialMemberAccount: "", targetGroupName: "", targetGroupId: 0, memberToAdd: "", memberToRemove: "", contractAddrFnCall: "", fnCallName: "", fnCallAbi: "", proposalCreationRights: [], votingPermissions: [] }) //bondsToCreateProposal: "", timeBeforeProposalExpires: "", bondsToClaimBounty: "", timeToUnclaimBounty: "",

    const [showAlert, setShowAlert] = useState()
    const [showWalletConnect, setShowWalletConnect] = useState()
    const [err, setErr] = useState([])
    const [loader, setLoader] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [alertMsg, setAlertMsg] = useState()

    const [votingPermission, setVotingPermission] = useState([])
    const [creationPermission, setCreationPermission] = useState([])

    const [fullLoader, setFullLoader] = useState(false)

    const [linksArray, setLinksArray] = useState([0])
    const [linksValidated, setLinksValidated] = useState(true)
    const [maxDaoFund, setMaxDaoFund] = useState(0)


    // let type = searchParams.get('proposalType');
    // console.log("type = ", type )
    // if (type) {
    //     for (let index = 0; index < proposalTypesArr?.length; index++) {
    //         const e = proposalTypesArr[index];
    //         console.log("e = ", e)
    //         console.log("e?.value == type ", e?.value == type)
    //         if (e?.value == type) {
    //             setProposalType(e)
    //         }
    //     }
    // }
    // initialize the proposal state
    const initializeProposal = () => {
        setProposal({ name: proposalType?.label, amount: "", targetAcc: "", bountyAmount: "", availableClaims: "", daystoComplete: "", proposedDaoName: "", proposedPurpose: "", links: [], proposedCover: "", proposedLogo: "", proposedLegalStatus: "", proposedLegalDocs: "", newGroupName: "", targetGroupId: 0, intialMemberAccount: "", targetGroupName: "", memberToAdd: "", memberToRemove: "", contractAddrFnCall: "", fnCallName: "", fnCallAbi: "", bountyId: 0, proposalCreationRights: creationPermission, votingPermissions: votingPermission })
        //description: ""
        descriptionRef.current.value = ""
        // memberAddrRef.current.value = ''
        // memberRemoveRef.current.value = ''
    }
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (props.userData.loginAuth) {
            setFullLoader(true)
            props.beforeCommunity()
            props.getCommunity(addressComm);
        }
    }, [props.userData.loginAuth])

    useEffect(() => {
        for (let index = 0; index < proposalTypesArr.length; index++) {
            const data = proposalTypesArr[index];
            if (data?.value == type) {
                console.log("data", data)
                console.log("proptype = " , proposalTypes[1])
                setProposalType({ ...data })
            }
        }
        let bountyId = searchParams.get('bountyId');
        if (!bountyId) {
        } else {
            // bountyId Exist then proposal shoould be only claims 
            let arr = [{ value: 8, label: 'Propose to Create a Claim', isdisabled: true, name: "Create a Claim", fields: [], },]
            setProposalTypes([...arr])
            setProposalType(arr[0])
            setProposal({ ...proposal, bountyId: bountyId })
        }

        setFullLoader(true)
        props.beforeCheckValidation()
        props.beforeCommunity()
        props.getCommunity(addressComm);
    }, [])


    useEffect(() => {
        if (props.community.getCommunityAuth) {
            const comm = props.community.community;
            let gList = []
            for (let index = 0; index < comm?.groups?.length; index++) {
                const e = comm?.groups?.[index];
                gList.push({ label: e?.name, value: e?._groupId })
            }
            setGroupsList([...gList])
            setComm(comm)
            getCommDetail(comm?.community?._daoId)
            setFullLoader(false)
        }
    }, [props.community.getCommunityAuth])

    useEffect(() => {
        props.beforeProposal()
        props.beforeCheckValidation()
    }, [])

    useEffect(() => {
        if (linksArray) {
            setProposal({ ...proposal, links: linksArray })
        }
    }, [linksArray])

    useEffect(() => {
        if (props.proposal.addProposalAuth) {
            setLoader(false)
            setIsSubmitted(true)
            props.beforeProposal()
        }
    }, [props.proposal.addProposalAuth])

    useEffect(() => {
        if (props.proposal.checkValidationAuth) {
            if (props.proposal.isProposalValid) {
                props.beforeCheckValidation()
                callCreateProposal()
            } else {
                setLoader(false)
                // set Error message
                const error = err
                error["proposalValidation"] = props.proposal.validationMsg
                setErr({ ...error })
            }
        }
    }, [props.proposal.checkValidationAuth])


    const getCommDetail = async (daoId) => {
        const res = await getCommunityDetail(daoId)
        setMaxDaoFund(res)
    }

    const legalUrlValidity = async (val) => {
        const isValidUrl = await ENV.isValidURL(val)
        if (!isValidUrl) {
            let error = err
            error["proposedLegalDocs"] = "Proposed Legal Docs must be a url."
            setErr(error)
        }
    }

    const changeData = async (type, value) => {
        let data = proposal
        data[type] = value
        setProposal(data)
    }

    const checkPermission = () => {
        let haveValidPermission = false
        let pType = proposalType?.value
        console.log("proposal type = ", proposalType?.value)
        console.log("comm?.permissions", comm?.permissions)
        if (comm?.permissions) {
            Promise.all(comm?.permissions?.map((e) => {
                Promise.all(e?.proposalPermission?.map((e) => {
                    if (e === pType) {
                        haveValidPermission = true
                        return
                    }
                }))
            }))
        }
        return haveValidPermission
    }

    const checkValidation = async () => {

        let isValid = true
        const error = []

        // proposal object destructuring
        let { description, amount, targetAcc, bountyAmount, availableClaims, daystoComplete, proposedDaoName, proposedPurpose, links, proposedCover, proposedLogo, proposedLegalStatus, proposedLegalDocs, bondsToCreateProposal, timeBeforeProposalExpires, bondsToClaimBounty, timeToUnclaimBounty, newGroupName, targetGroupId, intialMemberAccount, targetGroupName, memberToAdd, memberToRemove, contractAddrFnCall, fnCallName, fnCallAbi, bountyId } = proposal

        let type = proposalType?.value

        description = description.trim()
        if (!description?.length) {
            error["description"] = "Proposal Description is Required"
            isValid = false
        }
        // else{
        // if (description.length < 30) {
        //     error["description"] = "Proposal Description should be 30 characters long."
        //     isValid = false
        // }
        // }

        if (description?.length > 500) {
            error["description"] = "Proposal Description should not be more than 500 characters."
            isValid = false
        }

        if (type === 1) { // amount, targetAcc

            if (amount === "") {
                error["amount"] = "Amount is Required"
                isValid = false
            } else if (amount > maxDaoFund) {
                error["amount"] = "DAO doesn't have enough funds."
                isValid = false
            } else { }

            if (targetAcc === "") {
                error["targetAcc"] = "Target Account is Required"
                isValid = false
            } else {
                let isValidAddr = await ENV.isEthereumAddress(targetAcc)
                if (!isValidAddr) {
                    error["targetAcc"] = "Please provide the Ethereum Address."
                    isValid = false
                }
            }

        } else if (type === 2) { // bountyAmount, availableClaims, daystoComplete
            console.log('taptap')
            if (bountyAmount === "") {
                error["bountyAmount"] = "Bounty Amount is Required"
                isValid = false
            }

            if (availableClaims === "") {
                error["availableClaims"] = "Available Claims is Required"
                isValid = false
            }
            if (daystoComplete === "") {
                error["daystoComplete"] = "Day to Complete is Required"
                isValid = false
            }

        } else if (type === 3) { // proposedDaoName,
            // proposedDaoName = proposedDaoName.trim()
            var regName = /^[a-zA-Z][a-zA-Z ]*$/;
            if (proposedDaoName === "") {
                error["proposedDaoName"] = "New DAO name is Required"
                isValid = false
            } else if (!regName.test(proposedDaoName)) {
                error["proposedDaoName"] = "Invalid DAO name."
                isValid = false
            }else {
                if (proposedDaoName.length < 5) {
                    error["proposedDaoName"] = "New DAO name should be 5 characters long."
                    isValid = false
                }
                if (proposedDaoName.length > 30) {
                    error["proposedDaoName"] = "New DAO name should be less than 30 characters long."
                    isValid = false
                }
            }

        } else if (type === 4) { // proposedPurpose
            proposedPurpose = proposedPurpose.trim()
            if (proposedPurpose === "") {
                error["proposedPurpose"] = "New DAO purpose is Required"
                isValid = false
            } else if (proposedPurpose.length > 500) {
                error["description"] = "Proposal purpose should not be more than 500 characters."
                isValid = false
            }


        } else if (type === 5) { // links
            if (!links.length) {
                error["links"] = "Atleast 1 Link is Required."
                isValid = false
            }
            if (!linksValidated) {
                isValid = false
            }

        } else if (type === 6) { // proposedCover, proposedLogo

            if (proposedCover === "") {
                error["proposedCover"] = "Proposed Cover Image is Required"
                isValid = false
            }
            if (proposedCover?.target?.files[0]?.type === "image/png" || proposedCover?.target?.files[0]?.type  === "image/jpg" || proposedCover?.target?.files[0]?.type  === "image/jpeg") {

            }else{
                error["proposedCover"] = "Proposed cover image must be of type jpg/jpeg/png"
                isValid = false
            }
            
            if (proposedLogo === "") {
                error["proposedLogo"] = "Proposed Logo Image is Required"
                isValid = false
            }


            if (proposedLogo?.target?.files[0]?.type === "image/png" || proposedLogo?.target?.files[0]?.type  === "image/jpg" || proposedLogo?.target?.files[0]?.type  === "image/jpeg") {

            }else{
                error["proposedLogo"] = "Proposed Logo image must be of type jpg/jpeg/png"
                isValid = false
            }

        } else if (type === 7) { // proposedLegalStatus, proposedLegalDocs
            proposedLegalStatus = proposedLegalStatus.trim()
            if (proposedLegalStatus === "") {
                error["proposedLegalStatus"] = "Proposed Legal Status is Required"
                isValid = false
            }else if (proposedLegalStatus.length > 500) {
                error["proposedLegalStatus"] = "Proposed Legal Status should not be more than 500 characters."
                isValid = false
            }

            proposedLegalDocs = proposedLegalDocs.trim()
            if (proposedLegalDocs === "") {
                error["proposedLegalDocs"] = "Proposed Legal Docs is Required"
                isValid = false
            } else {
                const isValidUrl = await ENV.isValidURL(proposedLegalDocs)
                if (!isValidUrl) {
                    error["proposedLegalDocs"] = "Proposed Legal Docs must be a url."
                    isValid = false
                }
            }

        } else if (type === 8) {// bondsToCreateProposal, timeBeforeProposalExpires, bondsToClaimBounty, timeToUnclaimBounty

            // if (bondsToCreateProposal === "") {
            //     error["bondsToCreateProposal"] = "Bonds To Create Proposal is Required"
            //     isValid = false
            // }
            // if (timeBeforeProposalExpires === "") {
            //     error["timeBeforeProposalExpires"] = "Time Before Proposal Expires is Required"
            //     isValid = false
            // }
            // if (bondsToClaimBounty === "") {
            //     error["bondsToClaimBounty"] = "Bonds To Claim Bounty is Required"
            //     isValid = false
            // }
            // if (timeToUnclaimBounty === "") {
            //     error["timeToUnclaimBounty"] = "Time to unclaim bounty without any penalty is Required"
            //     isValid = false
            // }

        } else if (type === 9) { // newGroupName, intialMemberAccount,
            newGroupName = newGroupName.trim()
            if (newGroupName === "") {
                error["newGroupName"] = "Group Name is Required"
                isValid = false
            }else if (newGroupName.length > 50) {
                error["description"] = "Group Name should not be more than 50 characters."
                isValid = false
            }

            if (intialMemberAccount === "") {
                error["intialMemberAccount"] = "Initial Member is Required"
                isValid = false
            } else {
                let isValidAddr = await ENV.isEthereumAddress(intialMemberAccount)
                if (!isValidAddr) {
                    error["intialMemberAccount"] = "Please provide the Ethereum Address."
                    isValid = false
                }
            }

        } else if (type === 10) { // targetGroupId, memberToAdd

            if (targetGroupId === "") {
                error["targetGroupId"] = "Target Group Id is Required"
                isValid = false
            }
            if (memberToAdd === "") {
                error["memberToAdd"] = "Member Address is Required"
                isValid = false

            } else {
                let isValidAddr = await ENV.isEthereumAddress(memberToAdd)
                if (!isValidAddr) {
                    error["memberToAdd"] = "Please provide the Ethereum Address."
                    isValid = false
                }
            }

        } else if (type === 11) { // targetGroupId, memberToRemove

            if (targetGroupId === "") {
                error["targetGroupId"] = "Target Group Name is Required"
                isValid = false
            }
            if (memberToRemove === "") {
                error["memberToRemove"] = "Member Address is Required"
                isValid = false
            } else {
                let isValidAddr = await ENV.isEthereumAddress(memberToRemove)
                if (!isValidAddr) {
                    error["memberToRemove"] = "Please provide the Ethereum Address."
                    isValid = false
                }
            }

        } else if (type === 12) {
            // no validation check due to noe extra field

        } else if (type === 13) { // contractAddrFnCall, fnCallName, fnCallAbi

            if (contractAddrFnCall === "") {
                error["contractAddrFnCall"] = "Contract Address is Required"
                isValid = false
            } else {
                let isValidAddr = await ENV.isEthereumAddress(contractAddrFnCall)
                if (!isValidAddr) {
                    error["contractAddrFnCall"] = "Please provide the Ethereum Contract Address."
                    isValid = false
                }
            }
            fnCallName= fnCallName.trim()
            if (fnCallName === "") {
                error["fnCallName"] = "Method Name is Required"
                isValid = false
            }
            fnCallAbi = fnCallAbi.trim()
            if (fnCallAbi === "") {
                error["fnCallAbi"] = "Contract JSON ABI is Required"
                isValid = false
            }
        }
        setErr(error)
        return isValid
    }

    const checkProposalValidation = async () => {
        let type = proposalType?.value
        if (type == 6) {
            props.checkProposalValidation({ _daoId, type })
        } else {
            props.checkProposalValidation({ ...proposal, _daoId, type })
        }
    }

    const submitProposal = async (e) => {
        e.preventDefault()
        let check = await checkValidation()
        if (!props.userData.loginAuth) {
            setShowWalletConnect(true)
            setAlertMsg("Please Connect with Wallet.")
        } else {
            if (!checkPermission()) {
                setShowAlert(true)
                setAlertMsg("You dont have permission to do this action.")
            } else if (check) {
                setLoader(true)

                // call this to check the validation of proposal 
                props.beforeCheckValidation()
                checkProposalValidation()
            } else { }
        }

    }

    // call this when checkproposalvalidation auth true
    const callCreateProposal = async () => {
        // uint256 daoId, uint256 pollType, ProposeChanges memory proposedChanges
        let { amount, targetAcc, bountyAmount, availableClaims, daystoComplete, proposedDaoName, proposedPurpose, links, proposedCover, proposedLogo, proposedLegalStatus, proposedLegalDocs, newGroupName, intialMemberAccount, targetGroupName, targetGroupId, memberToAdd, memberToRemove, contractAddrFnCall, fnCallName, fnCallAbi, bountyId } = proposal
        let bountyNum = searchParams.get('bountyId');
        let type = searchParams.get('proposalType');
        if(type){

        }else { 
            type = proposalType.value
        }
        if (bountyNum) {
            bountyId = bountyNum
        }
        console.log('bountyId = ', bountyId)
        let groupObj = [0, "", [], [], []]
        if (type == 9) {
            let createPerm = Object.values(creationPermission)
            let votingPerm = Object.values(votingPermission)

            groupObj = [0, newGroupName, [intialMemberAccount], createPerm[0], votingPerm[0]]
        }
        let proposedChanges = { amount, targetAcc, bountyAmount, availableClaims, daystoComplete, alreadyClaimed: 0, proposedDaoName, proposedPurpose, links, proposedCover, proposedLogo, proposedLegalStatus, proposedLegalDocs, bountyId, groupObj, targetGroupName, targetGroupId, memberToAdd, memberToRemove, contractAddrFnCall, fnCallName, fnCallAbi: '' } //bondsToCreateProposal, timeBeforeProposalExpires, bondsToClaimBounty, timeToUnclaimBounty
        const address = props.userData?.userData?.address
        if (proposedChanges.proposedCover && proposedChanges.proposedLogo) {
            let coverData = await uploadToCloudinary(proposedChanges?.proposedCover.target.files[0])
            let logodata = await uploadToCloudinary(proposedChanges?.proposedLogo.target.files[0])
            proposedChanges.proposedCover = coverData.secure_url
            proposedChanges.proposedLogo = logodata.secure_url
        }

        let _proposal = await createProposal(_daoId, type, proposedChanges, address) // call contract function; 
        if (_proposal) {
            let { pollId, startTime, endTime } = _proposal

            startTime = moment.unix(startTime).format();
            endTime = moment.unix(endTime).format();
            const payload = { ...proposal, startTime, endTime, proposedCover: proposedChanges.proposedCover, proposedLogo: proposedChanges.proposedLogo, _daoId, _proposalId: pollId, proposer: address, type }
            payload["name"] = proposalType?.label
            let createPerm = Object.values(creationPermission)
            let votingPerm = Object.values(votingPermission)
            payload["proposalCreationRights"] = createPerm[0]
            payload["votingPermissions"] = votingPerm[0]
            payload.bountyId = bountyNum
            props.beforeProposal()
            props.addProposal(payload)
        } else {
            setLoader(false)
        }
    }

    // dynamically renders the fields of the proposals
    const proposalFields = () => {
        let type = proposalType?.value
        if (type === 0) {
            return
        } else if (type === 1) {
            return (
                <>
                    <Col lg={4} md={6} key="type1-1">
                        {/* <div className="d-flex justify-content-center align-items-center"> */}
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input
                                    type="text"
                                    autocomplete="off"
                                    placeholder="Amount in MTO "
                                    name="amount"
                                    onKeyDown={(e) => ENV.decimalNumberValidator(e)}
                                    defaultValue={proposal?.amount}
                                    onChange={(e) => {
                                        changeData(e.target.name, e.target.value.includes(".") ? e.target.value : parseFloat(e.target.value || 0));
                                    }} /><h3 className="text-danger ms-2">*</h3>
                                {/* <button onClick={() => {changeData("amount", maxDaoFund.includes(".") ? maxDaoFund : parseFloat(maxDaoFund || 0));}}>MAX</button> */}
                            </div>
                            {/* <input type="number" name="amount" placeholder="Amount" onChange={(e) => changeData(e.target.name, e.target.value)}></input> */}
                            {err["amount"] && <p className="text-danger">{err?.amount}</p>}
                        </div>
                        {/* </div> */}
                    </Col>
                    <Col lg={4} md={6} key="type1-2">
                        {/* <div className="d-flex justify-content-center align-items-center"> */}
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input autocomplete="off" name="targetAcc" placeholder="Target Account" defaultValue={proposal?.targetAcc} onChange={(e) => changeData(e.target.name, e.target.value)}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["targetAcc"] && <p className="text-danger">{err?.targetAcc}</p>}
                        </div>
                        {/* </div> */}
                    </Col>
                </>
            )
        } else if (type === 2) {
            return (
                <>
                    <Col md={6} key="type2-1">
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input
                                    type="text"
                                    autocomplete="off"
                                    placeholder="Amount in MTO"
                                    name="bountyAmount"
                                    onKeyDown={(e) => ENV.decimalNumberValidator(e)}
                                    defaultValue={proposal?.bountyAmount}
                                    onChange={(e) => {
                                        changeData(e.target.name, e.target.value.includes(".") ? e.target.value : parseFloat(e.target.value || 0));
                                    }}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["bountyAmount"] && <p className="text-danger">{err?.bountyAmount}</p>}
                        </div>
                    </Col>
                    <Col md={6} key="type2-2">
                        {/* */}
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input type="text"
                                    autocomplete="off"
                                    placeholder="Available Claims"
                                    name="availableClaims"
                                    onKeyDown={(e) => ENV.decimalNumberValidator(e)}
                                    defaultValue={proposal?.availableClaims}
                                    onChange={(e) => {
                                        changeData(e.target.name, e.target.value.includes(".") ? e.target.value : parseFloat(e.target.value || 0));
                                    }} ></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["availableClaims"] && <p className="text-danger">{err?.availableClaims}</p>}
                        </div>
                    </Col>
                    <Col md={6} key="type2-3">
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input type="text"
                                    autocomplete="off"
                                    placeholder="Days to Complete"
                                    name="daystoComplete"
                                    onKeyDown={(e) => ENV.decimalNumberValidator(e)}
                                    defaultValue={proposal?.daystoComplete}
                                    onChange={(e) => {
                                        changeData(e.target.name, e.target.value.includes(".") ? e.target.value : parseFloat(e.target.value || 0));
                                    }}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["daystoComplete"] && <p className="text-danger">{err?.daystoComplete}</p>}
                        </div>
                    </Col>
                </>
            )
        } else if (type === 3) {
            return (
                <>
                    <Col md={6} key="type3-1">
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input name="proposedDaoName" placeholder="Sample DAO Name" defaultValue={proposal?.proposedDaoName} autocomplete="off" onChange={(e) => changeData(e.target.name, e.target.value)}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["proposedDaoName"] && <p className="text-danger">{err?.proposedDaoName}</p>}
                        </div>
                    </Col>
                </>
            )
        } else if (type === 4) {
            return (
                <>
                    <Col md={12} key="type4-1">
                        <div className="textarea w-100">
                            <div className="d-flex align-items-center">
                                <textarea name="proposedPurpose" placeholder="Same New Purpose" defaultValue={proposal?.proposedPurpose} onChange={(e) => changeData(e.target.name, e.target.value)}></textarea>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["proposedPurpose"] && <p className="text-danger">{err?.proposedPurpose}</p>}
                        </div>
                    </Col>
                </>
            )
        } else if (type === 5) {
            return (
                <Col md={6} key="type5-1">
                    {/* <div className="textarea w-100">
                        <div className="d-flex align-items-center">
                            <div className="w-100 text-area">
                                <ReactTags
                                    tags={tags}
                                    handleDelete={handleDelete}
                                    handleAddition={handleAddition}
                                    handleDrag={handleDrag}
                                    handleTagClick={handleTagClick}
                                    inputFieldPosition="bottom"
                                    autocomplete
                                    placeholder="Add new Links"
                                    className="w-100"
                                />
                            </div>
                            <h3 className="text-danger ms-2">*</h3>
                        </div>
            </div> */}
                    {/* <label>Add Links</label> */}
                    <AddLinks setLinksValidated={setLinksValidated} setLinksArray={setLinksArray} linksArray={linksArray} />
                    {err["links"] && <p className="text-danger">{err?.links}</p>}
                </Col>
            )
        } else if (type === 6) {
            return (
                <Row>
                    <Col md={6} key="type6-1">
                        <div className="input w-100">
                            <label>Upload Cover Image</label>
                            <div className="d-flex align-items-center">
                                <input autocomplete="off" className="w-100" type="file" name="proposedCover" accept=".png,.jpeg,.jpg" defaultValue={proposal?.proposedCover} placeholder="Upload New Cover" onChange={(e) => changeData(e.target.name, e)}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["proposedCover"] && <p className="text-danger">{err?.proposedCover}</p>}
                        </div>
                    </Col>
                    <Col md={6} key="type6-2">
                        <div className="input w-100">
                            <label>Upload Logo</label>
                            <div className="d-flex align-items-center">
                                <input className="w-100" type="file" name="proposedLogo" accept=".png,.jpeg,.jpg" defaultValue={proposal?.proposedLogo} placeholder="Upload New Logo" autocomplete="off" onChange={(e) => changeData(e.target.name, e)}></input>
                                <h3 className="ms-2 text-danger">*</h3>
                            </div>
                            {err["proposedLogo"] && <p className="text-danger">{err?.proposedLogo}</p>}
                        </div>
                    </Col>
                </Row>
            )
        } else if (type === 7) {
            return (
                <>

                    <Col lg={4} md={6} key="type7-1">
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input autocomplete="off" name="proposedLegalStatus" placeholder="Enter Legal Status" defaultValue={proposal?.proposedLegalStatus} onChange={(e) => changeData(e.target.name, e.target.value)}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["proposedLegalStatus"] && <p className="text-danger">{err?.proposedLegalStatus}</p>}
                        </div>
                    </Col>
                    <Col lg={4} md={6} key="type7-2">
                        <div className="input w-100">
                            <div className="d-flex -align-items-center">
                                <input autocomplete="off" name="proposedLegalDocs" placeholder="Enter Legal Status Docs Link" defaultValue={proposal?.proposedLegalDocs} onChange={(e) => { legalUrlValidity(e.target.value); changeData(e.target.name, e.target.value) }}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["proposedLegalDocs"] && <p className="text-danger">{err?.proposedLegalDocs}</p>}
                        </div>
                    </Col>
                </>
            )
        } else if (type === 8) {
            // return (
            //     <Row>
            //         <Col md={6}>
            //             <h3 className="d-flex justify-content-center align-items-center">Proposals</h3>
            //             <div className="input w-100">
            //                 <div className="d-flex align-items-center">
            //                     <input type="number" name="bondsToCreateProposal" defaultValue={proposal?.bondsToCreateProposal} placeholder="Bonds to Create Proposal" onChange={(e) => changeData(e.target.name, e.target.value)}></input>
            //                     <h3 className="text-danger ms-2">*</h3>
            //                 </div>
            //                 {err["bondsToCreateProposal"] && <p className="text-danger">{err?.bondsToCreateProposal}</p>}
            //             </div>
            //             <div className="input w-100">
            //                 <div className="d-flex alig-items-center">
            //                     <input name="timeBeforeProposalExpires" placeholder="Time Before Proposal Expires" defaultValue={proposal?.timeBeforeProposalExpires} onChange={(e) => changeData(e.target.name, e.target.value)}></input>
            //                     <h3 className="text-danger ms-2">*</h3>
            //                 </div>
            //                 {err["timeBeforeProposalExpires"] && <p className="text-danger">{err?.timeBeforeProposalExpires}</p>}
            //             </div>
            //         </Col>
            //         <Col md={6}>
            //             <h3 className="d-flex justify-content-center align-items-center">Bounties</h3>
            //             <div className="input w-100">
            //                 <div className="d-flex align-items-center">
            //                     <input type="number" name="bondsToClaimBounty" placeholder="Bonds to Claim Bounty" defaultValue={proposal?.bondsToClaimBounty} onChange={(e) => changeData(e.target.name, e.target.value)}></input>
            //                     <h3 className="text-danger ms-2">*</h3>
            //                 </div>
            //                 {err["bondsToClaimBounty"] && <p className="text-danger">{err?.bondsToClaimBounty}</p>}
            //             </div>
            //             <div className="input w-100">
            //                 <div className="d-flex align-items-center">
            //                     <input name="timeToUnclaimBounty" placeholder="Time to Unclaim Bounty without penalty" defaultValue={proposal?.timeToUnclaimBounty} onChange={(e) => changeData(e.target.name, e.target.value)}></input>
            //                     <h3 className="text-danger ms-2">*</h3>
            //                 </div>
            //                 {err["timeToUnclaimBounty"] && <p className="text-danger">{err?.timeToUnclaimBounty}</p>}
            //             </div>
            //         </Col>
            //     </Row>
            // )
        } else if (type === 9) {
            return (
                <>
                    <Col lg={4} md={6} key="type9-1">
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input autocomplete="off" name="newGroupName" placeholder="New Group Name" defaultValue={proposal?.newGroupName} onChange={(e) => changeData(e.target.name, e.target.value)}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["newGroupName"] && <p className="text-danger">{err?.newGroupName}</p>}
                        </div>
                    </Col>
                    <Col lg={4} md={6} key="type9-2">
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input autocomplete="off" name="intialMemberAccount" placeholder="Initial Member Account" defaultValue={proposal?.intialMemberAccount} onChange={(e) => changeData(e.target.name, e.target.value)}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["intialMemberAccount"] && <p className="text-danger">{err?.intialMemberAccount}</p>}
                        </div>
                    </Col>
                    <Col md={6} className="d-flex align-items-baseline" key="type9-3">
                        <Accordion className="mb-3 w-100">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className="text-uppercase">Proposal Creation</Accordion.Header>
                                <Accordion.Body>
                                    <Permission name='create' description="Choose what creation rights you give Community groups." groups={[" "]} setPermission={setCreationPermission} allIsNot={true} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <h3 className="text-danger ms-2">*</h3>
                    </Col>
                    <Col md={6} className="d-flex align-items-baseline" key="type9-4">
                        <Accordion className="mb-3 w-100">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className="text-uppercase">Voting permissions</Accordion.Header>
                                <Accordion.Body>
                                    <Permission name='vote' description="Choose what voting rights you give Community groups." groups={[" "]} setPermission={setVotingPermission} allIsNot={true} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <h3 className="text-danger ms-2">*</h3>
                    </Col>
                </>
            )
        } else if (type === 10) {
            return (
                <>
                    <Col lg={4} md={6} key="type10-1">
                        <div className="input w-100">
                            {/* <input type="number" name="targetGroupId" placeholder="Target Group Id" onChange={(e) => changeData(e.target.name, e.target.value)}></input> */}
                            <div className="d-flex align-items-center">
                                <Select className="w-100" options={groupsList} onChange={(e) => { changeData("targetGroupId", e.value) }} placeholder="Select Group" classNamePrefix="freedom-select" />
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["targetGroupId"] && <p className="text-danger">{err?.targetGroupId}</p>}
                        </div>
                    </Col>
                    <Col lg={4} md={6} key="type10-2">
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input autocomplete="off" ref={memberAddrRef} name="memberToAdd" placeholder="Member To Add" onChange={(e) => changeData(e.target.name, e.target.value)}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["memberToAdd"] && <p className="text-danger">{err?.memberToAdd}</p>}
                        </div>
                    </Col>
                </>
            )
        } else if (type === 11) {
            return (
                <>
                    <Col lg={4} md={6} key="type11-1">
                        <div className="input w-100">
                            {/* <input name="targetGroupId" placeholder="Target Group Id" onChange={(e) => changeData(e.target.name, e.target.value)}></input> */}
                            <div className="d-flex align-items-center">
                                <Select className="w-100" options={groupsList} onChange={(e) => { changeData("targetGroupId", e.value) }} placeholder="Select Group" classNamePrefix="freedom-select" />
                                <h3 className="ms-2 text-danger">*</h3>
                            </div>
                            {err["targetGroupId"] && <p className="text-danger">{err?.targetGroupId}</p>}
                        </div>
                    </Col>
                    <Col lg={4} md={6} key="type11-2">
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input autocomplete="off" ref={memberRemoveRef} name="memberToRemove" placeholder="Member To Remove" defaultValue={proposal?.memberToRemove} onChange={(e) => changeData(e.target.name, e.target.value)}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["memberToRemove"] && <p className="text-danger">{err?.memberToRemove}</p>}
                        </div>
                    </Col>
                </>
            )
        } else if (type === 13) {
            return (
                <>
                    <Col lg={4} md={6} key="type12-1">
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input autocomplete="off" name="contractAddrFnCall" placeholder="Contract Address" defaultValue={proposal?.contractAddrFnCall} onChange={(e) => changeData(e.target.name, e.target.value)}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["contractAddrFnCall"] && <p className="text-danger">{err?.contractAddrFnCall}</p>}
                        </div>
                    </Col>
                    <Col lg={4} md={6} key="type12-2">
                        <div className="input w-100">
                            <div className="d-flex align-items-center">
                                <input autocomplete="off" name="fnCallName" placeholder="Function Prototype" defaultValue={proposal?.fnCallName} onChange={(e) => changeData(e.target.name, e.target.value)}></input>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["fnCallName"] && <p className="text-danger">{err?.fnCallName}</p>}
                        </div>
                    </Col>
                    <Col md={12} key="type12-3">
                        <div className="textarea w-100">
                            <div className="d-flex align-items-center">
                                <textarea name="fnCallAbi" placeholder="Place your Contract ABI" defaultValue={proposal?.fnCallAbi} onChange={(e) => changeData(e.target.name, e.target.value)}></textarea>
                                <h3 className="text-danger ms-2">*</h3>
                            </div>
                            {err["fnCallAbi"] && <p className="text-danger">{err?.fnCallAbi}</p>}
                        </div>
                    </Col>
                </>
            )
        } else { return }
    }

    return (
        <>    {fullLoader ? <FullPageLoader /> : ''}
            {
                !isSubmitted ?
                    <div className="create-proposal create-community">
                        <div className="page-header d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-0 mb-md-4">
                            <h2 className="me-0 me-md-2 mb-3 mb-md-0 font-weight-bold">Proposals - MUTO Network</h2>
                        </div>
                        <p className="mb-3 font-weight-bold text-white funds">Funds Available : {maxDaoFund}</p>
                        <div className="proposal-form">
                            {/* <h2>Create proposal</h2> */}
                            <Container fluid>
                                <form onSubmit={(e) => submitProposal(e)}>
                                    <Row>
                                        <h2 className="text-start">Create proposal</h2>
                                        <Col lg={`${proposalType?.value === 1 || proposalType?.value === 6 || proposalType?.value === 7 || proposalType?.value === 9 || proposalType?.value === 10 || proposalType?.value === 11 || proposalType?.value === 13 ? "4" : "6"}`} md={6}>
                                            <div>
                                                <div className="input w-100">
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            proposalType?.value == 8 ? <h3>Create a Claim</h3> :
                                                                <Select className="w-100" options={proposalTypes} isOptionDisabled={(option) => option.isdisabled} onChange={(e) => { setProposalType(e); setErr([]); initializeProposal(); setSearchParams({ proposalType: e.value }); }} value={proposalType} classNamePrefix="freedom-select" />
                                                        }
                                                        {/* isOptionDisabled={(option) => option.isdisabled} */}
                                                        <h3 className="text-danger ms-2">*</h3>
                                                    </div>

                                                </div>
                                            </div>
                                        </Col>
                                        {proposalFields()}
                                        <Col xl={12}>
                                            <div>
                                                <div className="textarea w-100">
                                                    <div className="d-flex align-items-center">
                                                        <textarea ref={descriptionRef} name="description" placeholder="Proposal Description" defaultValue={proposal?.description} onChange={(e) => changeData(e.target.name, e.target.value)}></textarea>
                                                        <h3 className="text-danger ms-2">*</h3>
                                                    </div>
                                                    {err["description"] && <p className="text-danger">{err?.description}</p>}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {err["proposalValidation"] && <p className="text-danger">{err?.proposalValidation}</p>}
                                    <div className="d-flex justify-content-center align-items-center flex-wrap pt-5">
                                    <Link to="/proposals" className="freedom-btn freedom-outline-btn transition  text-center mb-3 ms-0 mb-3 me-2 me-md-4">Cancel</Link>
                                    <button className="freedom-btn ms-0 mb-3 me-2 me-md-4 freedom-solid-btn transition   d-flex justify-content-center align-freedom-btn freedom-solid-btn transition  d-flex justify-content-center align-items-center pl-0 pl-md-0" type="submit" disabled={loader ? true : false}>
                                            {loader && <div className="loader me-2"></div>}
                                            Create
                                        </button>
                                        {/* <Link to="/proposals" className="freedom-btn freedom-outline-btn transition ms-0 mb-3 me-2 me-md-4 text-center">Cancel</Link> */}
                                        
                                    </div>
                                </form>
                            </Container>
                        </div>
                    </div> :
                    <div className="created-successfully">
                        <div className="frame">
                            <img src={CreatedSuccessfully} alt="" />
                        </div>
                        <p>Your proposal has been created and will become available shortly.</p>
                        <button className="d-inline-block align-top freedom-btn freedom-outline-btn transition margin-auto mb-3" onClick={() => navigate('/proposals')}>Back To Proposal List</button>
                    </div>
            }
            <AlertModal show={showAlert} setShow={setShowAlert} msg={alertMsg} />
            <WalletConnect show={showWalletConnect} setShow={setShowWalletConnect} />
        </>
    )
}

const mapStateToProps = state => ({
    proposal: state.proposal,
    community: state.community,
    userData: state.loginKey,
})

export default connect(mapStateToProps, { addProposal, beforeProposal, getCommunity, beforeCommunity, beforeCheckValidation, checkProposalValidation })(CreateProposal);