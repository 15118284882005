import React, { useEffect } from 'react'
import Dao from './../../components/dao/dao';
import { Images } from '../../assets/assets'
import './search.css'

const Search = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="search-page">
            <div className="search-section">
                <div className="search-results">
                    <span className="search-main-tag text-white">Search Results: <span className="search-value">3</span></span>
                    <div className="d-flex flex-wrap justify-content-between">
                        <Dao smallTag="Communities" head="The Coin Hero" para="Fan community for the heroes chained game. Come join us!" image={Images.heroImg} />
                        <Dao smallTag="Proposals" head="The Coin Hero Poll" para="Fan community for the heroes chained game. Come join us!" image={Images.heroImg} />
                        <Dao smallTag="Economy" head="The Coin Hero Partners" para="Fan community for the heroes chained game. Come join us!" image={Images.heroImg} />
                    </div>
                </div>
                <div className="no-results">
                    <span className="search-main-tag text-white no-data-margin">Search Results: <span className="search-value">0</span></span>
                    <div className="no-data text-center"> 
                        <span className="no-data-img"><img src={Images.noResult} alt='' className='img-fluid' /></span>
                        <span className="no-data-tag">Oops! No Results</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search