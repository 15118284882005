import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import './full-page-loader.css';

function FullPageLoader(props) {
	const [componentInitialCall, setInitialCall] = useState(true)
	useEffect(() => {
		if (componentInitialCall) {
			AOS.init();
			setInitialCall(false);
		}
	}, [componentInitialCall]);

	return (
		<div className='full-loader'>
			{/* <div className="fullpage-loader-holder">
				<div className="fullpage-loader">
					<div className="circle"></div>
					<div className="circle"></div>
					<div className="circle"></div>
					<div className="shadow"></div>
					<div className="shadow"></div>
					<div className="shadow"></div>
				</div>
			</div> */}
			{/* <div class="center-body">
				<div class="loader-square-49">
					<div class="sq_1"></div>
					<div class="sq_2"></div>
					<div class="sq_3"></div>
					<div class="sq_4"></div>
				</div>
			</div> */}
			<div class="outer">
				<div class="loader">
					<span></span>
					<p>Loading</p>
				</div>
			</div>
		</div>
	);
};

export default FullPageLoader;