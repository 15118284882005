import Web3 from 'web3';
import { ENV } from './../config/config';
import getLibrary from '../utils/getLibrary';
import controllerContractABI from '../utils/abi/controller.json';
import votingContractABI from '../utils/abi/voting.json';
// import vestingContractABI from '../utils/abi/vesting.json';
import tokenContractABI from '../utils/abi/token.json';
import { SET_WALLET_ERROR, DISCONNECT_USER } from '../redux/types';
import store from './../store'

const { appName, requiredChainId, amountToApprove} = ENV;
let requiredChainIds = [requiredChainId]
const { controller, voting, token, vesting } = ENV.contractAddress;
// const { address } = ENV.getUserKeys('address');
// let address = localStorage.getItem('address')


const call = (method, params) => {
    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
        method(...params)
            .call()
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const send = (method, params, from, value = 0) => {
    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
        method(...params)
            .send({ from, value })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const methods = {
    call,
    send,
};

const getSignMessage = async () => {
    return `${appName} uses this cryptographic signature in place of a password, verifying that you are the owner of this address.`;
}

export const signMessage = async (library, account) => {
    if (!library) return;
    try {
        const message = await getSignMessage();
        const signature = await library.provider.request({
            method: "personal_sign",
            params: [message, account]
        });
        return signature;
    } catch (error) {
        return false;
    }
};

export const getWeb3 = () => {
    if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        return web3;
    }
    else {
        return false;
    }
}

export const weitoEth = async (amount) => {
    const web3 = await getWeb3();
    if (!web3 || !amount) {
      return 0;
    }
    const etherValue = await web3.utils.fromWei(`${amount}`, "ether");
    return etherValue;
};

// fn to create the community
export const createCommunity = async (obj, addr) => {
    if(!obj.legalStatus){
        obj.legalStatus = " "
    }
    if(!obj.legalDocs){
        obj.legalDocs = " "
    }
    const { groupData, legalStatus, legalDocs, links, name, purpose } = obj

    let groupTuple = []

    const ObjKeys = ["groupName", "members", "proposalCreationRights", "votingPermissionRights"]

    Promise.all(groupData.map((e) => {
        const group = []
        Promise.all(ObjKeys.map(item => {
            group.push(e[item])
        }))
        groupTuple?.push(["0", ...group])
    }))

    const web3 = await getWeb3();
    if (!web3) {
        // toast.error("No web3 instance found.");
        return false;
    }
    try {
        let proxyControllerContract = new web3.eth.Contract(controllerContractABI, controller);
        let from = addr
        let tx = null
        console.log("groupTuple => ", groupTuple)
        tx = await methods.send(proxyControllerContract.methods.communityCreate, [name, purpose, legalStatus, legalDocs, [...links], groupTuple, "", ""], from)
        if (tx) {
            // let data = tx.events.EvtCommunityCreate.returnValues[1] 
            const { returnValues } = tx.events.EvtCommunityCreate;
            // let commId = returnValues[0]
            // let groupIds =  returnValues[1]
            return returnValues
        } else {
            return
        }
    } catch (e) {
        console.log(e, "ERROR!!!")
        return false;
    }
}

// fn to create the proposal
export const createProposal = async (commId, type, proposedChanges, addr) => {
    console.log("proposedChanges = ", proposedChanges)

    const web3 = await getWeb3();
    if (!web3) {
        return false;
    }
    try {
        const proposedTuple = []
        const ObjKeys = Object.keys(proposedChanges)
    
        // if amount is there convert it into the wei to store on contract
        if(proposedChanges["amount"]){
            proposedChanges["amount"] = await web3.utils.toWei(`${proposedChanges["amount"]}`,'ether');
        }else {
            proposedChanges["amount"] = 0
        }

        if(proposedChanges["bountyAmount"]){
            proposedChanges["bountyAmount"] = await web3.utils.toWei(`${proposedChanges["bountyAmount"]}`,'ether');
        }else {
            proposedChanges["bountyAmount"] = 0
        }

        if(proposedChanges["availableClaims"]){
        }else {
            proposedChanges["availableClaims"] = 0
        }

        if(proposedChanges["daystoComplete"]){
        }else {
            proposedChanges["daystoComplete"] = 0
        }

        Promise.all(ObjKeys.map(e => {
            if (e == "memberToAdd" || e == "memberToRemove" || e == "contractAddrFnCall" || e == "targetAcc") {
                if (!proposedChanges[e]) {
                    proposedChanges[e] = addr //passing user address to overcome the warning 
                }
            }
            proposedTuple.push(proposedChanges[e])
        }))
            
        console.log("proposal tupe = ", proposedTuple)
        let votingContract = new web3.eth.Contract(votingContractABI, voting);
        let from = addr;
        let tx = null
        tx = await methods.send(votingContract.methods.communityPollCreate, [commId, type, proposedTuple], from)
        if (tx) {
            const { returnValues } = tx.events.EvtCommunityPollCreate;
            let pollId = returnValues[0]
            let startTime = returnValues[1]
            let endTime = returnValues[2] 
            return {pollId, startTime, endTime};
        } else {
            return false
        }
    } catch (e) {
        console.log("ERROR = ", e)
        return false;
    }
}

// fn to vote casting
export const voteCast = async (daoId, pollId, vote, addr) => {
    const web3 = await getWeb3();
    if (!web3) {
        return false;
    }
    try {
        let votingContract = new web3.eth.Contract(votingContractABI, voting);
        let from = addr
        let tx = null
        tx = await methods.send(votingContract.methods.communityVoteCast, [daoId, pollId, vote], from)
        if (tx) {
            return true
        } else {
            return false
        }
    } catch (e) {
        console.log("ERROR = ", e)
        return false;
    }
}

// fn join community function
export const joinCommunity = async (commId,) => {

    const web3 = await getWeb3();
    if (!web3) {
        // toast.error("No web3 instance found.");
        return false;
    }
    try {
        // let connectedAddress = await getLibrary(web3);
        let proxyControllerContract = new web3.eth.Contract(controllerContractABI, controller);
        let from = await localStorage.getItem('address');
        let tx = null
        tx = await methods.send(proxyControllerContract.methods.communityJoin, [commId], from)
        if (tx) {
            return true
        } else {
            return
        }
    } catch (e) {
        console.log(e, "ERROR!!!")
        return false;
    }
}

// fn to deposit in community
export const depositToCommunity = async (daoId, amount, address) => {
    const web3 = await getWeb3();
    if (!web3) {
        return false;
    }
    try {
        let proxyControllerContract = new web3.eth.Contract(controllerContractABI, controller);
        let from = address
        let tx = null 
        // amount = await web3.utils.toWei(`${amount}`, 'ether');
        const approvedAmount = await isApproved();
        const amtInWei = web3.utils.toWei(`${amount}`, 'ether');
        if (parseFloat(amtInWei) > parseFloat(approvedAmount)) {
            const gotApproval = await getApproval()
            if (!gotApproval) {
                return false;
            }
        }
        tx = await methods.send(proxyControllerContract.methods.depositAmount, [amtInWei, daoId], from)
        if (tx) {
            return true
        } else {
            return false
        }
    } catch (e) {
        console.log("ERROR = ", e)
        return false;
    }
}

export const isApproved = async () => {
    const web3 = await getWeb3();
    if (!web3) {
        // toast.error("No web3 instance found");
        return false;
    }
    try {
        const connectedAddress = await localStorage.getItem('address');
        const tokenContract = new web3.eth.Contract(
            tokenContractABI,
            token,
        );
        const myNewData = await methods.call(tokenContract.methods.allowance, [connectedAddress, controller])

        return myNewData;
    } catch (e) {
        return 0;
    }
}
  
export const getApproval = async () => {
    const web3 = await getWeb3();
    if (!web3) {
        // toast.error("No web3 instance found");
        return false;
    }
    try {
        const connectedAddress = await localStorage.getItem('address');
        const tokenContract = new web3.eth.Contract(
            tokenContractABI,
            token,
        );

        await methods.send(
            tokenContract.methods.approve,
            [controller, ENV.amountToApprove],
            connectedAddress,
        );

        return true;
    } catch (e) {
        return false;
    }
}

export const isEthContractAddress = async (address) => {
    const web3 = await getWeb3();
    if (!web3) {
        // toast.error("No web3 instance found.");
        return false;
    }
    return web3.utils.isAddress(address);
}

const setWalletError = (message) => {
    return {
        type: SET_WALLET_ERROR,
        payload: message
    }
}

// disconnect user data
export const disconnectUser = () => dispatch => {
    dispatch({
        type: DISCONNECT_USER,
    })
}

export const getCommunityDetail = async (daoId) => {
    const web3 = await getWeb3();
    if (!web3) {
        // toast.error("No web3 instance found");
        return false;
    }
    try {
        const controllerProxy = new web3.eth.Contract(
            controllerContractABI,
            controller,
        );
        const myNewData = await methods.call(controllerProxy.methods.communityDetail, [daoId])
        const bal = await web3.utils.fromWei(myNewData["balance"], 'ether');
        return bal;
    } catch (e) {
        return 0;
    }
}

const accountsChangedHandler = () => {
    let error = `Please switch to ${ENV.requiredChainName} in order to use all features of DAO`
    if (window.ethereum) {
        window.ethereum.on('accountsChanged', function (accounts) {
            localStorage.clear()
            store.dispatch(setWalletError(""))
            store.dispatch(disconnectUser())
        })
        window.ethereum.on('chainChanged', function (_chainId) {
            let chaindId = parseInt(_chainId, 16);
            if (requiredChainIds.includes(chaindId)) {
                store.dispatch(setWalletError(""));
                localStorage.removeItem("walletError")
            }
            else {
                store.dispatch(setWalletError(error));
                localStorage.setItem("walletError", error)
            }
        })
        let web3 = new Web3(window.ethereum)
        web3.eth.net.getId().then((e)=> {
            if (requiredChainIds.includes(e)) {
                store.dispatch(setWalletError(""));
                localStorage.removeItem("walletError")
            }else {
                store.dispatch(setWalletError(error));
                localStorage.setItem("walletError", error)
            }
        })
    }
}

export const availableBalanceFn = async () => { //
    const web3 = await getWeb3();
    if (!web3) {
        // toast.error("No web3 instance found");
        return false;
    }
    try {
        const connectedAddress = await localStorage.getItem('address');
        const tokenContract = new web3.eth.Contract(
            tokenContractABI,
            token,
        );
        const myNewData = await methods.call(tokenContract.methods.balanceOf, [connectedAddress])
        return await web3.utils.fromWei(`${myNewData}`, "ether");
        
    } catch (e) {
        return 0;
    }
}

accountsChangedHandler();