import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Images } from '../../assets/assets'
import './sidebar.css'
import { useLocation } from 'react-router-dom';
import { clearProposal } from '../../redux/proposal/proposal.action';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faSquareTwitter, faTelegram, faTwitter, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { faBars, faChalkboardTeacher, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';


function Sidebar(props) {
	let location = useLocation();
	const [isActive, setActive] = useState(false);
    const toggleClass = () => {
        setActive(!isActive);
    };

	return (
		<div  className={isActive ? 'show complete-content' : 'complete-content'}>
				   <FontAwesomeIcon className="menu-icon text-white" onClick={toggleClass} icon={faBars} />
			   {/* <FontAwesomeIcon className="menu-icon2 text-white" onClick={toggleClass} icon={faXmark} /> */}
		<div className='sidebar' >
		<FontAwesomeIcon className="menu-icon2 text-white" onClick={toggleClass} icon={faXmark} />
			<ul>
				{/* <li>
					<Link to="/" className={`${location.pathname === "/" && "active" } d-flex align-items-center`}>
						<span className="sidebar-icons">
							<img src={Images.home_icon} alt='' className='black-icon img-fluid' />
							<img src={Images.home_icon_1} alt='' className='red-icon img-fluid' />
						</span>
						<span className="sidebar-tag">Home</span>
					</Link>
				</li> */}
				{/* <li>
					<Link to="/chat" className={`${location.pathname === "/chat" && "active" } d-flex align-items-center`}>
						<span className="sidebar-icons">
							<img src={Images.chat_icon} alt='' className='black-icon img-fluid' />
							<img src={Images.chat_icon_1} alt='' className='red-icon img-fluid' />
						</span>
						<span className="sidebar-tag">Chat</span>
					</Link>
				</li> */}
				<li onClick={() => props.clearProposal()}>
					<Link to="/communities" className={`${location.pathname === "/communities" && "active"} d-flex align-items-center`}>
						<span className="sidebar-icons">
							{/* <img src={Images.community_icon} alt='' className='black-icon img-fluid' /> */}
							<img src={Images.community_icon_1} alt='' className='red-icon img-fluid' />
						</span>
						<span className="sidebar-tag">Communities</span>
					</Link>
				</li>
				<li onClick={() => props.clearProposal()}>
					{/* <Link to="/proposals" className="d-flex align-items-center"> */}
					<Link to="/proposals" className={`${location.pathname === "/proposals" && "active"} d-flex align-items-center`}>
						<span className="sidebar-icons">
							{/* <img src={Images.proposal_icon} alt='' className='black-icon img-fluid' /> */}
							<img src={Images.proposal_icon_1} alt='' className='red-icon img-fluid' />
						</span>
						<span className="sidebar-tag">Proposals</span>
					</Link>
				</li>
				{/* <li>
					<Link to="/reward" className="d-flex align-items-center">
						<span className="sidebar-icons">
							<img src={Images.reward_icon} alt='' className='black-icon img-fluid' />
							<img src={Images.reward_icon_1} alt='' className='red-icon img-fluid' />
						</span>
						<span className="sidebar-tag">Reward</span>
					</Link>
				</li>
				<li>
					<Link to="/economy" className="d-flex align-items-center">
						<span className="sidebar-icons">
							<img src={Images.economy_icon} alt='' className='black-icon img-fluid' />
							<img src={Images.economy_icon_1} alt='' className='red-icon img-fluid' />
						</span>
						<span className="sidebar-tag">Economy</span>
					</Link>
				</li>   */}
			</ul>
		</div>
		</div>
	)
}
const mapStateToProps = state => ({
	proposal: state.proposal
})

export default connect(mapStateToProps, { clearProposal })(Sidebar)