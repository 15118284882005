import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate, useLocation} from "react-router-dom";
import 'react-bootstrap'
import { Container } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
// import Logo from '../../assets/images/logo.svg'
// import Logo from '../../assets/images/logo-new.png'
import Logo from '../../assets/images/logo.png'
import './header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faWallet, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Images } from '../../assets/assets'
import { Navbar, Nav, Modal, Dropdown, Row, Col } from 'react-bootstrap';
import Notifications from '../notifications/notifications';
import Messages from '../messages/messages';
import Chat from '../chat/chat';
import connectors from '../../utils/connectors';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { formatAddress, copyToClipBoard } from "../../utils/helperFunctions";
import { ENV } from "../../config/config";
import { login, beforeLogin, setUser } from "./header.action";
import { connect } from 'react-redux';
import { signMessage } from './../../utils/web3'
import {getProfileData, beforeProfileData, disconnectApp} from '../../redux/profile/profile.action'

const { requiredChainId, explorers, nativeCurrencies, web3Providers, requiredChainName } = ENV;

function Header(props) {
    const navRef = useRef()
    const navtTogglerRef = useRef()
    let navigate = useNavigate();
    let location = useLocation();

    const { activate, deactivate, active, account, library } = useWeb3React();

    const [show, setShow] = useState(false);
    const [showOne, setShowOne] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [addressCopied, setAddressCopied] = useState(false);
    const [accountAddress, setAccountAddress] = useState("");
    const [signature, setSignature] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseOne = () => setShowOne(false);
    const handleShowOne = () => setShowOne(true);

    useEffect(()=> {
        if(addressCopied){
            setTimeout(()=> {
                setAddressCopied(false)
            },[3000])
        }
    }, [addressCopied])

    useEffect(() => {
        let windowWidth = window.screen.width;
        if (windowWidth < 992) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false);
        }
        window.addEventListener("resize", () => {
            windowWidth = window.screen.width;
            if (windowWidth < 992) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        });
        const data = ENV.getUserKeys()
        console.log("data = ", data )
        const addr = data.address //localStorage.getItem("address")
        if (addr) {
            setAccountAddress(addr)
            props.setUser()
        }
    }, [])

    const onMessageClick = (status) => {
        setShowChat(status)
    }

    const connectWallet = (walletInjector, type) => {
        handleClose()
        const connector = walletInjector.provider;
        connector &&
            activate(connector, undefined, true)
                .then(async (res) => {
                    localStorage.setItem('connect', 'connected');
                    const networkDetails = {
                        chainId: `0x${Number(requiredChainId).toString(16)}`,
                        chainName: requiredChainName,
                        nativeCurrency: nativeCurrencies[requiredChainId],
                        rpcUrls: [web3Providers[requiredChainId]],
                        blockExplorerUrls: [explorers[requiredChainId]]
                    };
                    localStorage.setItem("provider", type);

                    await window?.ethereum?.request({
                        method: 'wallet_addEthereumChain',
                        params: [networkDetails],
                    });
                })
                .catch(async (error) => {
                    if (error instanceof UnsupportedChainIdError) {
                        const networkDetails = {
                            chainId: `0x${Number(requiredChainId).toString(16)}`,
                            chainName: requiredChainName,
                            nativeCurrency: nativeCurrencies[requiredChainId],
                            rpcUrls: [web3Providers[requiredChainId]],
                            blockExplorerUrls: [explorers[requiredChainId]]
                        };
                        await window?.ethereum?.request({
                            method: 'wallet_addEthereumChain',
                            params: [networkDetails],
                        });
                        activate(connector);
                    } else {
                    }
                });
    }

    useEffect(() => {
        if (account) {
            localStorage.setItem('address', account)
            loginMethod()
        }
    }, [account])

    useEffect(() => {
        if (props.loginKey.loginAuth) {
            if(props.loginKey.userData.isNewUser){
                navigate('/profile')
            }
            setAccountAddress(localStorage.getItem('address'))
        }
    }, [props.loginKey.loginAuth])

    const loginMethod = async () => {
        let wallet = localStorage.getItem('address')
        if (wallet) {
            const signature = await signMessage(library, wallet);
            let payload = {
                address: wallet,
                signature
            }
            props.login(payload);
        }
    }

    // disconnect from connected Account 
    const disconnect = () => {
        console.log("hello")
        deactivate();
        ENV.clearStorage()
        setAccountAddress(null)
        props.disconnectApp()
        navigate("/app")
    };

    return (
        <div className='header'>
            <Container fluid>
                <div className='d-flex align-items-center header-flex'>
                    <div className="navbar-section">
                        <Navbar expand="lg">
                            <Container>
                                <div ref={navtTogglerRef} >
                                    <img src={Images.barsIcon} alt='' className='img-fluid' onClick={() => { navRef.current.classList.add("show") }} />
                                </div>
                                <Navbar.Collapse ref={navRef} id="basic-navbar-nav">

                                    <Nav className="me-auto nav-options">
                                        {/* <Link to="/" className={`${location.pathname === "/" && "active" } nav-link d-flex align-items-center`}>
                                            <span className="sidebar-icons">
                                                <img src={Images.home_icon} alt='' className='black-icon img-fluid' />
                                                <img src={Images.home_icon_1} alt='' className='red-icon img-fluid' />
                                            </span>
                                            <span className="sidebar-tag">Home</span>
                                        </Link> */}
                                        {/* <Link to="/chat" className={`${location.pathname === "/chat" && "active" } nav-link d-flex align-items-center`}>
                                            <span className="sidebar-icons">
                                                <img src={Images.chat_icon} alt='' className='black-icon img-fluid' />
                                                <img src={Images.chat_icon_1} alt='' className='red-icon img-fluid' />
                                            </span>
                                            <span className="sidebar-tag">Chat</span>
                                        </Link> */}
                                        <Link to="/communities" className={`${location.pathname === "/communities" && "active" } nav-link d-flex align-items-center`}>
                                            <span className="sidebar-icons">
                                                <img src={Images.community_icon} alt='' className='black-icon img-fluid' />
                                                <img src={Images.community_icon_1} alt='' className='red-icon img-fluid' />
                                            </span>
                                            <span className="sidebar-tag">Communities</span>
                                        </Link>
                                        <Link to="/proposals" className={`${location.pathname === "/proposals" && "active" } nav-link d-flex align-items-center`}>
                                            <span className="sidebar-icons">
                                                <img src={Images.proposal_icon} alt='' className='black-icon img-fluid' />
                                                <img src={Images.proposal_icon_1} alt='' className='red-icon img-fluid' />
                                            </span>
                                            <span className="sidebar-tag">Proposals</span>
                                        </Link>
                                        {/* <Link to="/reward" className={`${location.pathname === "/reward" && "active" } nav-link d-flex align-items-center`}>
                                            <span className="sidebar-icons">
                                                <img src={Images.reward_icon} alt='' className='black-icon img-fluid' />
                                                <img src={Images.reward_icon_1} alt='' className='red-icon img-fluid' />
                                            </span>
                                            <span className="sidebar-tag">Reward</span>
                                        </Link>
                                        <Link to="/economy" className={`${location.pathname === "/economy" && "active" } nav-link d-flex align-items-center`}>
                                            <span className="sidebar-icons">
                                                <img src={Images.economy_icon} alt='' className='black-icon img-fluid' />
                                                <img src={Images.economy_icon_1} alt='' className='red-icon img-fluid' />
                                            </span>
                                            <span className="sidebar-tag">Economy</span>
                                        </Link> */}
                                    </Nav>
                                    <span className="cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={() => { navRef.current.classList.remove("show"); navtTogglerRef.current.classList.add("navbar-toggler collapsed") }} /></span>
                                    <div className='nav-logo'>
                                        <img src={Logo} alt='' className="img-fluid" />
                                    </div>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </div>
                    <div className='logo'>
                        <a href="/"><img src={Logo} alt='' className="img-fluid" /></a>
                    </div>
                    <div className="second-head">
                        <div className='search-input d-flex align-items-center'>
                            {/* <strong className='overview overview-header'>MutoDAO</strong> */}
                            {/* <div className="search-bar">
                                <input placeholder='Search' className='seach-input' onKeyUp={() => { navigate('/search') }}></input>
                                <img src={Images.seacrhIcon} alt='' className='img-fluid search-icon' />
                                <img src={Images.closeCircle} alt='' className='img-fluid close-icon' />
                            </div> */}
                        </div>
                        <div className='connect-section'>
                            <div className='d-flex'>

                                {
                                    !accountAddress ?
                                        <>
                                            <button className='connect-btn d-flex align-items-center' onClick={handleShow}>Connect Wallet <span className="arrow-images"><img src={Images.arrowRight} alt='' className='img-fluid black-image' /><img src={Images.arrowRight_1} alt='' className='img-fluid red-image' /></span> </button>
                                            <Modal  show={show} onHide={handleClose} className="metamask-modal" centered>
                                                <span className="modal-cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={handleClose} /></span>
                                                <Modal.Header closeButton className=' margin-bottom'>
                                                    <Modal.Title>
                                                        <div className='modal-logo'>
                                                            <img src={Logo} alt='' className="img-fluid" />
                                                        </div>
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body className="text-center">
                                                    <span className="modal-head margin-bottom">Connect your wallet</span>
                                                    <div className="yellow-div d-flex align-items-center justify-content-between margin-bottom" onClick={() => connectWallet(connectors.injected, "injected")}>
                                                        <span className="div-tag">MetaMask</span>
                                                        <span className="div-img"><img src={Images.metaMask} alt='' className="img-fluid" /></span>
                                                    </div>
                                                    <div className="blue-div d-flex align-items-center justify-content-between" onClick={() => connectWallet(connectors.walletConnect, "walletConnect")}>
                                                        <span className="div-tag">WalletConnect</span>
                                                        <span className="div-img"><img src={Images.vectorIcon} alt='' className="img-fluid" /></span>
                                                    </div>
                                                </Modal.Body>
                                            </Modal>
                                        </> :
                                        <>
                                            <Dropdown>
                                                <Dropdown.Toggle className='connect-btn d-flex align-items-center' id="dropdown-basic">
                                                    <span className="wallet-img-black"><img src={Images.walletIcon} alt='' className='img-fluid' /></span>
                                                    <span className="wallet-img-red"><img src={Images.walletIcon_1} alt='' className='img-fluid' /></span>
                                                    <span className="wallet-address ms-2">{formatAddress(accountAddress)}</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="dd-first-div d-flex align-items-center">
                                                        {/* <span><img src={Images.walletIconGrey} alt='' className='img-fluid' /></span> */}
                                                        <span className='d-inline-flex'>
                                                            <FontAwesomeIcon className='text-white' icon={faWallet} />
                                                        </span>
                                                        <span className="dropdown-tag">Your wallet</span>
                                                    </div>
                                                    <div className="dd-first-div text-center">
                                                        <span className="full-wallet-address">{accountAddress}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between dropdown-buttons">
                                                        <button onClick={() => {
                                                            copyToClipBoard(accountAddress)
                                                            setAddressCopied(true)
                                                        }} className="grey-button">
                                                            <span className="black-icon"><img src={Images.copyIcon} alt='' className='img-fluid' /></span>
                                                            <span className="red-icon"><img src={Images.copyIcon_new} alt='' className='img-fluid' /></span>
                                                            <span className="grey-button-text">{addressCopied ? "Copied" : "Copy Address"}</span>
                                                        </button>
                                                        <button onClick={() => disconnect()} className="grey-button">
                                                            <span className="black-icon"><img src={Images.logoutIcon} alt='' className='img-fluid' /></span>
                                                            <span className="red-icon"><img src={Images.logoutIcon_new} alt='' className='img-fluid' /></span>
                                                            <span className="grey-button-text">Disconnect</span>
                                                        </button>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <button type="button" className='top-icon top-icon-1 ms-2' onClick={() => navigate('/profile')}>
                                                {
                                                    props?.loginKey?.userData?.profileImage ? 
                                                        <img src={props?.loginKey?.userData?.profileImage} alt='' className='img-fluid' /> : 
                                                        <FontAwesomeIcon className='user-icon' icon={faUser} /> 
                                                }
                                            </button>
                                        </>
                                }
                                {/* message icon */}
                                {/* <button type="button" className='top-icon top-icon-1 ms-2' onClick={() => handleShowOne()}>
                                    <img src={Images.smsIcon} alt='' className='img-fluid' /><span className="value-span">2</span>
                                </button> */}
                                <Modal show={showOne} onHide={handleCloseOne} className="messages-modal">
                                    <span className="modal-cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={handleCloseOne} /></span>
                                    <Modal.Body>
                                        <Row>
                                            {
                                                isMobile ?
                                                    !showChat ?
                                                        <Col lg={5}>
                                                            <Messages onMessageClick={onMessageClick} />
                                                        </Col> :
                                                        <Col lg={7}>
                                                            <Chat onMessageClick={onMessageClick} />
                                                        </Col>
                                                    :
                                                    <>
                                                        <Col lg={5}>
                                                            <Messages onMessageClick={onMessageClick} />
                                                        </Col>
                                                        <Col lg={7}>
                                                            <Chat onMessageClick={onMessageClick} />
                                                        </Col>
                                                    </>
                                            }
                                        </Row>
                                    </Modal.Body>
                                </Modal>

                                {/* notifications icon */}
                                {/* <Dropdown className="top-icon-2">
                                    <Dropdown.Toggle className='top-icon ms-2' id="dropdown-basic">
                                        <img src={Images.notificationIcon} alt='' className='img-fluid' />
                                        <span className="value-span">12</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="noti-section d-flex justify-content-between align-items-center">
                                            <span className="noti-tag">Notifications</span>
                                            <button onClick="" className="read-button d-flex align-items-center" ><span className="button-img"><img src={Images.readAll} alt='' className='img-fluid' /></span><span className="button-tag ms-2">Make as read</span></button>
                                        </div>
                                        <div className="notifications">
                                            <Notifications message={true} time="1m ago" name="Eleanor Pena" para="replied to your message at" para2="Freedom Network Main Chat" image={Images.personOne} />
                                            <Notifications transaction={true} time="2h ago" para="Your swapped exactly" amount="0.0000005ETH" para2="for" account="155,123.456" accountRest="ABC" image={Images.personTwo} />
                                            <Notifications message={true} time="Yesterday" name="Eleanor Pena" para="replied to your message at" para2="Freedom Network Main Chat" image={Images.personThree} />
                                            <Notifications message={true} time="Juj 23, 2022 at 09:15 AM" name="Eleanor Pena" para="replied to your message at" para2="Freedom Network Main Chat" image={Images.personFour} />
                                            <Notifications message={true} time="Juj 23, 2022 at 09:15 AM" para="Great news! We are lauching our 5th fund: The coin Hero Living" image={Images.personFive} />
                                            <Notifications message={true} time="Juj 23, 2022 at 09:15 AM" name="Eleanor Pena" para="replied to your message at" para2="Freedom Network Main Chat" image={Images.personFour} />
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}


const mapStateToProps = state => ({
    loginKey: state.loginKey,
});

export default connect(mapStateToProps, { login, beforeLogin, setUser, getProfileData, beforeProfileData, disconnectApp })(Header);