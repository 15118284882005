import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Lottie from "lottie-react";
import workslottie from '../../../utils/works-lottie.json'
import userslottie from '../../../utils/user-lottie.json'
import groupslottie from '../../../utils/groups-lottie.json'
import votinglottie from '../../../utils/voting-lottie.json'
import './guids.css'
const Guids = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section className='muto-guide'>
            <Container>
                <Row>
                    <Col lg={6}>
                        <div className='guide-content'>
                            <h2 className='mb-2'>MutoDAO Guide (How it works)</h2>
                            <h5 className='pt-5'>What is MUTODAO?</h5>
                            <p>MutoDAO is a blockchain-powered system that allows autonomous communities and their verified members to interact, vote, and govern themselves through self-enforcing digital rules encoded on a public blockchain. </p>
                            <p>With decentralized voting and governance protocols fueled by an interactive token economy, MutoDAO has the collaborative potential the modern world needs with more accuracy, transparency, and flexibility</p>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='guides-lottie'>
                            <Lottie animationData={workslottie} loop={false} />
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className='guide-content'>
                            <div className='overlay'>
                                <h1>Idea</h1>
                                <p className='text-center'>With MutaDAO, decentralized autonomous organizations will be able to retain more sovereignty, impartiality, and independence and, importantly, cast the net further regarding governance through increased transparency, flexibility, and the introduction of governance tokens.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className='guides-lottie'>
                            <Lottie animationData={userslottie} loop={true} />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='guide-content'>
                            <h2>Users</h2>
                            <h6 >Not Logged-in "Users"</h6>
                            <p>Anyone without a MetaMask wallet can access and use the platform. Also, the users who have not connected their MetaMask wallet to MutoDAO and are not logged in using MetaMask wallet can still have access to the list of DAOs and their contents only. Remember, you must connect your wallet and log in to MutoDAO to make proposals, claim bounties, or vote</p>
                            <h6>MetaMask Wallet Connected "Users"</h6>
                            <p>Users with MetaMask wallet can log in to the MutoDAO platform and use all its features. Follow the below steps to log in.</p>
                            <ul className='guids-ul'>
                                <li>Click on the "Connect Wallet" button in the header (menu). You will be navigated to the MetaMask wallet to approve connecting to the MutoDAO service.</li>
                                <li>As soon as your connecting request is approved by MetaMAsk, you will be directed to the MutoDAO application. You have now logged in to the system. Users with their wallets connected to MutoDAO can create new DAOs and proposals, vote or claim bounties, etc. </li>
                            </ul>
                            <h5>Members of a DAO</h5>
                            <p>Members of the MutoDAO have access to additional features such as initiating proposals, governing policies, voting, and interacting with the treasury. These features are specific to the DAOs members and will not be available to non-members</p>
                            <p>Members of MutoDAO can be organized into groups, which can provide more flexibility in terms of governance. This allows the restriction of voting on certain proposal types to members of specific groups, providing a more granular approach to governance</p>
                        </div>
                    </Col>
                </Row>
                <Row className="flex-md-row-reverse">
                    <Col lg={6}>
                        <div className='guides-lottie'>
                            <Lottie className='p-5' animationData={groupslottie} loop={true} />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='guide-content'>
                            <h2>Groups</h2>
                            <h6>Why Group Members?</h6>
                            <p className='mb-0'>Groups within a DAO hold the right to identify the roles of members within the organization clearly.</p>
                            <h6>How to Create a Group?</h6>
                            <p>You can make a proposal to create a new group within MutoDAO. The steps are as follows:</p>
                            <ul>
                                <li>Click on the Members/Group bar and open the Members page</li>
                                <li>Click on the ‘Create a Group’ button.</li>
                                <li>Enter the details for your proposed group:</li>
                                <ul>
                                    <li>Enter the group name</li>
                                    <li>Add a description (and details to identify your proposal.)</li>
                                    <li>Add an external URL link (only if required)</li>
                                    <li>Add at least one member using a MetaMask Account.</li>
                                </ul>
                                <li>Click on the ‘Propose’ button.</li>
                            </ul>
                            <p>It should be noted that the specific steps may vary depending on the particular implementation of MutoDAO. Also, after a proposal is created, it will be up for voting by members of the DAO, and if it passes, the group will be created, and the members will be added to it.</p>
                            <p>The percentage and weight of votes for each member of a DAO may vary depending on the specific voting policy of that DAO.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className='guides-lottie'>
                            <Lottie className='p-5' animationData={votinglottie} loop={true} />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='guide-content'>
                            <h2>Voting </h2>
                            <h6>Voting Types</h6>
                            <p>The primary function of any DAO is to vote, and MutoDAO's smart contract offers the following type of voting: </p>
                            <p>Role Weighted, which assigns specific voting weights to users in certain groups. </p>
                            <h5>Voting Process</h5>
                            <p>The available options for voting are:</p>
                            <ul>
                                <li>Approve - This action signifies your agreement with the proposal and will take effect after verifying through the MetaMask wallet.</li>
                                <li>Reject - This action signifies your disagreement with the proposal and will take effect after verifying through the MetaMask wallet. If this option receives a majority vote, the proposal will be rejected, and the bond will be returned</li>
                                <li>Remove - This action is for proposals that you believe are invalid. After selecting this option, the proposal will no longer be visible in any feeds. If this option receives a majority vote, the proposal will be rejected, but the bond will NOT be returned</li>
                            </ul>
                            <h5>Voting Policy</h5>
                            <p>The Voting Policy established by the DAO council determines who is eligible to vote and who is not. It also outlines the requirements for how individuals should vote and the minimum number of votes needed to reach a decision (quorum).</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Guids