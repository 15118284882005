// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// LOGIN 
export const LOGIN_USER = 'LOGIN_USER';
export const BEFORE_LOGIN = 'BEFORE_LOGIN';

// COMMUNITY 
export const BEFORE_COMMUNITY = 'BEFORE_COMMUNITY';
export const ADD_COMMUNITY = 'ADD_COMMUNITY';
export const GET_USER_COMMUNITIES = 'GET_USER_COMMUNITIES';
export const GET_COMMUNITIES = 'GET_COMMUNITIES';
export const GET_COMMUNITY = 'GET_COMMUNITY';
export const ADD_MEMBERS = 'ADD_MEMBERS';
export const MEMBER_COMMUNITIES = "MEMBER_COMMUNITIES";
export const ADD_MEMBERS_COMMUNITY = "ADD_MEMBERS_COMMUNITY";
export const CHECK_MEMBER = "CHECK_MEMBER";
export const GET_DAO_MEMBERS = "GET_DAO_MEMBERS";
export const CHECK_VALIDATION_COMM = "CHECK_VALIDATION_COMM";
export const BEFORE_CHECK_VALIDATION_COMM = "BEFORE_CHECK_VALIDATION_COMM";

// MEMBERS
export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const BEFORE_MEMBER = "BEFORE_MEMBER";

// PROPOSAL 
export const BEFORE_PROPOSAL = 'BEFORE_PROPOSAL';
export const ADD_PROPOSAL = 'ADD_PROPOSAL';
export const GET_PROPOSAL = 'GET_PROPOSAL';
export const GET_PROPOSALS = 'GET_PROPOSALS';
export const DELETE_PROPOSAL = 'DELETE_PROPOSAL';
export const EDIT_PROPOSAL = 'EDIT_PROPOSAL';
export const CHECK_VALIDATION = 'CHECK_VALIDATION';
export const BEFORE_CHECK_VALIDATION = 'BEFORE_CHECK_VALIDATION';
export const CLEAR_PROPOSALS = 'CLEAR_PROPOSALS';

// VOTE 
export const BEFORE_VOTE = 'BEFORE_VOTE';
export const ADD_VOTE = 'ADD_VOTE';
export const EDIT_VOTE = 'EDIT_VOTE';

//PROFILE
export const BEFORE_PROFILE = 'BEFORE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';

//INVESTOR
export const BEFORE_INVESTORS = 'BEFORE_INVESTORS';
export const INVESTOR_SIGNUP = 'INVESTOR_SIGNUP';
export const GET_INVESTORS = 'GET_INVESTORS';
export const CHECK_OTP_VALIDATION = 'CHECK_OTP_VALIDATION';

//Wallet
export const SET_WALLET_ERROR ='SET_WALLET_ERROR';

// User
export const SET_USER = "SET_USER";
export const DISCONNECT_USER = "DISCONNECT_USER";

// FOOTER
export const BEFORE_FOOTER = 'BEFORE_FOOTER';
export const GET_FOOTER = 'GET_FOOTER';
export const SUBMIT_FOOTER = 'SUBMIT_FOOTER';
export const SUBMIT_CONTACT = 'SUBMIT_CONTACT';