import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import cardImageOne from '../../../assets/images/card-icon-1.svg'
import cardImageTwo from '../../../assets/images/card-icon-2.svg'
import cardImageThree from '../../../assets/images/card-icon-3.svg'
import cardImageFour from '../../../assets/images/card-icon-4.svg'
import './proportional-card.css'

const ProportionalCard = () => {
    const ProportionalCard = [
        { id: 1, title: "Active DAOs", values: "100", icon: cardImageOne },
        { id: 2, title: "Total Value Locked", values: "$50000", icon: cardImageTwo },
        { id: 3, title: "Proposals", values: "1500", icon: cardImageThree },
        { id: 4, title: "Profit percentage", values: "40%", icon: cardImageFour },
    ]
    return (
        <section className='proportional-area'>
            <Container>
                <Row>
                    {ProportionalCard.map((e,index) =>
                        <Col lg={3}  sm={6} >
                            <div className={`proportional-card proportional-card-${index % 2 == 0 ? 'one' : 'two'}`}>
                                <div><img src={e.icon} alt='image' className='img-fluid' /></div>
                                <div className='content'>
                                    <p className='proportional-title'>{e.title}</p>
                                    <h3 className='proportional-value'>{e.values}</h3>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </Container>
        </section>
    )
}

export default ProportionalCard