import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { Images } from '../../assets/assets';
import { ENV } from "../../config/config";
import IconFlag from "../../assets/images/icon-flag.png"
import IconMembers from "../../assets/images/icon-members.png"
import IconPosts from "../../assets/images/icon-posts.png"
import './community.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faUser } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";

const CommunityListing = (props) => {
	const currentTime = moment();
    return (
        <div id="commList" className="communities-list d-flex align-items-center flex-wrap">
            {/* <Row> */}
            {props?.communities?.map((item, index) => {
                return (
                    <div className="community-item mb-4 d-flex" key={index}>
                        <div className="image-holder mb-3 mb-md-0 me-3">
                            <img src={item.logo || Images.Default_Logo} alt="Community Image" />
                        </div>
                        <div className="community-detail">
                            <strong className="community-name d-block mb-3 pt-2">
                                <Link className="transition" to={`/community/${item.address}`}>{item.name}</Link>
                            </strong>
                            <p>{item.description}</p>
                            <ul className="list-unstyled d-flex flex-wrap community-stats mb-0">
                                <li className="mb-4">
                                    <span className="stat-icon me-2">
                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                        {/* <img src={IconFlag} alt="" className='ms-1' /> */}
                                        <FontAwesomeIcon icon={faFlag} />
                                    </span>
                                    <span className="stat-text">
                                        Founded <strong>{currentTime.diff(item.createdAt, "days") > 0 ? `${currentTime.diff(item.createdAt, "days")} days ago` : `${currentTime.diff(item.createdAt, "hours")} hours ago`}</strong>
                                    </span>
                                </li>

                                <li className="mb-4">
                                    <span className="stat-icon me-2">
                                        <FontAwesomeIcon icon={faUser} />
                                        {/* <img src={IconFlag} alt="" className='ms-1' /> */}
                                    </span>
                                    <span className="stat-text">
                                        Founder <strong>{ENV?.formattedAddress(item?.founderAddr)}</strong>
                                    </span>
                                </li>
                                <li className="mb-2">
                                    <span className="stat-icon me-2">
                                        <img src={IconMembers} alt="" />
                                    </span>
                                    <span className="stat-text">
                                        <strong>{item.memberCount}</strong> members
                                    </span>
                                </li>
                                <li className="mb-2">
                                    <span className="stat-icon me-2">
                                        <img src={IconPosts} alt="" />
                                    </span>
                                    <span className="stat-text">
                                        <strong>{item.proposalCount}</strong> Posts
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                )
            }
            )
            }
        </div>
	)
}

export default CommunityListing;