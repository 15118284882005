require('dotenv').config();
const CryptoJS = require('crypto-js');
const dataEncryptionKey = 'kalsaOOLLaASASAFFSSEE';
const Web3  = require('web3');

export const ENV = {
    amountToApprove: '132452152222200001125234466568964684610',
    cloud_name: process.env.CLOUD_NAME,
    api_key: process.env.API_KEY,
    api_secret: process.env.API_SECRET,
    current_network: process.env.REACT_APP_NETWORK,
    appName: process.env.REACT_APP_NAME,
    appURL: process.env.REACT_APP_URL,
    url: process.env.REACT_APP_BASE_URL,
    // Headers
    Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
    x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,

    //Blockchain related variables
    requiredChainName: process.env.REACT_APP_REQUIRED_CHAIN_NAME,
    requiredChainId: parseInt(process.env.REACT_APP_REQUIRED_CHAIN_ID),
    networks: { [parseInt(process.env.REACT_APP_ETH_CHAIN)]: process.env.REACT_APP_ETH_CHAIN },
    nativeCurrencies: {
        [parseInt(process.env.REACT_APP_ETH_CHAIN)]: {
            name: process.env.REACT_APP_ETH_NAME,
            symbol: process.env.REACT_APP_ETH_CURRENCY_SYMBOL,
            decimals: parseInt(process.env.REACT_APP_ETH_CURRENCY_DECIMALS),
        },
    },
    web3Providers: {
        [parseInt(process.env.REACT_APP_ETH_CHAIN)]: process.env.REACT_APP_ETH_RPC,
    },
    explorers: {
        [parseInt(process.env.REACT_APP_ETH_CHAIN)]: process.env.REACT_APP_ETH_EXPLORER,
    },
    contractAddress: {
        controller: process.env.REACT_APP_CONTROLLER_CONTRACT_ADDRESS,
        voting: process.env.REACT_APP_VOTING_CONTRACT_ADDRESS,
        token: process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
        vesting: process.env.REACT_APP_VESTING_CONTRACT_ADDRESS
    },
    encryptUserData: function (data) {
        let userData = localStorage.getItem('encryptedFreedomUser');
        if (userData && !data.accessToken) {
            let bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            let originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            if (originalData && originalData.accessToken) {
                data.accessToken = originalData.accessToken;
            }
        }
        data = JSON.stringify(data);
        let encryptedUser = CryptoJS.AES.encrypt(data, dataEncryptionKey).toString();
        localStorage.setItem('encryptedFreedomUser', encryptedUser);
        return true;
    },

    getUserKeys: function (keys = null) {
        let userData = localStorage.getItem('encryptedFreedomUser');
        if (userData) {
            var bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            var originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            let user = {};
            if (keys) {
                keys = keys.split(" ");
                for (let key in keys) {
                    let keyV = keys[key];
                    user[keyV] = originalData[keyV];
                }
            }
            else {
                user = originalData;
            }
            return user;
        }
        return {};
    },

    clearStorage: function () {
        localStorage.clear()
    },

    objectToQueryString: function (body) {
        const qs = Object.keys(body)
            .map((key) => `${key}=${body[key]}`)
            .join("&");
        return qs;
    },

    isValidImageType: function (file) {
        if (file && file.type) {
            const acceptableTypes = [
                "image/png",
                "image/x-png",
                "image/jpeg",
                "image/jpg",
            ];
            return acceptableTypes.includes(file.type.toLowerCase());
        }
    },

    decimalNumberValidator: function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        let specialKeys = [46, 8, 9, 27, 13, 110, 190];

        if (e.target.value.includes(".")) {
            specialKeys = [46, 8, 9, 27, 13];
        } else {
            specialKeys = [46, 8, 9, 27, 13, 110, 190];
        }

        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        if (
            specialKeys.includes(e.keyCode) ||
            // Allow: Ctrl+A,Ctrl+C,Ctrl+Z,Ctrl+X Command+A
            ((e.keyCode === 65 ||
                e.keyCode === 67 ||
                e.keyCode === 90 ||
                e.keyCode === 88) &&
                (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40) ||
            // Allow F1 to F12 keys
            (e.keyCode >= 112 && e.keyCode <= 123)
        ) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if (
            (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    },

    integerNumberValidator: function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        const specialKeys = [46, 8, 9, 27, 13, 110];

        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        if (
            specialKeys.includes(e.keyCode) ||
            // Allow: Ctrl+A,Ctrl+C,Ctrl+Z,Ctrl+X Command+A
            ((e.keyCode === 65 ||
                e.keyCode === 67 ||
                e.keyCode === 90 ||
                e.keyCode === 88) &&
                (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)
        ) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if (
            (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    },

    truncTextareaLength: (val, maxlength = 150) => {
        // maxlength = 150

        if (val.length > maxlength) {
            val = val.substring(0, maxlength).concat("...");
        } else if ((val.match(/\n/g) || []).length) {
            let values = val.split("\n");

            if (values && values.length && values[0] !== "\n")
                val = values[0].concat("...");
        }

        return val;
    },

    formattedAddress: (address) => {
        return `${address?.slice(0, 5)}...${address?.slice(-5)}`
    },
    copyToClipBoard: (url) => {
        navigator.clipboard.writeText(url);
    },
    isValidURL: (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    },
    isUniqueArray: (arr) => {
        let array = []
        for (let index = 0; index < arr.length; index++) {
            const e = arr[index];
            if(e === ""){

            }else {
                array.push(e)
            }
        }
        let set = new Set(array);
        if (set?.size === array?.length) {
          return true;
        } else {
          return false;
        }
    },
    isEthereumAddress: async (address) => {
        return new Promise(async (resolve, reject) => {
            const web3 = new Web3(Web3.givenProvider || "http://localhost:8545")
            if (!web3.currentProvider) {
                reject(new Error('No web3 provider found'));
            }
            try {
                const isValid = await web3.utils.isAddress(address)
                if (isValid) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }catch (err) {
                reject(err);
            }  
        })
    } 
}