import React from 'react'
import { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import $ from 'jquery'
import logo from '../../assets/images/logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faGithub, faLinkedin, faLinkedinIn, faTelegram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import './header-1.css'
const Header = () => {

	const ref = useRef();
	const location = useLocation();
	const routePath = location.pathname;
	// const sticky = useStickyHeader( routePath == "/home" || routePath == "/" ? 1200 : 80 );
	const sticky = useStickyHeader(103);
	const headerClasses = ` ${(sticky) ? 'sticky nav-wrapper' : 'nav-wrapper'}`
	const { clientHeight } = ref;
	function useStickyHeader(offset = 0) {
		const [stick, setStick] = useState(false);

		const handleScroll = () => {
			setStick(window.scrollY > offset);
		};

		useLayoutEffect(() => {
			window.addEventListener('scroll', handleScroll);

			return (() => {
				window.removeEventListener('scroll', handleScroll);
			});
		});

		return stick;
	}

	return (
		// <div  >
		<header id="header" >
			<div ref={ref} className={headerClasses}>
				<Navbar expand="lg" >
					<Container>
						<Navbar.Brand href="#">
							<strong>
								<Link to="/home"><img src={logo} className='img-fluid' alt='logo' /></Link>
							</strong>
						</Navbar.Brand>
						<Navbar.Toggle className='text-light' aria-controls="navbarScroll" />
						<Navbar.Collapse id="navbarScroll">
							<Nav className="me-auto  w-100 justify-content-end">
								<li>
									<span className='dropdown'>About</span>
									<div className='dropdown-menu'>
										<Link className='dropdown-item' to="/mission">
											<h6>Mission</h6>
											<p>The mission behind Muto</p>
										</Link>
										<Link className='dropdown-item' to="/our-investors">
											<h6>Our Investors</h6>
											<p>Lore ipsum dolor set ammet</p>
										</Link>
									</div>
								</li>
								<li>
									<span className='dropdown'>Product</span>
									<div className='dropdown-menu'>
										<Link className='dropdown-item' to="/faqs">
											<h6>FAQs</h6>
											<p>Frequently asked questions list</p>
										</Link>
										<Link className='dropdown-item' to="/guids">
											<h6>DAO Guide</h6>
											<p>Learn mutodao guideline</p>
										</Link>
									</div>
								</li>
								<li>
									<Nav.Link className='dropdown' href="">Developers</Nav.Link>
									<div className='dropdown-menu'>
										<a className='dropdown-item' target="_blank" href='https://github.com/metamuto/mutodao/'>
											<div className='d-flex align-items-center'>
												<div className='platform-icons'>
													<a className='' href='https://freedom-v2.arhamsoft.org/'>
														<FontAwesomeIcon icon={faGithub} />
													</a>
												</div>
												<div className='platform-info ms-3'>
													<h6>GitHub</h6>
													<p>Explore Meta Dao on GitHub.</p>
												</div>
											</div>
										</a>
									</div>
								</li>
								<li className='community-dropdown'>
									<span className='dropdown'>Community</span>
									<div className='dropdown-menu'>
										<a className='dropdown-item' target="_blank" href='https://www.youtube.com/@metamuto.metaverse'>
											<div className='d-flex align-items-center'>
												<div className='platform-icons'>
													<a className='' href=''><FontAwesomeIcon icon={faYoutube} /></a>
												</div>
												<div className='platform-info ms-3'>
													<h6>Youtube</h6>
													<p>Explore Meta Dao on youtube.</p>
												</div>
											</div>
										</a>
										<a className='dropdown-item' target="_blank" href='https://t.me/metamuto'>
											<div className='d-flex align-items-center'>
												<div className='platform-icons'>
													<a className='' href=''><FontAwesomeIcon icon={faTelegram} /></a>
												</div>
												<div className='platform-info ms-3'>
													<h6>Telegram</h6>
													<p>Connect with a distributed community</p>
												</div>
											</div>
										</a>
										<a className='dropdown-item' target="_blank" href='https://twitter.com/meta_muto'>
											<div className='d-flex align-items-center'>
												<div className='platform-icons'>
													<a className='' href=''><FontAwesomeIcon icon={faTwitter} /></a>
												</div>
												<div className='platform-info ps-3'>
													<h6>Twitter</h6>
													<p>Follow for the latest news and updates</p>
												</div>
											</div>
										</a>
										<a className='dropdown-item' target="_blank" href='https://www.linkedin.com/company/metamuto/'>
											<div className='d-flex align-items-center'>
												<div className='platform-icons'>
													<a className='' href=''><FontAwesomeIcon icon={faLinkedinIn} /></a>
												</div>
												<div className='platform-info ps-3'>
													<h6>LinkedIn</h6>
													<p>Follow for the latest news and updates</p>
												</div>
											</div>
										</a>
									</div>
								</li>
								<Link to="/app" type='button' className='custom-btn btn'>Launch App</Link>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</div>
		</header>
		// </div>
	)
}
export default Header;
