import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebookF, faLinkedinIn, faTelegram } from "@fortawesome/free-brands-svg-icons";
import footerLogo from '../../../src/assets/images/arhamoft-logo.png'
import mergingImage from '../../../src/assets/images/ft-merge.png'
import './footer.css'
import { beforeFooter, submitEmail } from '../../redux/footer/footer.action'
import { connect } from 'react-redux'

const Footer = (props) => {

    const location = useLocation();
    const [email, setEmail] = useState()
    const [msg, setMsg] = useState('')

    useEffect(() => {
        if(props.footer.submitAuth){
            setEmail("")
            setMsg("Email Submitted Successfully.") //props.footer.submitRes.message
        }
    }, [props.footer.submitAuth])
    
    useEffect(()=> {
        if(msg !== ''){
            let timer1 = setTimeout(() =>{
                setMsg('')
            },  2 * 1000);
            return () => {
                clearTimeout(timer1);
            }
        }
    }, [msg])
    
    const submitEmailFn = (e) => {
        e.preventDefault();
        props.beforeFooter();
        setMsg('')
        props.submitEmail({email})
    }

    return (
        <footer id='footer' className={location.pathname === "/" || location.pathname === "/home" ? "footer-bg" :""}>
            <Container>
                <Row>
                    {
                       location.pathname === "/" || location.pathname === "/home" ? 
                        <div className='ft-merge'><img src={mergingImage} className='img-fluid' alt='image'/></div>
                        : ''
                    }
                    <div className='footer-content'>
                        <div className='social'>
                            <ul class="social-icons list-unstyled">
                                <li><a href='https://www.youtube.com/@metamuto.metaverse' target="_blank" className='youtube-icon'><FontAwesomeIcon icon={faPlay} /></a></li>
                                <li><a href='https://twitter.com/meta_muto' target="_blank" className='twitter-icon'><FontAwesomeIcon icon={faTwitter} /></a></li>
                                <li><a href='https://t.me/metamuto' target="_blank" className='facebook-icon'><FontAwesomeIcon icon={faTelegram} /></a></li>
                                <li><a href='https://www.linkedin.com/company/metamuto/' target="_blank"  className='linkedin-icon'><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                            </ul>
                        </div>
                        {/* <div className='input-ft pt-4 mb-4'>
                            <form onSubmit={(e)=> {submitEmailFn(e)}}>
                                <span className='newsletter'>Subscribe to our newsletter</span>
                                <div class="input-group mb-3 pt-2">
                                    <input type="email" class="form-control" defaultValue={email} placeholder="Enter your email" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e)=> {setEmail(e.target.value)}} ></input>
                                    <button type='submit'><span class="input-group-text" id="basic-addon2">Subscribe</span></button>
                                </div>
                                {
                                    msg && <p className='text-danger'>{msg}</p>
                                }
                            </form>
                        </div> */}
                        <div className='copy-right text-light d-flex align-items-center justify-content-between flex-wrap'>
                            <ul className='list-unstyled d-flex align-items-center '>
                                <li><Link to="/privacy-policy">Privacy Policy &nbsp; &nbsp;~</Link> </li>
                                <li><Link to="/terms-condition">Terms And Condition &nbsp; &nbsp;</Link></li>
                            </ul>
                            <span className='all-right-reserved'>© 2023 MetaMuto. All Rights Reserved</span>
                        </div>
                    </div>
                </Row>
            </Container>
        </footer>
    )
}

const mapStateToProps = state => ({
    footer: state.footer,
});

export default connect(mapStateToProps, { beforeFooter, submitEmail  })(Footer
);