import React, { useState, useEffect } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Images } from '../../assets/assets'
import { Modal } from 'react-bootstrap';
import { ENV } from "../../config/config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import connectors from '../../utils/connectors';
import Logo from '../../assets/images/logo.png'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'

const { requiredChainId, explorers, nativeCurrencies, web3Providers, requiredChainName } = ENV;

const WalletConnect = (props) => {
    const {show, setShow} = props
    const navigate = useNavigate()

    const { activate, deactivate, active, account, library } = useWeb3React();

    useEffect(() => {
        if (props.loginKey.loginAuth) {
            // if(props.loginKey.userData.isNewUser){
            //     navigate('/profile')
            // }
            handleClose()
        }
    }, [props.loginKey.loginAuth])


    const handleClose = () => setShow(false);

    const connectWallet = (walletInjector, type) => {
        handleClose()
        const connector = walletInjector.provider;
        connector &&
            activate(connector, undefined, true)
                .then(async (res) => {
                    localStorage.setItem('connect', 'connected');
                    const networkDetails = {
                        chainId: `0x${Number(requiredChainId).toString(16)}`,
                        chainName: requiredChainName,
                        nativeCurrency: nativeCurrencies[requiredChainId],
                        rpcUrls: [web3Providers[requiredChainId]],
                        blockExplorerUrls: [explorers[requiredChainId]]
                    };
                    localStorage.setItem("provider", type);

                    await window?.ethereum?.request({
                        method: 'wallet_addEthereumChain',
                        params: [networkDetails],
                    });
                })
                .catch(async (error) => {
                    if (error instanceof UnsupportedChainIdError) {
                        const networkDetails = {
                            chainId: `0x${Number(requiredChainId).toString(16)}`,
                            chainName: requiredChainName,
                            nativeCurrency: nativeCurrencies[requiredChainId],
                            rpcUrls: [web3Providers[requiredChainId]],
                            blockExplorerUrls: [explorers[requiredChainId]]
                        };
                        await window?.ethereum?.request({
                            method: 'wallet_addEthereumChain',
                            params: [networkDetails],
                        });
                        activate(connector);
                    } else {
                    }
                });
    }
    return (
        <Modal  show={show} onHide={handleClose} className="metamask-modal" centered>
            <span className="modal-cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={handleClose} /></span>
            <Modal.Header closeButton className=' margin-bottom'>
                <Modal.Title>
                    <div className='modal-logo'>
                        <img src={Logo} alt='' className="img-fluid" />
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <span className="modal-head margin-bottom">Connect your wallet</span>
                <div className="yellow-div d-flex align-items-center justify-content-between margin-bottom" onClick={() => connectWallet(connectors.injected, "injected")}>
                    <span className="div-tag">MetaMask</span>
                    <span className="div-img"><img src={Images.metaMask} alt='' className="img-fluid" /></span>
                </div>
                <div className="blue-div d-flex align-items-center justify-content-between" onClick={() => connectWallet(connectors.walletConnect, "walletConnect")}>
                    <span className="div-tag">WalletConnect</span>
                    <span className="div-img"><img src={Images.vectorIcon} alt='' className="img-fluid" /></span>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = state => ({
    loginKey: state.loginKey,
});

export default connect(mapStateToProps, { })(WalletConnect);