import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ellipse from '../../../assets/images/ellipse.png'
import involveIconOne from '../../../assets/images/icons8-transparent-64.png'
import involveIconTwo from '../../../assets/images/icons8-lock-50.png'
import involveIconThree from '../../../assets/images/icons8-agile-64.png'
import involveIconFour from '../../../assets/images/icons8-autonomous-64.png'
import involveIconFive from '../../../assets/images/icons8-diversity-50.png'
import involveIconSix from '../../../assets/images/icons8-decentralized-network-50.png'
import mapImage from '../../../assets/images/involve-globe.png'
import shadedDot from '../../../assets/images/shaded-dot.png'
import mapVideo from '../../../assets/images/map-video.mp4'
import './get-involved.css'

const GetInvolved = () => {
    const involveCard = [
        { id: 1, title: "Transparent",  icon: involveIconOne },
        { id: 2, title: "Secure",  icon: involveIconTwo },
        { id: 3, title: "Agile",  icon: involveIconThree },
        { id: 4, title: "Autonomous",  icon: involveIconFour },
        { id: 5, title: "Diverse",  icon: involveIconFive },
        { id: 6, title: "Decentralized", icon: involveIconSix },
    ]
    return (
        <section className='get-involved'>
            <Container>
                <Row>
                    <Col lg={7}>
                        <div className='involved-content'>
                            <h4 className='custom-heading'>Get Involved</h4>
                            <p className='custom-paragraph'>Engage, signal, and vote on protocol decision</p>
                            <div className='d-flex align-items-start justify-content-center'>
                                <ul className='list-unstyled timeline pt-5'>
                                    <li className=''><img src={ellipse} className='img-fluid' alt='image' /></li>
                                    <li className=''><img src={ellipse} className='img-fluid' /></li>
                                    <li className=''><img src={ellipse} className='img-fluid' /></li>
                                </ul>
                                <div className='involved-card-area d-flex align-items-start justify-content-start flex-wrap pt-5'>
                                    {involveCard.map((i) =>
                                        <div className='involved-card'>
                                            <div className='involve-icon text-end mb-2 pe-3'><img className='img-fluid' src={i.icon} alt='image' /></div>
                                            <h4>{i.title}</h4>
                                            <p>{i.description}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className='involved-map'>
                            <div className='map-image'>
                                <img src={mapImage} className='img-fluid' alt='globe-image' />
                               <div className='map-video'>
                               <video autoPlay muted loop>
                                    <source src={mapVideo} type="video/mp4" />
                                </video>
                               </div>
                            </div>
                            <div className='shaded-dot'><img src={shadedDot} className='img-fluid' alt='globe-image' /></div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </section>
    )
}

export default GetInvolved