import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import ecoImageOne from '../../../assets/images/eco-1.png'
import ecoImageTwo from '../../../assets/images/eco-2.png'
import ecoImageThree from '../../../assets/images/eco-3.png'
import Lottie from "lottie-react";
import lottieCircle from "../../../utils/circle.json";
import './ecosystem.css'

const Ecosystem = () => {
    const ecoSyatem = [
        { id: 1, image: ecoImageOne, name: "Danish Hameed", desciption: "Co-Founder", about: "Danish is a Crypto enthusiast and blockchain entrepreneur. He has over five years of experience advising ICOs and companies in Asia regarding their private/public blockchain implementation and DApps. He has been a consultant in many successful projects and has guided many new CEOs to innovate new things." },
        { id: 2, image: ecoImageTwo, name: "Umer Surkhail", desciption: "Manager", about: "Technical Manager well-versed at managing multiple blockchain projects on time and within budget. Detail-oriented professional who thrives in a high-volume, multi-tiered environment. Effectively prioritize and manage multiple complex assignments and clients." },
        { id: 3, image: ecoImageThree, name: "Mohsin Laeeque", desciption: "DAO Developer", about: "Mohsin is one of the best blockchain developers. He has worked on many blockchain projects and has vast experience in his field. He has explored the fields like DeFi, NFT, Metaverse, Blockchain gaming, etc." },
    ]
    return (
        <section className='eco-system'>
            <Container>
                <Row>
                    <h6 className='custom-paragraph text-center'>MetamutoDAO</h6>
                    <h3 className='custom-heading text-center mb-5'>Ecosystem Leaders</h3>
                    {ecoSyatem.map((e) =>
                        <Col lg={4} md={6}>
                            <div className='eco-card'>
                                <div className='d-flex align-items-center mb-4'>
                                    <div className='eco-icon'>
                                        <img className='img-fluid' src={e.image} alt='image' />
                                    </div>
                                    <div className='eco-content ps-4'>
                                        <h6>{e.name}</h6>
                                        <span>{e.desciption}</span>
                                    </div>
                                </div>
                                <p>{e.about}</p>
                            </div>
                        </Col>
                    )}
                    <h2 className='heading-xl pt-5 mb-4'>HUNDREDS OF DECENTRALIZED  AUTONOMOUS</h2>
                    <div className='circle-lottie-area'>
                        <div className='circle-lottie'>
                            <Lottie animationData={lottieCircle} loop={true} />
                        </div>
                        <div className='text-center launch-app-btn'><Link to="/app" type='button' className='custom-lg-btn btn'>Launch App</Link></div>
                    </div>
                </Row>
            </Container>
        </section>
    )
}

export default Ecosystem