import React from 'react'
import { faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from 'react-bootstrap';

const AlertModal = ({show, setShow, msg, success}) => {
    return (
        <div className='alert-modal-menu'>
        <Modal show={show} onHide={()=>setShow(false)} className="metamask-modal" centered>
          <span className="modal-cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={()=>setShow(false)} /></span>
            <Modal.Body className="d-flex justify-content-center align-items-center">
              <div>
              {
                success === false ? 
                <span  className='d-flex justify-content-center align-items-center mb-4' ><FontAwesomeIcon className='alert-icon' icon={faTriangleExclamation} /></span> 
                : ''  
              }
                <span className="modal-head margin-bottom text-center">{msg}</span>
              </div>
            </Modal.Body>
        </Modal>
        </div>
    )
}

export default AlertModal