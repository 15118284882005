import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'

const defaultPermissions = [3, 4, 10, 11]

const Permission = ({ allIsNot, groups, setPermission, name, description }) => {

    useEffect(() => {
        defaultPermissions.map((e) => {
            onChangeAll(e, true)
        })
    }, [])

    const onChangeAll = (type, value) => {
        if (value) {
            for (let index = 0; index < groups.length; index++) {
                const e = groups[index];
                const position = index
                document.getElementById(`${name}_${e}_${type}`).checked = true
            }
        } else {
            for (let index = 0; index < groups.length; index++) {
                const e = groups[index];
                const position = index
                document.getElementById(`${name}_${e}_${type}`).checked = false
            }
        }
        permissionResult()
    }

    const permissionResult = () => {
        const obj = {}
        for (let index1 = 0; index1 < groups.length; index1++) {
            for (let index = 1; index <= 13; index++) {
                let e = groups[index1].toString();
                const type = index;
                let flag = document.getElementById(`${name}_${e}_${type}`)?.checked
                if (flag) {
                    if (obj[e]) {
                        obj[e].push(type)
                    } else {
                        obj[e] = []
                        obj[e].push(type)
                    }
                }
            }
        }
        // let permissionsArray = Object.values(obj);
        console.log("obj in permission = ",obj)
        setPermission(obj)
    }

    const unCheckAll = (id, checker) => {
        console.log("id, checker", id, checker)
        if (!checker) {
            document.getElementById(`${id}`).checked = false
        }
    }

    const magicTick = (type, id) => {
        let allChecked = true
        for (let index = 0; index < groups.length; index++) {
            console.log('{name}_${groups[index]}_${type}', `${name}_${groups[index]}_${type}`)
            let flag = document.getElementById(`${name}_${groups[index]}_${type}`)?.checked
            if(!flag){
                allChecked = false 
                return
            }
        }
        if(allChecked){
            document.getElementById(`${id}`).checked = true
        }
     }

    return (
        <>
            <label>{description}</label>
            <div className='table-responsive'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th></th>
                            {!allIsNot && <th>all</th>}
                            {
                                groups.map((e, index) => {
                                    return <th key={index} >{e}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Transfer</td>
                            {!allIsNot &&
                                <td >
                                    <div className="checkbox">
                                        <label class="right-label-checkbox">
                                            <input type="checkbox" id={`${name}_all_1`} onChange={(e) => { onChangeAll(1, e.currentTarget.checked) }} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </td>}
                            {/* <input type="checkbox" onChange={(e) => { onChangeAll(1, e.currentTarget.checked) }} /></td> */}
                            {
                                groups.map((e, index) => {
                                    return <td key={index}  >
                                        <label class="right-label-checkbox">
                                            <input type="checkbox" id={`${name}_${e}_1`} onChange={(e) => { unCheckAll(`${name}_all_1`, e.currentTarget.checked); permissionResult(); magicTick(1, `${name}_all_1`) }} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                })
                            }
                        </tr>
                        <tr>
                            <td>Bounty</td>
                            {!allIsNot &&
                                <td>

                                    <label class="right-label-checkbox">
                                        <input type="checkbox" id= {`${name}_all_2`} onChange={(e) => { onChangeAll(2, e.currentTarget.checked) }} />
                                        <span class="checkmark"></span>
                                    </label>
                                    {/* <input type="checkbox" onChange={(e) => { onChangeAll(2, e.currentTarget.checked) }} /> */}
                                </td>
                            }
                            {
                                groups.map((e, index) => {
                                    return <td key={index} >
                                        <label class="right-label-checkbox">
                                            <input type="checkbox" id={`${name}_${e}_2`} onChange={(e) => { unCheckAll(`${name}_all_2`, e.currentTarget.checked); permissionResult(); magicTick(2, `${name}_all_2`) }} />
                                            <span class="checkmark"></span>
                                        </label>


                                    </td>
                                })
                            }
                        </tr>
                        <tr>
                            <td>Change DAO Name</td>
                            {!allIsNot &&
                                <td>
                                    <label class="right-label-checkbox">
                                        <input type="checkbox" id={`${name}_all_3`} onChange={(e) => { onChangeAll(3, e.currentTarget.checked) }} />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                            }
                            {
                                groups.map((e, index) => {
                                    if (e === 'council') {
                                        return (
                                            <td key={index} >
                                                <label class="right-label-checkbox">
                                                    <input type="checkbox" value={true} id={`${name}_${e}_3`} onChange={(e) => { unCheckAll(`${name}_all_3`, e.currentTarget.checked); permissionResult(); magicTick(3, `${name}_all_3`) }} />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </td>
                                        )
                                    } else {
                                        return <td key={index} >
                                            <label class="right-label-checkbox">
                                                <input type="checkbox" id={`${name}_${e}_3`} onChange={(e) => { unCheckAll(`${name}_all_3`, e.currentTarget.checked); permissionResult(); magicTick(3, `${name}_all_3`) }} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </td>
                                    }
                                })
                            }
                        </tr>
                        <tr>
                            <td>Change DAO Purpose</td>
                            {!allIsNot &&
                                <td>
                                    <label class="right-label-checkbox">
                                        <input type="checkbox" id={`${name}_all_4`} onChange={(e) => { onChangeAll(4, e.currentTarget.checked) }} />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                            }
                            {
                                groups.map((e, index) => {
                                    return <td key={index} >
                                        <label class="right-label-checkbox">
                                            <input type="checkbox" id={`${name}_${e}_4`} onChange={(e) => { unCheckAll(`${name}_all_4`, e.currentTarget.checked); permissionResult(); magicTick(4, `${name}_all_4`) }} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                })
                            }
                        </tr>
                        <tr>
                            <td>Change DAO Link</td>
                            {!allIsNot &&
                                <td>
                                    <label class="right-label-checkbox">
                                        <input type="checkbox" id={`${name}_all_5`} onChange={(e) => { onChangeAll(5, e.currentTarget.checked) }} />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                            }
                            {
                                groups.map((e, index) => {
                                    return <td key={index} >
                                        <label class="right-label-checkbox">
                                            <input type="checkbox" id={`${name}_${e}_5`} onChange={(e) => { unCheckAll(`${name}_all_5`, e.currentTarget.checked); permissionResult(); magicTick(5, `${name}_all_5`) }} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                })
                            }
                        </tr>
                        <tr>
                            <td>Change DAO Flag and Logo</td>
                            {!allIsNot &&
                                <td>
                                    <label class="right-label-checkbox">
                                        <input type="checkbox" id={`${name}_all_6`} onChange={(e) => { onChangeAll(6, e.currentTarget.checked) }} />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                            }
                            {
                                groups.map((e, index) => {
                                    return <td key={index} >
                                        <label class="right-label-checkbox">
                                            <input type="checkbox" id={`${name}_${e}_6`} onChange={(e) => { unCheckAll(`${name}_all_6`, e.currentTarget.checked); permissionResult(); magicTick(6, `${name}_all_6`) }} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                })
                            }
                        </tr>
                        <tr>
                            <td>Change DAO Legal Status and Doc</td>
                            {!allIsNot &&
                                <td>
                                    <label class="right-label-checkbox">
                                        <input type="checkbox" id={`${name}_all_7`} onChange={(e) => { onChangeAll(7, e.currentTarget.checked) }} />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                            }
                            {
                                groups.map((e, index) => {
                                    return <td key={index} >
                                        <label class="right-label-checkbox">
                                            <input type="checkbox" id={`${name}_${e}_7`} onChange={(e) => { unCheckAll(`${name}_all_7`, e.currentTarget.checked); permissionResult(); magicTick(7, `${name}_all_7`) }} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                })
                            }
                        </tr>
                        <tr>
                            <td>Create Claim</td>
                            {!allIsNot &&
                                <td>
                                    <label class="right-label-checkbox">
                                        <input type="checkbox" id={`${name}_all_8`} onChange={(e) => { onChangeAll(8, e.currentTarget.checked) }} />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                            }
                            {
                                groups.map((e, index) => {
                                    return <td key={index} >
                                        <label class="right-label-checkbox">
                                            <input type="checkbox" id={`${name}_${e}_8`} onChange={(e) => { unCheckAll(`${name}_all_8`, e.currentTarget.checked); permissionResult(); magicTick(8, `${name}_all_8`) }} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                })
                            }
                        </tr>
                        <tr>
                            <td>Create Group</td>
                            {!allIsNot &&
                                <td>
                                    <label class="right-label-checkbox">
                                        <input type="checkbox" id={`${name}_all_9`} onChange={(e) => { onChangeAll(9, e.currentTarget.checked) }} />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                            }
                            {
                                groups.map((e, index) => {
                                    return <td key={index} >
                                        <label class="right-label-checkbox">
                                            <input type="checkbox" id={`${name}_${e}_9`} onChange={(e) => { unCheckAll(`${name}_all_9`, e.currentTarget.checked); permissionResult(); magicTick(9, `${name}_all_9`) }} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                })
                            }
                        </tr>
                        <tr>
                            <td>Add Members</td>
                            {!allIsNot &&
                                <td>

                                    <label class="right-label-checkbox">
                                        <input type="checkbox" id={`${name}_all_10`} onChange={(e) => { onChangeAll(10, e.currentTarget.checked) }} />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                            }
                            {
                                groups.map((e, index) => {
                                    return <td key={index} >
                                        <label class="right-label-checkbox">
                                            <input type="checkbox" id={`${name}_${e}_10`} onChange={(e) => { unCheckAll(`${name}_all_10`, e.currentTarget.checked); permissionResult(); magicTick(10, `${name}_all_10`) }} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                })
                            }
                        </tr>
                        <tr>
                            <td>Remove Members</td>
                            {!allIsNot &&
                                <td>
                                    <label class="right-label-checkbox">
                                        <input type="checkbox" id={`${name}_all_11`} onChange={(e) => { onChangeAll(11, e.currentTarget.checked) }} />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                            }
                            {
                                groups.map((e, index) => {
                                    return <td key={index} >
                                        <label class="right-label-checkbox">
                                            <input type="checkbox" id={`${name}_${e}_11`} onChange={(e) => { unCheckAll(`${name}_all_11`, e.currentTarget.checked); permissionResult(); magicTick(11, `${name}_all_11`) }} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                })
                            }
                        </tr>
                        <tr>
                            <td>Polls</td>
                            {!allIsNot &&
                                <td>
                                    <label class="right-label-checkbox">
                                        <input type="checkbox" id={`${name}_all_12`} onChange={(e) => { onChangeAll(12, e.currentTarget.checked) }} />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                            }
                            {
                                groups.map((e, index) => {
                                    return <td key={index} >
                                        <label class="right-label-checkbox">
                                            <input type="checkbox" id={`${name}_${e}_12`} onChange={(e) => { unCheckAll(`${name}_all_12`, e.currentTarget.checked); permissionResult(); magicTick(12, `${name}_all_12`) }} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>
                                })
                            }
                        </tr>
                        <tr>
                            <td>Function Call</td>
                            {!allIsNot &&
                                <td>
                                    <label class="right-label-checkbox">

                                        <input type="checkbox" id={`${name}_all_13`} onChange={(e) => { onChangeAll(13, e.currentTarget.checked) }} />
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                            }
                            {
                                groups.map((e, index) => {
                                    return <td key={index} >
                                        <label class="right-label-checkbox">

                                            <input type="checkbox" id={`${name}_${e}_13`} onChange={(e) => { unCheckAll(`${name}_all_13`, e.currentTarget.checked); permissionResult(); magicTick(13, `${name}_all_13`) }} />
                                            <span class="checkmark"></span>
                                        </label>

                                    </td>
                                })
                            }
                        </tr>
                    </tbody>
                </Table>
            </div>
        </>
    )
}


export default Permission