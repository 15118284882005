import { ENV } from "../config/config";

export const uploadToCloudinary = async (file) => {
    return new Promise((resolve)=>{
        const formData = new FormData();
        formData.append('file', file);

        formData.append('upload_preset', 'fklaxuht');
        const options = {
            method: 'POST',
            body: formData,
        };
        fetch(`https://api.Cloudinary.com/v1_1/arhamsoft-ltd/image/upload`, options)
        .then(res => res.json())
        .then(res => {
            resolve(res)
        })
        .catch(err => console.log("Error : ",err));
    })
}
