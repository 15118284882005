import React, { useEffect, useState } from 'react';
import { addMemberCommunity, beforeCommunity, getCommunity, checkMember } from "../../redux/communities/community.action";
import { connect } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { ENV } from "../../config/config";
import { joinCommunity } from "../../utils/web3";




function MemberRequest(props) {
    const { address, slug } = useParams()
    const [communityData, setCommunityData] = useState()
    const [memberData, setMemberData] = useState()
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const { _id } = ENV.getUserKeys('_id');


    useEffect(() => {

        props.checkMember(address)
        props.getCommunity(slug);
    }, [])


    const acceptInvitation = async () => {
        setLoader(true)
        const tx = await joinCommunity(communityData?.communityId)
        if (tx) {
            let payload = { address, slug }
            props.addMemberCommunity(payload)
        }
        else {
            setLoader(false)
        }

    }

    useEffect(() => {
        if (props.community.getCommunityAuth) {
            const { community } = props.community.community
            setCommunityData(community)
        }
    }, [props.community.getCommunityAuth])



    useEffect(() => {
        if (props.community.addCommunityMemberAuth) {
            props.beforeCommunity()
            navigate(`/community/${slug}`)
        }
    }, [props.community.addCommunityMemberAuth])


    useEffect(() => {
        if (props.community.checkMemberAuth) {
            props.beforeCommunity()
            const { status } = props.community.memberData
            setMemberData(status)
        }
    }, [props.community.checkMemberAuth])



    return (
        <>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <Modal.Title>Member Invitation Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please Accept the request to be the part of community.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button disabled={loader || !_id} onClick={() => acceptInvitation()} className="freedom-btn freedom-outline-btn transition ms-3 d-flex justify-content-center align-items-center">{loader && <div className="loader me-2"></div>}Accept Request</button>
                    <button onClick={() => window.close()} className="freedom-btn freedom-outline-btn transition ms-3">Decline Request</button>
                </Modal.Footer>
            </Modal.Dialog>
        </>
    );
}



const mapStateToProps = state => ({
    community: state.community
});

export default connect(mapStateToProps, { addMemberCommunity, beforeCommunity, getCommunity, checkMember })(MemberRequest);
