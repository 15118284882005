import { MEMBER_COMMUNITIES,GET_DAO_MEMBERS,FETCH_MEMBERS, BEFORE_COMMUNITY, ADD_COMMUNITY, GET_USER_COMMUNITIES, GET_ERRORS, GET_COMMUNITIES, GET_COMMUNITY, ADD_MEMBERS, ADD_MEMBERS_COMMUNITY, CHECK_MEMBER, CHECK_VALIDATION_COMM, BEFORE_CHECK_VALIDATION_COMM } from "../types";
import { ENV } from "../../config/config";
import { emptyError } from '../shared/error/error.action';

// const { address } = ENV.getUserKeys('address');
// const e  = ENV.getUserKeys()
// console.log("e user in emv = ", e)

export const beforeCommunity = () => {
    return {
        type: BEFORE_COMMUNITY
    }
}
export const addCommunity = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}dao/create`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: ADD_COMMUNITY,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const checkDaoValidation = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}dao/check-validation`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: CHECK_VALIDATION_COMM,
                payload: {isValid: data.isValid, message: data.message}
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const beforeCheckValidation = () => {
    return {
        type: BEFORE_CHECK_VALIDATION_COMM
    }
}

export const getUserCommunities = (qs = null) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}dao/list-user-daos/`;

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_USER_COMMUNITIES,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const getCommunities = (qs = null) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}dao/list-daos/`;

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_COMMUNITIES,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const getCommunity = (slugAddress) => dispatch => {
    dispatch(emptyError());
    const { address } = ENV.getUserKeys('address');

    let url; 
    if(address){
        url = `${ENV.url}dao/get-dao/${slugAddress}?walletAddress=${address}`;
    }else {
        url = `${ENV.url}dao/get-dao/${slugAddress}`;
    }
    
    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_COMMUNITY,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const addMembers = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}dao/invite-member/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: ADD_MEMBERS,
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const getMemberCommunities = (qs = null) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}dao/user-communities`;

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: MEMBER_COMMUNITIES,
                payload: data.data.userCommunities
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};


export const addMemberCommunity = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}dao/add-member/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: ADD_MEMBERS_COMMUNITY,
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const checkMember = (address) => dispatch => {
    dispatch(emptyError());
    
    let url = `${ENV.url}dao/check-member/${address}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: CHECK_MEMBER,
                payload: data
            })
        } else {
            alert(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

