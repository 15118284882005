import { combineReducers } from 'redux'
import loginReducer from "../components/header/header.reducer";
import communityReducer from "./communities/community.reducer";
import proposalReducer from './proposal/proposal.reducer';
import voteReducer from './voting/vote.reducer';
import profileReducer from './profile/profile.reducer';
import membersReducer from './members/members.reducer';
import errorReducer from './shared/error/error.reducer';
import footerReducer from './footer/footer.reducer';
import investorReducer from './investor/investor.reducer';

export default combineReducers({
    loginKey: loginReducer,
    community: communityReducer,
    investor: investorReducer,
    proposal: proposalReducer,
    vote: voteReducer,
    // profileData:profileReducer,
    member:membersReducer,
    error : errorReducer,
    footer: footerReducer
})