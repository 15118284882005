import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import CopyLink from "../../assets/images/copy-link.svg"
import Message from '../../assets/images/tab1.png'
import MessageActive from '../../assets/images/tab1-active.png'
import Tab2 from '../../assets/images/tab2.png'
import Tab2Active from '../../assets/images/tab2-active.png'
import Tab3 from '../../assets/images/tab3.png'
import Tab3Active from '../../assets/images/tab3-active.png'
import Tab4 from '../../assets/images/tab4.png'
import Tab4Active from '../../assets/images/tab4-active.png'
import './community.css'
import LatestTab from '../../components/latestTab/latestTab';
import MemberTab from '../../components/memberTab/memberTab';
import ProposalTab from '../../components/propposalTab/proposalTab';
import CommunityDetailTab from "../../components/communityDetailTab/communityDetailTab";
import TreasuryTab from '../../components/treasuryTab/treasuryTab';
import { useParams } from "react-router-dom";
import { getCommunity } from '../../redux/communities/community.action';
import { connect } from 'react-redux'
import { getProposals, beforeProposal } from '../../redux/proposal/proposal.action';
import FullPageLoader from '../../components/full-page-loader/full-page-loader'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ENV } from '../../config/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowTurnDown } from '@fortawesome/free-solid-svg-icons'
import { Images } from '../../assets/assets'
import { depositToCommunity, getCommunityDetail, availableBalanceFn } from '../../utils/web3'
// import { toast } from "react-toastify";
import WalletConnect from '../../components/walletConnect/walletConnect';

function CreateDetail(props) {

    const { address } = useParams();
    const [comm, setComm] = useState();
    const [proposals, setProposals] = useState()
    const [loader, setLoader] = useState(true)
    const [isCopied, setIsCopied] = useState(false);
    const [depositAmount, setDepositAmount] = useState("");
    const [error, setError] = useState()
    const [showWalletConnect, setShowWalletConnect] = useState()
    const [commFund, setCommFund] = useState(0)
    const [tabN, setTabN] = useState("first")

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        props.getCommunity(address);
    }, [])

    useEffect(() => {
        if (props.community.getCommunityAuth) {
            const comm = props.community.community;
            console.log("hellooooooo", comm)
            // props.beforeProposal()
            // props.getProposals(`daoId=${comm?.community?._daoId}`)
            setComm(comm)
            getCommDetail(comm?.community?._daoId)
        }
    }, [props.community.getCommunityAuth])

    // useEffect(() => {
    //     if (props.proposal.getProposalsAuth) {
    //         setProposals(props.proposal.proposals)
    //         setLoader(false)
    //     }
    // }, [props.proposal.getProposalsAuth])


    const getCommDetail = async (daoId) => {
        const res = await getCommunityDetail(daoId)
        setCommFund(res)
        setLoader(false)

    }

    const depositFn = async (e) => {
        e?.preventDefault()
        setError('')
        if (!props.userData?.userData?.address) {
            setShowWalletConnect(true)
        } else if (depositAmount > 0) {
            const availableBalance = await availableBalanceFn()
            if (availableBalance >= depositAmount) {
                setLoader(true)
                const res = await depositToCommunity(comm?.community?._daoId, depositAmount, props.userData?.userData?.address)
                if (res) {
                    // toast.error("Deposited !")
                    setDepositAmount("")
                    getCommDetail(comm?.community?._daoId)
                } else {
                    // toast.error("Deposit Failed!")
                }
                setLoader(false)
            } else {
                setLoader(false)
                setError("You don't have enough tokens.")
            }
        } else {
            setError("Amount should be greater than 0.")
        }
    }

    return (
        <div className='community-details'>
            {loader ? <FullPageLoader /> : ''}
            <div className="page-header  d-flex justify-content-between flex-column flex-md-row">
                <h2 className="mb-3 mb-md-0 font-weight-bold">Community/{comm?.community?.name}</h2>

                <form className="deposit-form position-relative" key="depositAmt_1" onSubmit={(e) => depositFn(e)}>
                    <div className="input-holder">
                        <input
                            type="text"
                            autocomplete="off"
                            placeholder="Add Muto Token"
                            value={depositAmount}
                            onKeyDown={(e) => ENV.decimalNumberValidator(e)}
                            onChange={(e) => {
                                setDepositAmount(e.target.value.includes(".") ? e.target.value : e.target.value ? parseFloat(e.target.value || 0) : '');
                                setError("");
                            }} />
                    </div>
                    <span className="muto-icon">
                        <img className="img-fluid" src={Images.mutoM} alt="" />
                    </span>
                    <button type='submit'>
                        <span className="enter-icon">
                            <FontAwesomeIcon icon={faArrowTurnDown} />
                        </span>
                    </button>
                    {error && <span className='text-danger ps-2 pt-2'>{error}</span>}
                </form>
            </div>
            <p className="font-weight-bold text-white mb-3 funds">Funds Available : {commFund}</p>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="lower-header d-flex flex-column flex-md-row align-items-center justify-content-between flex-wrap">
                    <div className="heading-col mb-3 mb-md-0">
                        <div className="d-flex">
                            <div className="copy-icon position-relative">
                                <div className="d-flex align-items-center">
                                    <strong className="d-block me-2">{comm?.community?.name}</strong>
                                    <CopyToClipboard text={`${ENV.appURL}/community/${comm?.community?.address}`} onCopy={onCopyText}>
                                        <div className="copy-icon position-relative">
                                            <img className="cursor-pointer" src={CopyLink} alt="" />
                                            {
                                                isCopied &&
                                                <span className={`copy-feedback-text position-absolute d-block `}>
                                                    Copied!
                                                </span>
                                            }
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                        {/* <strong className="d-block">{comm?.community?.name}</strong> */}
                        {/* <div className="d-flex">
                            <Link className="me-2" to={`${ENV.appURL}communities/${comm?.community?.address}`}>{ENV.appURL}communities/{comm?.community?.address}</Link>
                            <CopyToClipboard text={`${ENV.appURL}/communities/${comm?.community?.address}`} onCopy={onCopyText}>
                                <div className="copy-icon position-relative">
                                    <img className="cursor-pointer" src={CopyLink} alt="" />
                                    {
                                        isCopied &&
                                        <span className={`copy-feedback-text position-absolute d-block `}>
                                            Copied!
                                        </span>
                                    }
                                </div>
                            </CopyToClipboard>
                        </div> */}
                    </div>
                    <div className="community-detail-tabs">
                        <Nav variant="pills" className="d-flex">
                            {/* <Nav.Item>
                                <Nav.Link eventKey="first">
                                    <div className='d-flex justify-content-center'>
                                        <img className='default-state' src={Message} alt='' />
                                        <img className='active-state' src={MessageActive} alt='' />
                                    </div>
                                    Latest</Nav.Link>
                            </Nav.Item> */}
                            <Nav.Item>
                                <Nav.Link eventKey="first" onClick={() => setTabN("first")}>
                                    <div className='d-flex justify-content-center'>
                                        <img className='default-state' src={Tab2Active} alt='' />
                                        <img className='active-state' src={Tab2Active} alt='' />
                                    </div>
                                    Community</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second" onClick={() => setTabN("second")}>
                                    <div className='d-flex justify-content-center'>
                                        <img className='default-state' src={Tab2Active} alt='' />
                                        <img className='active-state' src={Tab2Active} alt='' />
                                    </div>
                                    Proposals</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third" onClick={() => setTabN("third")}>
                                    <div className='d-flex justify-content-center'>
                                        <img className='default-state' src={Tab3Active} alt='' />
                                        <img className='active-state' src={Tab3Active} alt='' />
                                    </div>
                                    Groups &amp; Members</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                <Nav.Link eventKey="forth">
                                    <div className='d-flex justify-content-center'>
                                        <img className='default-state' src={Tab4} alt='' /> 
                                        <img className='active-state' src={Tab4Active} alt='' /> 
                                        </div>
                                    Treasury</Nav.Link>
                            </Nav.Item> */}
                        </Nav>
                    </div>
                </div>
                <Tab.Content>
                    {
                        tabN === "first" ?
                            <CommunityDetailTab community={props?.community?.community?.community} />
                            :
                            tabN === "second" ?
                                <ProposalTab comm={props?.community?.community?.community} />
                                :
                                tabN === "third" ?
                                    <MemberTab members={comm?.memberList} groups={comm?.groups} />
                                    : ''
                    }

                    {/* <Tab.Pane eventKey="forth">
                        <TreasuryTab />
                    </Tab.Pane> */}
                </Tab.Content>
            </Tab.Container>
            <WalletConnect show={showWalletConnect} setShow={setShowWalletConnect} />
        </div>
    )
}
const mapStateToProps = state => ({
    community: state.community,
    proposal: state.proposal,
    userData: state.loginKey,
})
export default connect(mapStateToProps, { getCommunity, getProposals, beforeProposal })(CreateDetail)