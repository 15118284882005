import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Person1 from "../../assets/images/person-1.png"
import { getCommunity, beforeCommunity, addMembers } from "../../redux/communities/community.action";
import { connect } from "react-redux"
import '../../pages/communities/community.css'
import { faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from 'react-bootstrap';
import proposalTypes from "../../utils/data/proposalTypes";

function MemberTab({ members, groups }) {
    const [show, setShow] = useState(false)

    const [creationPremission, setCreationPremission] = useState([])
    const [votingPremission, setVotingPremission] = useState([])

    const showPermission = (creation, voting) => {
        setCreationPremission(creation)
        setVotingPremission(voting)
        setShow(true)
    }
    console.log("create permission = ", creationPremission)
    console.log("proposal type = ", proposalTypes)
    return (
        <div className="main-body">
            {/* <Row>
                <Col md={12}>
                    <div className="btns-holder top-head d-flex flex-wrap justify-content-between align-items-center">
                        <strong className="top-heading">Members (<span className="simple-text">Showing </span>{members?.length} <span className="simple-text">members</span>)</strong>
                    </div>
                </Col>
            </Row> */}
            <Row>
                {
                    groups?.map((e) => {
                        return (
                            <>

                                <Col md={12}>
                                    <div className="btns-holder top-head d-flex flex-wrap justify-content-between align-items-center">
                                        <strong className="top-heading title">Id # {e._groupId} - {e.name}</strong>
                                        <span className="freedom-btn freedom-solid-btn transition ms-md-3 cursor-pointer" onClick={() => { showPermission(e.proposalCreationRights, e.votingPermissions) }}>View Group Permissions</span>
                                    </div>
                                </Col>
                                {members?.length > 0 ? members.map((item) =>
                                    e.name === item.groupName && item.walletAddress &&
                                    <Col xl={3} lg={6} md={4} sm={6}>
                                        <Card className="member-card">
                                            <div className="card-body text-center">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div className="text-center mb-2 member-img ">
                                                        <img src={item.logo || Person1} className="img-fluid" alt="profile-image" />
                                                    </div>
                                                </div>
                                                <strong className="title">{item.userName || "Unknown"}</strong>
                                                <strong>{item.walletAddress}</strong>
                                            </div>
                                        </Card>
                                    </Col>
                                ) : ""}
                            </>
                        )
                    })
                }
            </Row>
            <div className='alert-modal-menu'>
                <Modal size="xl" show={show} onHide={() => setShow(false)} className="metamask-modal membertab-modal" centered>
                    <span className="modal-cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={() => setShow(false)} /></span>
                    <Modal.Body>
                        <div>
                            <Row>
                                <Col md={6}>
                                    <h5 className="mb-5 text-white">Proposal Creation</h5>
                                    {
                                        proposalTypes?.map((e) => {
                                            if (creationPremission.includes(e.value)) {
                                                return (
                                                    <div className="mb-3">
                                                        <label class="right-label-checkbox mb-3">{e.label}
                                                            {/* {proposalTypes[e-1]?.name} */}
                                                            <input type="checkbox" defaultChecked={true} disabled={true} />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </Col>
                                <Col md={6}>
                                    <h5 className="mb-5 text-white">Voting</h5>
                                    {
                                        proposalTypes?.map((e) => {
                                            if (votingPremission.includes(e.value)) {
                                                return (
                                                    <div className="mb-3">
                                                        <label class="right-label-checkbox mb-3">{e.label}
                                                            <input type="checkbox" defaultChecked={true} disabled={true} />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </Col>

                                {
                                    !votingPremission.length && !creationPremission.length ? <div className="d-flex justify-content-center align-items-center"><h5 className="text-white">You don't have any permission yet.</h5></div> : ''
                                }
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>







            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    community: state.community
});

export default connect(mapStateToProps, { getCommunity, beforeCommunity, addMembers })(MemberTab);