import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import './community.css'

function TermsConditions() {
    window.scrollTo(0, 0);
    return (
        <div >
            <Container >
                <div className="terms-conditions main-body mt-0">


                    <div className="mb-2">
                        <h2 className="mb-3 text-center pt-4">Terms and Conditions for <strong style={{ color: "#2b56f5" }}>MutoDAO</strong></h2>
                        <p className="mb-3">This end-user pact (the "Agreement," "Terms of Use") should be read and accepted by the "User" in its entirety before he starts using MutoDAO and its services. Furthermore, be aware that this Agreement constitutes a legally binding agreement between the User and MutoDAO (referred to herein as "MutoDAO," "us," or "we"), which owns, operates, and maintains this platform <a target="_blank" rel="" href={'https://MutoDAO.metamuto.com'}>"https://MutoDAO.metamuto.com"</a> (website and all its modules and functionalities together referred to as "Service.")</p>
                        <p className="mb-3">As soon as you access and start using our Service, we consider it your formal consent for this Agreement, believing that you have read and understood the Terms of Use and agree to be bound by them.</p>
                    </div>


                    <div className="mb-2 mt50">
                        <h3 className="mb-3">Grant of License:</h3>
                        <ul className="mb-3">
                            <li>MutoDAO officially allows the User a personal, non-exclusive, and non-transferable right to use the Service on his personal computer or other devices that access the Internet to access and use the services described on the MutoDAO), subject to the Terms of Use contained herein.</li>
                            <li>The Service is not for use by</li>
                            <ul className="mb-3">
                                <li>Individuals under the age of 13 years</li>
                                <li>Minors and individuals under the age of majority adulthood in their specific jurisdiction(s)</li>
                                <li>Individuals who access and use the Service from any jurisdiction in which it is illegal to do so.</li>
                                <p>MutoDAO cannot verify the legality of the Service in every jurisdiction. Therefore, it is entirely up to the User to determine whether or not their use of the Service is lawful.</p>
                            </ul>

                            <li>MutoDAO and its licensors are the sole holders of all rights in and to the Service and code, technology, organization, structure, and architecture, including copyright, trade secrets, intellectual property, and other rights. You may not:</li>
                            <ul className="mb-3">
                                <li>Copy, distribute, publish, create derivative works, reverse engineer, modify, decompile, disassemble, or translate the MutoDAO website or the Service.</li>
                                <li>Use the Service if prohibited (for any reason) by applicable laws in any jurisdiction.</li>
                                <p className="mb-3">The user may face the consequences of the Unauthorized Use of this Service, and MutoDAO will not take any responsibility in such a case.</p>
                            </ul>

                            <li>MutoDAO reserves all implied or otherwise rights not expressly granted to the User hereunder and retains all rights and interests in the Service. The User will be solely liable for any damage or cost arising from (or associated with) any Unauthorized Use of the Service. You shall notify MutoDAO immediately upon becoming aware of the commission by any person of any Unauthorized Use. You shall also provide MutoDAO with reasonable assistance with any investigations it conducts in light of your provided information in this respect.</li>
                            <li>The term "MutoDAO," its domain names, and any other trademarks or service marks used by <a target="_blank" rel="" href={'https://MutoDAO.metamuto.com'}>"https://MutoDAO.metamuto.com"</a> are solely owned by MutoDAO. Moreover, all content (textual and visual) on MutoDAO, such as text, images, animations, videos, audio, etc., belongs to MutoDAO. All of the website content and other assets related to the Service are copyright protected under the intellectual property or other rights.</li>
                            <li>The User hereby must acknowledge that by using the Service, he obtains no rights in the Website Content, Trademarks, and/or associated Service Marks or any part of it. The User, under no circumstances, may use the Website Content, Trademarks, and/or associated Service Marks without the written consent of MutoDAO. In addition, the User agrees to prevent doing anything that could potentially harm MutoDAO intellectual property rights and any part thereof.</li>
                        </ul>
                    </div>

                    <div className="mb-2 mt50">
                        <h3 className="mb-3">Restrictions</h3>
                        <p className="mb-3">You shall not use the Service except as expressly permitted in these Terms.  You shall not;</p>
                        <ul className="mb-3">
                            <li>Involve in theft, fraud, and other malicious activities when using MutoDAO. Any attempts to use our Service for illegal or dishonest purposes will not be tolerated. If we suspect that you are misusing MutoDAO in any way, we may suspend or terminate your account immediately.</li>
                            <li>Violate any of the applicable laws and regulations in any way.</li>
                            <li>Infringe any proprietary rights, including but not limited to copyrights, patents, trademarks, or trade secrets of MutoDAO.</li>
                            <li>Use the Service to transmit any data or material containing malware, spyware, keystroke loggers, Trojan horses, etc., of any harmful computer code designed to affect MutoDAO operations adversely.</li>
                            <li>Use any bots, web crawlers, deep linking, spiders, scripts, algorithms, automatic devices, etc., or any manual process equivalent to these automated processes to access, copy, replicate, bypass, or spy the Website or the Service.</li>
                            <li>Make archival copies or backups of the Website and the Service-related assets or any part of it; for example, de-compilation or disassembling the Site.</li>
                            <li>Violate public morals and interests or any legitimate interests and preferences of other Users using the Service. This may include but is not limited to the activities that disrupt, interfere with, prohibit, or negatively affect other Users.</li>
                            <li>Use the Site and the Service for market manipulation. This may include but is not limited to wash trading, front running, spoofing, self-trading, etc., regardless of whether prohibited by the law.</li>
                            <li>Attempt to access and use the Service or any part or function of the Website without the authorization of MutoDAO or tend to connect to any other system/networks associated with our Service by password mining, cyber hacking, or any other illegitimate or prohibited means.</li>
                            <li>Probe, scan or test the MutoDAO vulnerabilities or any network or system connected to the Service properties or violate any authentication or security measures on the Website or any network/system connected thereto.</li>
                            <li>Track, reverse look-up, or seek to track any data of any other User or visitor of MutoDAO.</li>
                            <li>Use any software, device, or routine programs to interfere and impede MutoDAO typical operation of any transaction or any other individual's use of the Service.</li>
                            <p className="mb-3">By accessing and using MutoDAO, the User agrees to the right of the Service provider to investigate any violation of the abovementioned Terms and Conditions. The Service provider can unilaterally determine whether the User has violated these clauses and take all legitimate actions under relevant laws and regulations without the User's consent or prior notice.</p>
                        </ul>
                    </div>

                </div>
            </Container>
        </div>

    )
}
export default TermsConditions