import React from "react";
import '../../pages/communities/community.css'
import { Link } from 'react-router-dom';
import ProposalList from "../proposalList/proposalList";

const ProposalTab = (props) => {
    console.log("dao id = ", props?.comm?._daoId)
    return (
        
        <div className="main-body proposal-listing justify-content-center align-items-center">
            <div className="top-header">
                <Link to={`/create-proposal/${props?.comm?.address}/${props?.comm?._daoId}`} className="freedom-btn freedom-solid-btn transition text-center">Create A Proposal</Link>
            </div>
            <div className="no-data">
            <ProposalList from={"proposalTab"} daoId = {props?.comm?._daoId} />
            </div>
        </div>
    )
}

export default ProposalTab