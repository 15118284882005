import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import smallMissionImage from '../../../assets/images/small-mission-img.png';
import './ourInvestors.css'
import { connect } from 'react-redux';
import { beforeInvestors, getInvestors } from '../../../redux/investor/investor.action';
import FullPageLoader from "../../full-page-loader/full-page-loader";
import { Images } from '../../../assets/assets';

const OurInvestors = (props) => {
    const [ investors, setInvestors ] = useState([]);
    const [ loader, setLoader ] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
        props.beforeInvestors()
        props.getInvestors()
    }, [])

    useEffect(() => {
        if (props.investor.getInvestorDataAuth) {
            setInvestors([...props.investor.investors])
            setLoader(false)
        } else {
            setLoader(true)
        }
    }, [props.investor.getInvestorDataAuth])
    
    return (
        <>
            {loader ? <FullPageLoader /> : ''}
            <section className='mission-page'>
                <div className='rocket-small-image'>
                    <img className='img-fluid' src={smallMissionImage} alt='image' />
                </div>
                <div className="mission-content">
                    <Container>
                        <h3>Our Investors</h3>
                        {/* <p>Lorem ipsum dolor sit ammet</p> */}
                        <Row>
                            {investors.map((obj, index) => {
                                return (
                                    <Col xl={3} md={4} sm={6} key={index}>
                                        <div className="investor-block mb-5">
                                            <div className="image-holder mb-3">
                                                <img src={obj.profileImage || Images.Default_Logo} alt="" />
                                            </div>
                                            <div className="detail-holder d-flex flex-column">
                                                <strong className="investor-name mb-1">{`${obj.firstName} ${obj.lastName}`}</strong>
                                                <span className="designation">{obj.role}</span>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = state => ({
	investor: state.investor,
});

export default connect(mapStateToProps, { beforeInvestors, getInvestors })(OurInvestors);