import React from "react";
import { Col, Row } from "react-bootstrap";
import { Images } from '../../assets/assets'
import { Link } from 'react-router-dom';
import '../../pages/communities/community.css'

function LatestTab(){
    return(
        <div className="main-body d-flex justify-content-center align-items-center">
            <div className="no-data text-center">
                <span className="no-data-img"><img src={Images.noResult} alt='' className='img-fluid' /></span>
                <span className="no-data-tag mb-4 d-block">No Latest Details Found</span>
                <div>
                    <Link to='' className="freedom-btn freedom-solid-btn transition text-center">Create Latest</Link>
                </div>
            </div>
        </div>
    )
}
export default LatestTab