import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import aboutMissionImage from '../../../assets/images/rocket-img.png';
import smallMissionImage from '../../../assets/images/small-mission-img.png';
import { Link } from 'react-router-dom'
import '../mission/mission.css'
import Footer from '../../footer_1/Footer'

const Mission = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <section className='mission-page'>
                <div className='rocket-small-image'>
                    <img className='img-fluid' src={smallMissionImage} alt='image' />
                </div>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className='mission-content'>
                                <h3>Our mission</h3>
                                <p>Our mission is to empower communities and individuals by providing a decentralized, autonomous platform for decision-making and resource allocation.</p>
                                <p>Our DAO platform enables transparency, fairness, and security in all actions and transactions, fostering trust and collaboration among participants. We strive to create a decentralized future where individuals have more control over their assets and communities can come together to achieve shared goals.</p>
                                <p>We believe in the power of decentralization to create a more equitable and sustainable world.</p>
                                <div className='launch-app-btn'><Link to="/app" type='button' className='custom-lg-btn btn'>Launch App</Link></div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='rocket-image'>
                                <img className='img-fluid' src={aboutMissionImage} alt='image' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* <Footer/> */}
        </>
    )
}

export default Mission