import React, { useEffect, useState } from "react";
import '../../pages/proposals/proposal.css'
import Voting from "../votingThreshold/votingThreshold";
import { useNavigate } from 'react-router-dom';
import VotingThreshold from "../votingThreshold/votingThreshold";

function VotingDetail({ votes }){
    const navigate = useNavigate()
    const { disLikedVotes, likedVotes, abstainVotes, voteFloor } = votes
    const [ totalVotes, setTotalVotes ] = useState()


    useEffect(()=> {
        setTotalVotes(disLikedVotes+likedVotes+abstainVotes)
    },[disLikedVotes, likedVotes, abstainVotes])

    const calculateVotePercentage = ( val ) => {
        if(val){
            return (val/totalVotes*100).toFixed(2)
        }else{
            return 0
        }
    }

    
    return (
        <div className="vote-result">
            <h2>Vote Results</h2>
            <div className="d-flex justify-content-center align-items-center flex-wrap boxes">
                <div className="box">
                    <span>Total Votes</span>
                    <strong>{totalVotes}</strong>
                </div>
                <div className="box hide-box">
                    <span>Vote Floor</span>
                    <strong>{voteFloor}</strong>
                </div>
            </div>
            <VotingThreshold like={calculateVotePercentage(likedVotes)} dislike={calculateVotePercentage(disLikedVotes)} abstain={calculateVotePercentage(abstainVotes)} />
            <button className="freedom-btn freedom-outline-btn transition margin-auto mb-3" onClick={()=> navigate('/proposals')}>Back To Proposal List</button>

        </div>
    )
}

export default VotingDetail