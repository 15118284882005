import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Form, Row, Col, Container } from 'react-bootstrap'
import { Images } from '../../assets/assets';
import { getCommunities, beforeCommunity, addMemberCommunity } from "../../redux/communities/community.action";
import moment from "moment";
import { ENV } from "../../config/config";
import IconFlag from "../../assets/images/icon-flag.png"
import IconMembers from "../../assets/images/icon-members.png"
import IconPosts from "../../assets/images/icon-posts.png"
import './community.css'
import { connect } from 'react-redux';
import FullPageLoader from "../../components/full-page-loader/full-page-loader";
import { joinCommunity } from "../../utils/web3"
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { scroller } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import CommunityListing from './communityListing';

function Communities(props) {

	const [communities, setCommunities] = useState([])
	const [communityName, setCommunityName] = useState('')
	const [communityFounder, setCommunityFounder] = useState('')
	const [resetButton, setResetButton] = useState(false)
	const [loader, setLoader] = useState(true)
	const currentTime = moment();
	const { _id, address } = ENV.getUserKeys('_id address');
	const [pagination, setPagination] = useState(null);
	const [length, setLength] = useState(false);
	const [sortBy, setSortBy] = useState('')
	const [pg, setPg] = useState(1)
	const [prevPosition, setPrevPosition] = useState(null);

	// useLayoutEffect(() => {
	// 	if (prevPosition) {
	// 		window.scrollTo(prevPosition.x, prevPosition.y);
	// 	}
	// }, []);

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		props.getCommunities()
	}, [])

	useEffect(() => {
		if (props.community.getCommunitiesAuth) {
			setLoader(false)
			props.beforeCommunity()
			const { allDaos, paginationData } = props.community.communities;
			setPagination(paginationData)
			console.log("already comm", communities)
			console.log("new comm", allDaos)
			setCommunities([...communities, ...allDaos])
		}
	}, [props.community.getCommunitiesAuth])

	useEffect(() => {
		if (props.community.addCommunityMemberAuth) {
			setLoader(false)
		}
	}, [props.community.addCommunityMemberAuth])

	const checkKeyCode = (e) => {
		if (e.keyCode === 13) {
			searchCommunities("");
			e.preventDefault()
		}
	}

	const searchCommunities = (sortBy) => {
		setLoader(true)
		let payload = { name: communityName, founderAddress: communityFounder }
		if (sortBy) {
			payload["sortBy"] = sortBy
		}
		let qs = ENV.objectToQueryString(payload)
		setCommunities([])
		props.getCommunities(qs)
		props.beforeCommunity()
		setResetButton(true)
	}

	const reset = () => {
		setResetButton(false)
		setCommunityName('')
		setCommunityFounder('')
		setLoader(true)
		setCommunities([])
		props.getCommunities()
	}

	const joinCommunityMethod = async (commId, slug) => {
		setLoader(true)
		const tx = await joinCommunity(commId)
		if (tx) {
			props.addMemberCommunity({ slug, address })
		} else {
			setLoader(false)
		}
	}

	const onPageChange = async (page) => {
		// let prevPos = event.currentTarget.getBoundingClientRect()
		setLoader(true)
		page = page + 1;
		// setPrevPosition({ x: 0, y: page * 400 });
		// setPg(page)
		let payload = { name: communityName, founderAddress: communityFounder }
		if (sortBy) {
			payload["sortBy"] = sortBy
		}
		if (page) {
			payload["page"] = page
		}

		let qs = ENV.objectToQueryString(payload)
		props.beforeCommunity()
		props.getCommunities(qs)
	}

	return (
		<>
			{loader ?
				<FullPageLoader />
				: " "
			}
			{/* <Container fluid> */}
			<div className="explore-communities">
				<div className="create-community">
					<div className="page-header d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-0 mb-md-4">
						<h2 className="me-0 me-md-2 mb-3 mb-md-0 font-weight-bold">Explore Communities</h2>
						<div className="d-flex btns-holder flex-column flex-sm-row">
							<Dropdown>
								<Dropdown.Toggle id="dropdown-basic">
									<span>Sort By</span>
									<svg className="ms-2" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M21.5 7.75H3.5C3.09 7.75 2.75 7.41 2.75 7C2.75 6.59 3.09 6.25 3.5 6.25H21.5C21.91 6.25 22.25 6.59 22.25 7C22.25 7.41 21.91 7.75 21.5 7.75Z" />
										<path d="M18.5 12.75H6.5C6.09 12.75 5.75 12.41 5.75 12C5.75 11.59 6.09 11.25 6.5 11.25H18.5C18.91 11.25 19.25 11.59 19.25 12C19.25 12.41 18.91 12.75 18.5 12.75Z" />
										<path d="M14.5 17.75H10.5C10.09 17.75 9.75 17.41 9.75 17C9.75 16.59 10.09 16.25 10.5 16.25H14.5C14.91 16.25 15.25 16.59 15.25 17C15.25 17.41 14.91 17.75 14.5 17.75Z" />
									</svg>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{/* <Dropdown.Item href="#/action-1">Popularity</Dropdown.Item> */}
									<Dropdown.Item onClick={() => { setSortBy("member"); searchCommunities("member") }}>Members</Dropdown.Item>
									<Dropdown.Item onClick={() => { setSortBy("post"); searchCommunities("post") }}>Posts</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							<Link to="/create-community" className="freedom-btn freedom-solid-btn transition ms-md-3">Create A Community</Link>
						</div>
					</div>
				</div>
				<div className="community-search-form">

					<Row>
						<Col md={6} sm={6} className="mb-3">
							<Form className="search-form position-relative">
								{/* <span className="search-icon">
										<img src={Images.seacrhIcon} alt="" onClick={() => searchCommunities("")} />
									</span> */}
								<div className="input-holder">
									<input
										type="text"
										className="form-control"
										placeholder="Search by Community Name"
										onKeyDown={(e) => checkKeyCode(e)}
										value={communityName}
										onChange={(e) => setCommunityName(e.target.value)}
									/>
								</div>
								{/* <div className="input-holder">
										<input
											type="text"
											className="form-control"
											placeholder="Search By Adderss"
											onKeyDown={(e) => checkKeyCode(e)}
											value={communityFounder}
											onChange={(e) => setCommunityFounder(e.target.value)}
										/>
									</div> */}
							</Form>
						</Col>



						<Col md={6} sm={6} className="mb-3">
							<Form className="search-form position-relative">
								{/* <span className="search-icon">
										<img src={Images.seacrhIcon} alt="" onClick={() => searchCommunities("")} />
									</span> */}
								{/* <div className="input-holder">
										<input
											type="text"
											className="form-control"
											placeholder="Search"
											onKeyDown={(e) => checkKeyCode(e)}
											value={communityName}
											onChange={(e) => setCommunityName(e.target.value)}
										/>
									</div> */}
								<div className="input-holder">
									<input
										type="text"
										className="form-control"
										placeholder="Search By Address"
										onKeyDown={(e) => checkKeyCode(e)}
										value={communityFounder}
										onChange={(e) => setCommunityFounder(e.target.value)}
									/>
								</div>
							</Form>
						</Col>
						{/* <Col xxl={2} xl={3} lg={4} md={5} sm={5} className="mb-3 me-3">
								<button className="freedom-btn freedom-solid-btn transition " onClick={() => searchCommunities("")}>Search</button>
							</Col>
							<Col xxl={2} xl={3} lg={4} md={5} sm={5} className="mb-3">
								{resetButton && <button onClick={() => reset()} className="freedom-btn freedom-outline-btn transition ">Reset</button>}
							</Col> */}
					</Row>
					<div className='d-flex flex-wrap'>
						<div className='me-3 mb-2'>
							<button className="freedom-btn freedom-solid-btn transition " onClick={() => searchCommunities("")}>Search</button>
						</div>
						<div className='mb-2'>
							{resetButton && <button onClick={() => reset()} className="freedom-btn freedom-outline-btn transition ">Reset</button>}
						</div>
					</div>

				</div>
				<div id="commList" className="communities-list d-flex align-items-center flex-wrap">
					{/* <Row> */}
					<CommunityListing communities={communities} />
					{
						!communities?.length ?
							<div className='d-flex justify-content-center align-items-center not-found'><span className='text-center'>No Communities Found.</span></div> : ''
					}
				</div>
				{/* {
						pagination &&
							<Pagination
								className="m-3"
								defaultCurrent={1}
								pageSize // items per page
								current={pagination.page} // current active page
								total={pagination.pages} // total pages
								onChange={onPageChange}
								locale={localeInfo}
							/>
					} */}
				{pagination?.page + 1 > pagination?.pages ? "" : <button className='freedom-btn freedom-solid-btn transition ' onClick={(e) => { onPageChange(pagination?.page, e) }}>Load More</button>}
			</div>
			{/* </Container> */}
		</>
	)
}

const mapStateToProps = state => ({
	community: state.community
});

export default connect(mapStateToProps, { getCommunities, beforeCommunity, addMemberCommunity })(Communities);