import React from "react";
import '../../pages/communities/community.css'
import { Row, Col } from 'react-bootstrap'
import { Images } from '../../assets/assets'
import "./communityDetailTab.css";


const CommunityDetailTab = (props) => {
    return (
        <div className="main-body community-detail">
            <div className="community-detail-holder">
                <div className="form-group p-3">
                    <label>Community Cover Photo</label>
                    <div className="cover-image-holder">
                        <img src={props?.community?.coverPhoto || Images.Default_Cover} alt="" />
                    </div>
                </div>
                <Row>
                    <Col xl={5} lg={5} md="6">
                        <div className="form-group p-3">
                            <label>Community Picture</label>
                            <div className="image-holder">
                                <img className="img-fluid" src={props?.community?.logo || Images.Default_Logo} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col xl={7} lg={7} md="6">
                        <Row>
                            <Col md="12">
                                <div className="form-group p-3">
                                    <label>Name</label>
                                    <input className="form-control" type="text" readOnly value={props?.community?.name} />
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="form-group p-3">
                                    <label>Community Address</label>
                                    <input className="form-control" type="text" readOnly value={props?.community?.address} />
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="form-group p-3">
                                    <label>Social Links</label>
                                    {
                                        props?.community?.links?.map((e, index)=> {
                                            return <input key={index} className="form-control mb-4" type="text" readOnly value={e} />
                                        })
                                    }
                                    {
                                        !props?.community?.links?.length && <span className="text-danger d-block">No social links available yet.</span>
                                    }
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="form-group p-3">
                                    <label>Legal Status</label>
                                    {props?.community?.legalStatus !== " " &&  props?.community?.legalStatus !== "" ?
                                        <textarea  className="form-control" type="text" readOnly value={props?.community?.legalStatus} />
                                        :
                                        <span className="text-danger d-block">No Legal Status yet.</span>
                                    }
                                    
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="form-group p-3">
                                    <label>Legal Document</label>
                                    {props?.community?.legalDocs !== " " &&  props?.community?.legalDocs !== ""?
                                        <input className="form-control" type="text" readOnly value={props?.community?.legalDocs} />
                                    :
                                        <span className="text-danger d-block">No Legal document available yet.</span>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="12">
                        <div className="form-group p-3">
                            <label>Community Purpose</label>
                            {props?.community?.purpose !== " " && props?.community?.purpose !== ""   ?
                                <textarea className="form-control" type="text" readOnly value={props?.community?.purpose} ></textarea>
                            :
                                <span className="text-danger d-block">Purpose is not available yet.</span>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default CommunityDetailTab