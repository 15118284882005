import {CLEAR_PROPOSALS, ADD_PROPOSAL, BEFORE_PROPOSAL, GET_PROPOSAL, GET_PROPOSALS, DELETE_PROPOSAL, EDIT_PROPOSAL, CHECK_VALIDATION, BEFORE_CHECK_VALIDATION} from "../../redux/types";

const initialState = {
    proposals: [], // all the proposals
    proposal: {}, // proposal data
    addProposalAuth: false,
    getProposalsAuth: false,
    getProposalAuth: false,
    deleteProposalAuth: false,
    editProposalAuth: false,
    checkValidationAuth: false,
    isProposalValid: false, 
    validationMsg: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_PROPOSAL:
            return {
                ...state,
                addProposalAuth: true,
                proposal: action.payload,
            }
        case EDIT_PROPOSAL: 
            return {
                ...state,
                editProposalAuth: true,
                proposal: action.payload
            }
        case GET_PROPOSAL:
            return {
                ...state,
                getProposalAuth: true,
                proposal: action.payload
            }
        case GET_PROPOSALS:
            console.log('action.payload = ', action.payload)
            return {
                ...state,
                getProposalsAuth: true,
                proposals: [...state.proposals, ...action.payload.fetchedProposals],
                paginationData: action.payload.paginationData
                // {fetchedProposals: [ ...state?.proposals?.fetchedProposals, ...action.payload.fetchedProposals], paginationData: action.payload.paginationData}
            }
        case DELETE_PROPOSAL:
            return {
                ...state,
                deleteProposalAuth: true,
                proposal: action.payload
            }
        case BEFORE_PROPOSAL:
            return {
                ...state,
                proposal: {},
                // proposals: [],
                addProposalAuth: false,
                getProposalAuth: false,
                getProposalsAuth: false,
                deleteProposalAuth: false,
                editProposalAuth: false
            }
        case CLEAR_PROPOSALS:
            return { 
                ...state,
                proposal: {},
                proposals: [],
                addProposalAuth: false,
                getProposalAuth: false,
                getProposalsAuth: false,
                deleteProposalAuth: false,
                editProposalAuth: false
            }
        case CHECK_VALIDATION:
            return {
                ...state,
                checkValidationAuth: true,
                isProposalValid: action.payload.isValid,
                validationMsg: action.payload.message
            }
        case BEFORE_CHECK_VALIDATION:
            return {
                ...state,
                checkValidationAuth: false,
                isProposalValid: false,
            }
        default:
            return {
                ...state
            }
    }
}