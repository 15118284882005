import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Form, Accordion, Container } from 'react-bootstrap'
import { ENV } from "../../config/config";
import { addCommunity, beforeCommunity, beforeCheckValidation, checkDaoValidation } from "../../redux/communities/community.action";
import { useNavigate } from "react-router-dom";
import CreatedSuccessfully from "../../assets/images/Frame.png"
import { connect } from 'react-redux';
import { createCommunity } from "../../utils/web3";
import { Images } from '../../assets/assets';
import './community.css'
import AddLinks from '../../components/addLinks/addLinks';
import Permission from '../../components/permissions/permissions';
import ManageGroups from '../../components/manageGroups/manageGroups';
import ManageMembers from '../../components/manageMembers/manageMembers';
import AlertModal from '../../components/alertModal/alertModal';
import DefaultImg from "../../assets/images/placeholder-dark.jpg"
import { uploadToCloudinary } from '../../utils/upload'
import WalletConnect from '../../components/walletConnect/walletConnect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faPencil } from '@fortawesome/free-solid-svg-icons';

function CreateCommunity(props) {

	const navigate = useNavigate()

	const [showAlert, setShowAlert] = useState()
	const { _id, address } = ENV.getUserKeys('_id address');
	const [communityTermsAndConditions, setCommunityTermsAndConditions] = useState(false)
	const [membersTermsAndConditions, setMembersTermsAndConditions] = useState(false)
	const [communityCreated, setCommunityCreated] = useState(false)
	const [communitySlug, setCommunitySlug] = useState()
	const [linksArray, setLinksArray] = useState([0])
	const [linksValidated, setLinksValidated] = useState(true)
	const [membersValidated, setMembersValidated] = useState(true)

	const [loader, setLoader] = useState(false)
	const [logoImg, setLogoImg] = useState();
	const [coverImg, setCoverImg] = useState();

	const [isValidLogo, setIsValidLogo] = useState(true)
	const [isValidCover, setIsValidCover] = useState(true)

	const [data, setData] = useState({
		name: '',
		creatorId: _id,
		address: '',
		purpose: '',
		logo: '',
		coverPhoto: '',
		legalStatus: '',
		legalDocs: '',
		groupData: [
			{
				groupName: "",
				members: [],
				permisionproposal: [],
				voting: []
			}
		]
	});
	const [errorMsg, setErrorMessage] = useState({ name: '' });
	const [groups, setGroups] = useState(["council"])
	const [memberOfGroup, setMembersOfGroup] = useState([])
	const [votingPermission, setVotingPermission] = useState([])
	const [creationPermission, setCreationPermission] = useState([])

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
	
	useEffect(() => {
		if (data.name) {
			let autoFilledAddress = data.name.trim().toLowerCase().split(" ").join("-")
			setData({ ...data, address: data.name ? `${autoFilledAddress}-${ENV.current_network}` : "" })
		} else {
			setData({ ...data, address: "" })
		}
	}, [data.name])

	useEffect(() => {
		if (props.community.addCommunityAuth) {
			setCommunitySlug(props.community.communityData.address)
			setLoader(false)
			setCommunityCreated(true)
		}
	}, [props.community.addCommunityAuth])

	useEffect(() => {
		if (props.community.checkValidationAuth) {
			console.log(props.community)
			if (props.community.isCommValid) {
				fnCreateComm()
			} else {
				setLoader(false)
				// set Error message
				const error = errorMsg
				error["name"] = props.community.validationMsg
				setErrorMessage({ ...error })
			}
		}
	}, [props.community.checkValidationAuth])

	useEffect(() => {
		setData({ ...data, links: linksArray })
	}, [linksArray])

	// useEffect(()=> {
	// 	if(data?.legalDocs !== ""){
	// 		const res = ENV.isValidURL(data?.legalDocs)
	// 		if(!res){
	// 			setErrorMessage({
	// 				...errorMsg,
	// 				legalDocs: "Invalid Url.",
	// 			})
	// 		}else {
	// 			setErrorMessage({
	// 				...errorMsg,
	// 				legalDocs: "",
	// 			})
	// 		}
	// 	}
	// }, [data?.legalDocs])

	const changeLogoImg = (e) => {
		const file = e.target.files[0]
		setData({ ...data, logo: file })
		if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
			setIsValidLogo(true)
			return new Promise(resolve => {
				let baseURL = "";
				// Make new FileReader
				let reader = new FileReader();

				// Convert the file to base64 text
				reader.readAsDataURL(file);

				// on reader load somthing...
				reader.onload = () => {
					// Make a fileInfo Object
					baseURL = reader.result;
					setData({ ...data, logo: baseURL })
					const imgUrl = URL.createObjectURL(e.target.files[0])
					setLogoImg(imgUrl)
					resolve(baseURL);
				};
			});
		} else {
			setIsValidLogo(false)
		}
	}

	const changeCoverImg = (e) => {
		const file = e.target.files[0]
		setData({ ...data, coverPhoto: file })
		if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
			setIsValidCover(true)
			return new Promise(resolve => {
				let baseURL = "";
				// Make new FileReader
				let reader = new FileReader();

				// Convert the file to base64 text
				reader.readAsDataURL(file);

				// on reader load somthing...
				reader.onload = () => {
					// Make a fileInfo Object
					baseURL = reader.result;
					setData({ ...data, coverPhoto: baseURL })
					const imgUrl = URL.createObjectURL(e.target.files[0])
					setCoverImg(imgUrl)
					resolve(baseURL);
				};
			});
		} else {
			setIsValidCover(false)
		}
	}

	const checkValidation = async () => {
		let err = [];
		setErrorMessage({})
		let isValid = true
		var regName = /^[a-zA-Z][a-zA-Z ]*$/;

		if (!isValidLogo || !isValidCover) {
			isValid = false
		}

		// data.name = data.name.trim()
		if (!data.name) {
			err["name"] = "Community Name is Required"
			isValid = false
		} else if (data.name.length < 5) {
			err["name"] = "Community Name must be 5 characters long."
			console.log("err", err)
			isValid = false
		} else if (!regName.test(data.name)) {
			err["name"] = "Invalid name."
			isValid = false
		} else {
			err["name"] = ""
		}
		// console.log('data?.legalStatus?.length = ', data?.legalStatus?.length)
		if (data.legalStatus.length <= 250) {
			err["legalStatus"] = ""
		}else {
			isValid = false
			err["legalStatus"] = "Legal status must be less than 250 characters."
		}
		if (data?.legalDocs && data?.legalDocs !== " ") {
			const res = await ENV.isValidURL(data?.legalDocs)
			if (!res) {
				isValid = false
				err["legalDocs"] = "Invalid Url."
			} else {
				err["legalDocs"] = ""
			}
		}

		// groups should be unique
		if (!ENV.isUniqueArray(groups)) {
			isValid = false
		}

		// members address should be unique in every group and must be ethereum address
		for (let index = 0; index < groups.length; index++) {
			let members = memberOfGroup[groups[index]];
			if (index == 0) {
				members = [members]
			} else {
				if (members === "" || members === undefined) {
					members = []
				} else {
					members = members?.split(",")
				}
			}
			if (!ENV.isUniqueArray(members)) {
				isValid = false
			}
			for (let index = 0; index < members.length; index++) {
				const e = members[index];
				if (!ENV.isEthereumAddress(e)) {
					isValid = false
				}
			}
		}
		setErrorMessage({...err})
		return isValid
	}

	const addCommunityData = async (e) => {
		e.preventDefault()
		if (!props.userData.loginAuth) {
			setShowAlert(true)
		} else {

			let valid = await checkValidation()
			if (!valid) {
				return
			} else {
				setLoader(true)

				// call this to check the validation of proposal 
				props.beforeCheckValidation()
				props.checkDaoValidation(data)
				// fnCreateComm()
			}
		}
	}

	const fnCreateComm = async () => {
		let dataArray = []
		// const address = props.userData?.userData?.address
		console.log("groups = ", groups)
		console.log("creationPermission = ", creationPermission)
		console.log("votingPermission = ", votingPermission)

		for (let i = 0; i < groups.length; i++) {
			let obj = {};
			obj.groupName = groups[i];
			obj.proposalCreationRights = creationPermission[groups[i]] ? creationPermission[groups[i]] : [];
			obj.votingPermissionRights = votingPermission[groups[i]] ? votingPermission[groups[i]] : [];
			obj.members = memberOfGroup[groups[i]]?.length ? memberOfGroup[groups[i]].split(",") : []
			dataArray.push(obj);
		}
		data.groupData = dataArray;
		setLoader(true)
		const obj = await createCommunity(data, address)
		if (obj.id) {
			if (data.logo) {
				let res = await uploadToCloudinary(data?.logo)
				data.logo = res.secure_url
			}
			props.addCommunity({ ...data, "_daoId": obj.id, groupIds: obj.groupIds })
		} else {
			setLoader(false)
		}
	}

	return (
		<Container fluid>
			<div className="create-community">
				<div className="page-header d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-4">
					<h2 className="me-0  font-weight-bold">Create A Community</h2>

				</div>
				{!communityCreated ?
					<Form onSubmit={(e) => addCommunityData(e)} className="create-community-form">
						<div>
							<div className='freedom-card mb-2 mb-sm-2 p-0 cover-editor'>
								<div className='cover-photo-update'>

									<div className='cover'>
									<div className='d-flex justify-content-end cover-file-format'>
							<div>{!isValidCover? < label className={`text-danger pl-1 mb-3`}>Cover must be of type jpeg/jpg/png.</label> : <label className='mb-3'>&nbsp;</label>}</div>
							</div>
										<img className='cover-photo' src={coverImg || Images.Default_Cover} alt='' /> </div>
									<div className='profile-holder'>
										
										<img src={logoImg || Images.Default_Logo} alt='' />
										{/* {!isValidLogo ? < label className={`text-danger pl-1`}>Logo must be of type jpeg/jpg/png.</label> : ''} */}
										{/* <div className='edit-cover'><FontAwesomeIcon className='text-white ' icon={faPencil} /></div> */}
										{/* <label for="coverUploader">
											<div className='edit-cover'><FontAwesomeIcon className='text-white ' icon={faPencil} /></div>
										</label> */}
										<input style={{ display: "none" }} id="coverUploader" type="file" varient="info" accept=".png,.jpeg,.jpg" onChange={(e) => changeCoverImg(e)} />
									</div>
									<label for="coverUploader">
										
										<div className='edit-cover'><FontAwesomeIcon className='text-white ' icon={faPencil} /></div>
									</label>
									<label for="logoUploader">
										<div className='camera-circle-dp'>
											{/* <FontAwesomeIcon className='text-white' icon={faCamera} /> */}
											<FontAwesomeIcon className='text-white' icon={faPencil} />
										</div>
									</label>
									<input style={{ display: "none" }} id="logoUploader" type="file" varient="info" accept=".png,.jpeg,.jpg" onChange={(e) => changeLogoImg(e)} />
								</div>
								{/* {!isValidCover? < label className={`text-danger pl-1`}>Cover must be of type jpeg/jpg/png.</label> : ''} */}
							</div>
							<div className='d-flex justify-content-center align-items-center logo-file-format flex-wrap'>
								<div>
									<div>{!isValidLogo ? < label className={`text-danger pl-1`}>Logo must be of type jpeg/jpg/png.</label> : ''}</div>
								</div>
							</div>
							{/* <div className='d-flex justify-content-end cover-file-format'>
							<div>{!isValidCover? < label className={`text-danger pl-1`}>Cover must be of type jpeg/jpg/png.</label> : ''}</div>
							</div> */}
							<strong className='ps-1 optional d-block mb-3 pt-2'>Both fields are optional. If you skip, default image will be used instead. An image and a logo together form a flag of your DAO!</strong>

							<div className="freedom-card">
								<h3 className="text-uppercase mb-4">Community Information</h3>
								<Row>
									<Col md={5}>
										<div className="basic-info-col">
											{/* <h3 className="text-uppercase mb-4">Community Information</h3> */}
											<Form.Group className="mb-3">
												<div className="d-flex align-items-center">
													<input
														className="form-control transition"
														placeholder="Community Name"
														onChange={(e) => setData({ ...data, name: e.target.value })}
														value={data.name}
													/>
													<h2 className='text-danger ms-2 mb-0'>*</h2>
												</div>
												{errorMsg && errorMsg.name ? < label className={`text-danger pl-1`}>{errorMsg.name}</label> : ''}
											</Form.Group>
											<Form.Group className="mb-3 d-flex align-items-center">
												<input
													className="form-control transition"
													placeholder="Community Address"
													value={data.address}
												/>
												<h2 className='text-danger ms-2'>&nbsp;</h2>
											</Form.Group>
											<Form.Group className="mb-3 d-flex align-items-center">
												<textarea rows="5" cols="5" className="form-control transition" placeholder="Purpose (Optional)"
													onChange={(e) => setData({ ...data, purpose: e.target.value })} value={data.purpose}
												></textarea>
												<h2 className='text-danger ms-2'>&nbsp;</h2>
											</Form.Group>
											{/* <Form.Group className="mb-3"> */}
											{/* <div className='d-flex align-items-center'>
													<div className='mb-3 uploaded-img-div'> <img src={logoImg || DefaultImg} className="img-fluid" /> </div>
													<h2 className='text-danger ms-2'>&nbsp;</h2>
												</div>

												<div className='d-flex align-items-center'>
													<div className='input-file-btn-holder m-0 ms-2'>
														<input type="file" className="imgup custom-file-input" id="imgupload" onChange={(e) => { changeLogoImg(e) }} />
														<span className='input-file-btn'>Choose File</span>
													</div>

													<span className='ps-1 optional'> (optional)</span>
												</div> */}
											{/* <button>
												<label htmlFor='imgupload'><a id="OpenImgUpload">Replace Image</a></label>
											</button> */}

											{/* </Form.Group> */}
										</div>
									</Col>
									<Col md={7}>
										<Accordion defaultActiveKey="0" className="mb-3">
											<Accordion.Item eventKey="0">
												<Accordion.Header className="text-uppercase">KYC <span className='ps-1 optional'> (optional)</span></Accordion.Header>
												<Accordion.Body>
													<Form.Group className="mb-3">
														<label>Please explain your Community`s Legal Status and Jurisdiction (if known).</label>
														<textarea
															className="form-control transition"
															placeholder="Legal Status"
															onChange={(e) => setData({ ...data, legalStatus: e.target.value })}
															value={data.legalStatus}
															onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
														/>
														{errorMsg && errorMsg.legalStatus ? < label className={`text-danger pl-1`}>{errorMsg.legalStatus}</label> : ''}
													</Form.Group>
													<Form.Group className="mb-3">
														<label>Please attach a link to the relevant document as proof of legal status.</label>
														<input
															className="form-control transition"
															placeholder="https://Legal_Document"
															onChange={(e) => setData({ ...data, legalDocs: e.target.value })}
															value={data.legalDocs}
															onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
														/>
														{errorMsg && errorMsg.legalDocs ? < label className={`text-danger pl-1`}>{errorMsg.legalDocs}</label> : ''}
													</Form.Group>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
										<Accordion className="mb-3">
											<Accordion.Item eventKey="0">
												<Accordion.Header className="text-uppercase">Links and Socials <span className='ps-1 optional'> (optional)</span></Accordion.Header>
												<Accordion.Body>
													<label>Looking to grow the Community members? Add links to allow people to learn more about your Community</label>
													<Form.Group className="mb-3 align-items-center">
														<AddLinks setLinksValidated={setLinksValidated} setLinksArray={setLinksArray} linksArray={linksArray} />
														{/* <button className="add-btn transition  d-flex justify-content-center align-items-center ms-3">Add</button> */}
													</Form.Group>

												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
										<Accordion className="mb-3">
											<Accordion.Item eventKey="0">
												<Accordion.Header className="text-uppercase">Add Groups </Accordion.Header>
												<Accordion.Body>
													<Form.Group className="mb-3 align-items-center">
														<strong className='text-white d-block mb-1'>Default groups</strong>
														{/* <span className='d-block mb-2 optional ps-1'>You can't delete them</span> */}
														<ManageGroups setGroups={setGroups} />
													</Form.Group>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>

										<Accordion className="mb-3">
											<Accordion.Item eventKey="0">
												<Accordion.Header className="text-uppercase">Add Members</Accordion.Header>
												<Accordion.Body>
													<label>Add members to your Community</label>
													<Form.Group className="mb-3 align-items-center">
														{/* add members */}
														<ManageMembers groups={groups} setMembersOfGroup={setMembersOfGroup} />
													</Form.Group>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
										<Accordion className="mb-3">
											<Accordion.Item eventKey="0">
												<Accordion.Header className="text-uppercase">Proposal Creation</Accordion.Header>
												<Accordion.Body>
													<Form.Group className="mb-3 align-items-center">
														<Permission name='create' description="Choose what creation rights you give Community groups." groups={groups} setPermission={setCreationPermission} />
													</Form.Group>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
										<Accordion className="mb-3">
											<Accordion.Item eventKey="0">
												<Accordion.Header className="text-uppercase">Voting permissions</Accordion.Header>
												<Accordion.Body>
													<Form.Group className="mb-3 align-items-center">
														<Permission name='vote' description="Choose what voting rights you give Community groups." groups={groups} setPermission={setVotingPermission} />
													</Form.Group>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</Col>
								</Row>
								<Row>
									<Col sm={12}>
										<div className="checkboxes pt-0 pt-md-4">
											<div className="checkbox mb-3">
												<label class="right-label-checkbox"> I agree to follow the <a href="/terms-condition" target="_blank">Terms &amp; Conditions</a> of use.
													<input type="checkbox" defaultValue={communityTermsAndConditions} onChange={(e) => setCommunityTermsAndConditions(e.target.checked)} />
													<span class="checkmark"></span>
												</label>
											</div>
											<div className="checkbox mb-3">

												<label class="right-label-checkbox">  I understand that all Community Members must follow the <a href="/terms-condition" target="_blank">Terms &amp; Conditions</a>.
													<input type="checkbox" defaultValue={membersTermsAndConditions} onChange={(e) => setMembersTermsAndConditions(e.target.checked)} />
													<span class="checkmark"></span>
												</label>
											</div>
										</div>
									</Col>
									<Col sm={12} className="d-flex justify-content-end create-community-buttons flex-wrap">
										<button type="submit" disabled={!communityTermsAndConditions || !membersTermsAndConditions || loader} className="mb-2 mb-sm-0 me-2 me-sm-0 freedom-btn freedom-solid-btn transition  d-flex justify-content-center align-items-center" >{loader && <div className="loader me-2"></div>}Create</button>
										<Link to="/communities" className="freedom-btn freedom-outline-btn transition  d-flex justify-content-center me-2 me-sm-0 mb-2 mb-sm-0 ms-0 ms-sm-3">Cancel</Link>
									</Col>
								</Row>
							</div>
						</div>
					</Form>
					:
					<div className="created-successfully">
						<div className="frame">
							<img src={CreatedSuccessfully} alt="" />
						</div>
						<p>Your community has been created! </p>
						{/*Start by inviting members.  */}
						<button className="d-inline-block align-top freedom-btn freedom-outline-btn transition" onClick={() => navigate(`/community/${communitySlug}`)}><span>View Community</span></button>
					</div>
				}
				<WalletConnect show={showAlert} setShow={setShowAlert} />
				{/* <AlertModal show={showAlert} setShow={setShowAlert} msg="Please Connect with Wallet." /> */}
			</div >
		</Container>
	)
}

const mapStateToProps = state => ({
	community: state.community,
	userData: state.loginKey,
});

export default connect(mapStateToProps, { addCommunity, beforeCommunity, beforeCheckValidation, checkDaoValidation })(CreateCommunity);