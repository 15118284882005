import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/header/header";
import Footer from "../components/footer_1/Footer";
import Sidebar from "../components/sidebar/sidebar";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { ENV } from "../config/config";
import { connect } from 'react-redux';

const Layout1 = (props) => {

    const { children } = props;
    const location = useLocation();
    const { _id } = ENV.getUserKeys();
    const pathName = location.pathname;

    return (
        <>
            {
                props?.walletError && 
                    <div className="warning-section">
                        <div className="warning-text">
                            {props?.walletError}
                        </div>
                    </div>
            }
            <div className="main-wrapper">
                <Header />
                <Container fluid className="main-page">
                    <Row>
                        <Col xl={2} lg={3}>
                            <Sidebar />
                        </Col>
                        <Col xl={10} lg={9}>
                            {children}
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        walletError: state.loginKey.walletError
    }
}
export default connect(mapStateToProps, {})(Layout1);