import { ADD_VOTE, BEFORE_VOTE, EDIT_VOTE, GET_ERRORS } from "../../redux/types";
import { ENV } from "../../config/config";
import { emptyError } from '../../redux/shared/error/error.action';

export const beforeVote = () => {
    return {
        type: BEFORE_VOTE
    }
}

export const addVote = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}vote/create/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: ADD_VOTE,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const editVote = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}vote/edit/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: payload
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: EDIT_VOTE,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};


