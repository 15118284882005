import { MEMBER_COMMUNITIES,GET_DAO_MEMBERS, ADD_COMMUNITY, BEFORE_COMMUNITY, GET_USER_COMMUNITIES, GET_COMMUNITIES, GET_COMMUNITY, ADD_MEMBERS, ADD_MEMBERS_COMMUNITY, CHECK_MEMBER, BEFORE_CHECK_VALIDATION_COMM, CHECK_VALIDATION_COMM } from "../../redux/types";

const initialState = {
    addCommunityAuth: false,
    getUserCommunityAuth: false,
    getCommunityAuth: false,
    getCommunitiesAuth: false,
    addMemberAuth: false,
    getMemberCommunityAuth: false,
    addCommunityMemberAuth: false,
    checkMemberAuth: false,

    checkValidationAuth: false,
    isProposalValid: false, 
    validationMsg: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_COMMUNITY:
            return {
                ...state,
                addCommunityAuth: true,
                communityData: action.payload,
            }
        case ADD_MEMBERS_COMMUNITY:
            return {
                ...state,
                addCommunityMemberAuth: true,
            }
        case GET_USER_COMMUNITIES:
            return {
                ...state,
                getUserCommunityAuth: true,
                userCommunities: action.payload
            }
        case MEMBER_COMMUNITIES:
            return {
                ...state,
                getMemberCommunityAuth: true,
                memberCommunities: action.payload
            }
        case GET_COMMUNITIES:
            return {
                ...state,
                getCommunitiesAuth: true,
                communities: action.payload
            }
        case GET_COMMUNITY:
            return {
                ...state,
                getCommunityAuth: true,
                community: action.payload
            }
        case CHECK_MEMBER:
            return {
                ...state,
                checkMemberAuth: true,
                memberData: action.payload
            }
        case ADD_MEMBERS:
            return {
                ...state,
                addMemberAuth: true,
            }
        case BEFORE_COMMUNITY:
            return {
                ...state,
                addCommunityAuth: false,
                getUserCommunityAuth: false,
                getCommunitiesAuth: false,
                getCommunityAuth: false,
                addMemberAuth: false,
                getMemberCommunityAuth: false,
                addCommunityMemberAuth: false,
                checkMemberAuth: false
            }
        case CHECK_VALIDATION_COMM:
            return {
                ...state,
                checkValidationAuth: true,
                isCommValid: action.payload.isValid,
                validationMsg: action.payload.message
            }
        case BEFORE_CHECK_VALIDATION_COMM:
            return {
                ...state,
                checkValidationAuth: false,
                isCommValid: false,
            }
        default:
            return {
                ...state
            }
    }
}