import React, { useState, useEffect } from 'react'
import proposalTypes from '../../utils/data/proposalTypes';
import moment from 'moment';
import DefaultImg from "../../assets/images/default-image.png"
import Countdown from 'react-countdown';
import { Images } from '../../assets/assets';
import { useNavigate } from 'react-router-dom'

const Proposal = ({ data }) => { // title, description, remainingTime, logo

    const naviagte = useNavigate()
    const [fieldsArr, setFieldsArr] = useState({})

    useEffect(() => {
        fieldsArrFn()
    }, [data?.type])

    const Completionist = () => <span className="text-danger">Time Ended</span>;

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            if (days < 10) {
                days = `0${days}`
            }
            if (hours < 10) {
                hours = `0${hours}`
            }
            if (minutes < 10) {
                minutes = `0${minutes}`
            }
            if (seconds < 10) {
                seconds = `0${seconds}`
            }
            // Render a countdown
            return <span>{days}:{hours}:{minutes}:{seconds}</span>;
        }
    };

    const remainingTime = () => {
        let min = data?.timePeriod //in mintues
        let timeRemaining = moment(data?.createdAt).fromNow()
        return timeRemaining
    }

    const fieldsArrFn = () => {
        proposalTypes.map((type, i) => {
            if (type?.value == data?.type) {
                console.log("type ===> ", type.value == data?.type)
                console.log("type == ", type)
                setFieldsArr({ ...type })

            }
        })
    }

    let currentDate = moment().format()
    return (
        <div className="contenty">
            <div className="d-flex justify-content-between align-items-baseline flex-wrap top-head">
                <div className='proposal-title'><h2 className='mb-2 mb-sm-2'>{data?.name}</h2></div>
                {
                    data?.status === 1 ?
                        <span className="text-white timer mb-0 stop-watch">
                            <Countdown
                                date={moment(data?.endTime)}
                                renderer={renderer}
                            />
                        </span> :
                        data?.status === 2 ?
                            <div className="d-flex align-items-end  flex-column approved-stamp">
                                <span className="text-success timer mb-3 stop-watch"><img src={Images.Approved} alt='' /> </span>
                                {
                                    // when there is a bounty proposal, then we will check the create claim condition
                                    data?.type == 2 && currentDate < moment(data?.createdAt).add(data?.daystoComplete, 'day').format() ?
                                        <div className="d-flex align-items-center flex-wrap mt-4">
                                            {
                                                data?.alreadyClaimed < data?.availableClaims ?
                                                    <>
                                                        <button disabled={!currentDate < moment(data?.createdAt).add(data?.daystoComplete, 'day').format() ? true : false} className="freedom-outline-btn transition mb-3 me-3 mb-md-0 create-claim-btn" onClick={() => naviagte(`/create-proposal/${data?.daoAddress}/${data?._daoId}?proposalType=${8}&&bountyId=${data?._proposalId}`)}>Create Claim </button>
                                                        <span className="text-white timer mb-0 stop-watch">
                                                            <Countdown
                                                                date={moment(data?.createdAt).add(data?.daystoComplete, 'day')}
                                                                renderer={renderer}
                                                            />
                                                        </span>
                                                    </> : <span className="text-white">No Claim Available</span>
                                            }
                                        </div>
                                        : <span className="text-danger timer mb-0 stop-watch">{data?.type == 2 ? "Claim Time Up" : ''}</span>
                                }
                            </div>
                            :
                            data?.status === 3 ? <div className="d-flex align-items-end  flex-column approved-stamp"><span className="text-success timer mb-3 stop-watch"> <img src={Images.Failed} alt='' /></span> </div> : <span className='text-danger'>Time Ended</span>
                }
                {/* <>{data?.status === 3 ? <span className="text-danger">Failed</span> : data?.status === 2 ? <span className="text-success">Approved</span> : '' } */}
                {data.proposedEndTime && data?.status !== 1 ? <span className='stamp-date'>{moment(data?.proposedEndTime).format('MMMM Do YYYY')}</span> : ''}
                {/* {data?.timePeriod && <div><span className="m-0 time-left d-block" >{remainingTime()}</span></div>} */}
                {data?.logo && <img className='' src={data.logo} />}
            </div>
            <div className='row'>
                <div className='col-xl-5 col-lg-3'>
                    <div className="body pool-2">
                        <strong>Community Name</strong>
                        <p>{data.daoName}</p>
                    </div>
                </div>
                <div className='col-xl-7 col-lg-9'>
                    <div className="body pool-2">
                        <strong>Proposer</strong>
                        <p>{data.proposer}</p>
                    </div>
                </div>
            </div>
           
            {/* render the proposed values */}
            <div className="row pt-4 pt-xl-3">
                {
                    fieldsArr?.fields?.map((e, j) => {
                        console.log("field", e)
                        if (e?.val === "links") {
                            return (
                                <div className="col-md-4">
                                    {/* <div className={"proposedCover" ? "col-md-9" :"proposedLogo" ? "col-md-3" : ""}> */}
                                    <div className="body mb-3" key={j}>
                                        <strong>{e?.name}</strong>
                                        <ul className='p-0 m-0'>
                                            {
                                                data[e?.val]?.map((e, k) => {
                                                    return <li><p>{e}</p></li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            )
                        } else if (e?.val === "proposedCover" || e?.val === "proposedLogo") {
                            return (

                                // <div className="col-md-4">  
                                <div className={e?.val === "proposedCover" ? "col-md-8" : e?.val === "proposedLogo" ? "col-md-4" : ""} >
                                    <div className="body mb-3" key={j}>
                                        <strong>{e?.name}</strong>
                                        <div className="pt-2">
                                            <div className={`mb-3 uploaded-img-div me-2 ${e?.val === "proposedCover" ? "w-100" : e?.val === "proposedLogo" ? "" : ""}`}  > <img src={data[e?.val] || DefaultImg} /> </div>
                                        </div>
                                    </div>
                                    {/* //  </div>  */}
                                </div>
                            )
                        } else if (e?.val === "proposedLegalStatus" || e?.val === "proposedLegalDocs") {
                            return (

                                // <div className="col-md-4">  
                                <div className={e?.val === "proposedLegalStatus" ? "col-md-5" : e?.val === "proposedLegalDocs" ? "col-md-7" : ""} >
                                    <div className="body mb-3" key={j}>
                                        <strong>{e?.name}</strong>
                                        <div className="pt-2">
                                            <p>{data[e?.val]}</p>
                                        </div>
                                    </div>
                                    {/* //  </div>  */}
                                </div>
                            )
                        } else {
                            return (
                                <div className={e?.name?"col-md-5":"col-md-7"}>
                                    <div className="body mb-3" key={j}>
                                        {
                                            e?.val !== "fnCallAbi" ?
                                                <>
                                                    <strong>{e?.name}</strong>
                                                    <p>{data[e?.val]}</p>
                                                </> : ''
                                        }
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
            <div className="body pool-2">
                <strong>Description</strong>
                <p>{data.description}</p>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare consequat nulla nec fermentum. Morbi interdum enim a pretium accumsan. Integer vel ex hendrerit, sagittis ipsum eget, fermentum ipsum. Donec ligula sapien, egestas eu eros a, rutrum vehicula nisi. Nam efficitur bibendum ante, non pulvinar velit. Pellentesque dictum placerat congue. Aliquam condimentum gravida dolor, quis varius mi tristique et. Vestibulum bibendum erat a sapien sagittis egestas.</p> */}
            </div>
            {
                data.reason ?
                    <div className="body pool-2">
                        <strong>Reason</strong>
                        <p className='text-danger'>{data.reason}</p>
                    </div> : ""
            }
        </div>
    )
}

export default Proposal;