
import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import Lottie from "lottie-react";
import hologram from "../../../utils/hologram.json";
import questionlottie from '../../../utils/question-lottie.json'
import './faqs.css'

const Faqs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const faqsAccordion = [
        { id: 0, title: "Can I use MutoDAO if I am not familiar with blockchain technology? ", description: "Yes, definitely! MutoDAO is developed with the needs and feedback of real-world users in mind. As a result, it has evolved into a user-friendly product that anyone with an internet connection can easily access." },
        { id: 1, title: "Can I use MutoDAO on my mobile phone? ", description: "Yes, it is possible. The Ethereum Protocol, which is the blockchain that the MutoDAO is built on, is designed to be accessible to the next billion users of crypto. So, whether you have an Android or iOS device, you can run MutoDAO on the go." },
        { id: 2, title: "How does MutoDAO differ from DAOs on other blockchain platforms, such as Binance?", description: "MutoDAO operates on Ethereum, a Layer 1 blockchain known for its unlimited scalability, resulting in low-cost and rapid transactions, unlike other DAOs on other blockchains." },
        { id: 3, title: "How long does a proposal last?", description: "It depends. The duration of a proposal varies, with less critical proposals typically taking 4 days for voting and more critical proposals taking 7 days. A proposal's start and end must occur within the same epoch" },
        { id: 4, title: "How can transparency in voting be ensured, and how can the accuracy and fairness of the results be verified?", description: "The voting process is open, and any community member can track it. All voting transactions are recorded on the blockchain and can be viewed on etherscan, including transaction hashes and other details. The outcome of the voting is determined by the proposal option that receives the most votes. This is done through a democratic process." },
    ]
    return (
        <section className='faqs'>
            <Container>
                <Row>
                    <Col lg={7} md={12}>
                        <div className='faq-content pt-3'>
                            <h3>FAQs</h3>
                            {faqsAccordion.map((e, index, key) =>
                                <Accordion defaultActiveKey={index = 0} flush>
                                    <Accordion.Item eventKey={e.id}>
                                        <Accordion.Header>{e.title}</Accordion.Header>
                                        <Accordion.Body>{e.description}</Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )}
                        </div>
                    </Col>
                    <Col lg={5} md={12}>
                        <div className='faqs-gif text-center d-flex align-items-center justify-content-center'>
                            <Lottie animationData={hologram} loop={true} />
                            <div className='question-lottie'>
                                <Lottie animationData={questionlottie} loop={true} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Faqs