import { faTruckFieldUn } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react'
import { ENV } from "../../config/config";
import AddLinks from '../../components/addLinks/addLinks';
import { Form } from 'react-bootstrap'
import DynamicInput from "react-dynamic-input"
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddMembers = ({addMember, groupName}) => {

		const [membersAddr, setMemberAddr] = useState([]);
		const [memberCount, setMemberCount] = useState(1)
	    const [isValidMemberList, setIsValidMemberList] = useState(true)

		useEffect(()=> {
			pushMember()
		}, [])

		useEffect(()=>{
			let arr = []
			for (let index = 0; index < membersAddr?.length; index++) {
				const e = membersAddr[index];
				if(e){
					arr.push(e.address)
				}   
			}
			addMember(arr.toString(), groupName)
			// setGroups([...arr])
	        checkValidation(arr)
		}, [memberCount, membersAddr])
		
	
		const checkValidation = async(arr) => {
			const isValid = await ENV.isUniqueArray(arr)
			if(isValid){
				setIsValidMemberList(true)
			}else{
				setIsValidMemberList(false)
			}
		}
	
		const addMemberAddr = async(name, index) => {
			const validated = await ENV.isEthereumAddress(name)
			let member_names = membersAddr
			member_names[index]= { address: name, validationMsg: validated || !name ? "" : "Invalid Address."}
			setMemberAddr([...member_names])
		}
	
		const removeMember = (val) => {
			let members_name = membersAddr
			const members = []
			Promise.all(members_name?.map((e, index)=> {
				if(index !== val){
					members.push(e)
				}
			}))
	
			setMemberCount(members.length)
			setMemberAddr([...members])
		}
	
		const pushMember = () => {
			setMemberCount((prevValue) => prevValue+1);
			addMemberAddr("", memberCount)
		}
	
		return (
			<>
				{/* add members in gorup */}
				{
					membersAddr?.map((item, index)=> {
						if(index !== 0 ){
							return (
								<div className='d-flex align-items-center' id={`member_${index}`} key={`member_${index}`}>
									<div className="input-holder">
										<input className="form-control mb-2"
											placeholder="Member Address"
											value={item.address}
											onChange={(e) => {addMemberAddr(e.target.value, index)}}
										/>
										{ item.validationMsg && <p className='text-danger ms-2 mb-0'>{item.validationMsg}</p>}
									</div>
									{
										index !== 0  ? <span style={{color:"#2b56f5", cursor: "pointer"}} className="ms-2" onClick={()=> { removeMember(index);}}><FontAwesomeIcon  icon={faCircleMinus} /></span> : ""
									}
								</div>
							)
						}
					})
				}
				<span style={{fontWeight:"600",marginLeft:"5px", cursor: "pointer"}} onClick={() => {pushMember()}} > <FontAwesomeIcon  style={{color:"#2b56f5"}}  icon={faCirclePlus } /></span>
				{
					!isValidMemberList && <p className='text-danger ms-2 mb-0'>Group must have unique members.</p>
				}
			</>
		)
	}


const ManageMembers = ({groups, setMembersOfGroup }) => {

	const { address } = ENV.getUserKeys('address');
	const [groupMembers, setGroupMembers] = useState({ 'council': address });

	useEffect(()=> {
		addMember(address, "council")
	},[])

	const renderAddMemberInGroup = () => {
		let addMemberInGroup = [
			<div className="input-holder mb-3">
				<p className='mb-2' style={{fontWeight:"500",fontSize:"14px",color:"#2b56f5",marginBottom:"0",marginLeft:"5px", textTransform:"capitalize"}}>{groups[0]}</p>
				<input className="form-control"
					placeholder='Wallet address'
					value={address}
				/>				
			</div>
		]

		for (let index = 1; index < groups.length; index++) {
			addMemberInGroup.push(
				<>
					<p className='mb-2' style={{fontWeight:"500",fontSize:"14px",color:"#2b56f5",marginBottom:"0",marginLeft:"5px", textTransform:"capitalize"}}>{groups[index]}</p>
					<AddMembers addMember={addMember} groupName={groups[index]} />
				</>
			)
		}
		return addMemberInGroup
	}

	const addMember = async (memberList, group) => {
		let temp = groupMembers
		temp[group] = memberList
		setGroupMembers(temp)
		setMembersOfGroup(temp)
	}

	return (
		<>
			{
				renderAddMemberInGroup()?.map((e) => {
					return e
				})
			}
		</>
	)
}

export default ManageMembers