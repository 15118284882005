import { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";
import groovyWalkAnimation from "../../../utils/lottie.json";
import outerCircle from '../../../assets/images/outer-circle.png'
import innerCircle from '../../../assets/images/inner-circle.png'
import tileOne from '../../../assets/images/tile-1.png'
import tileTwo from '../../../assets/images/tile-2.png'
import tileThree from '../../../assets/images/tile-3.png'
import tileFour from '../../../assets/images/tile-4.png'
import globeOne from '../../../assets/images/ball-1.svg'
import globeTwo from '../../../assets/images/ball-2.svg'
import globeThree from '../../../assets/images/ball-3.svg'
import globeFour from '../../../assets/images/ball-4.svg'
import globeFive from '../../../assets/images/ball-5.svg'
import grapImage from '../../../assets/images/hand.png'
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
import './banner.css'
gsap.registerPlugin(ScrollTrigger);
window.scrollTo(0, 0);
const Banner = () => {
    var x = window.matchMedia("(max-width: 1599px)")
    var z = window.matchMedia("(max-width: 1399px)")
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".banner",
            start: "top 50px",
            end: "bottom bottom",
            // scrub: false,
            pin: true,
            toggleActions: "play none none reverse",
            pinSpacer: false,
            smooth: 1,
            effects: true,
        },
    });


    useEffect(() => {
        const tx = gsap.timeline({
            scrollTrigger: {
                trigger: ".banner",
                start: '10%',
                end: 'bottom',
                // scrub: true,
                pin: true,
                toggleActions: "play none none reverse",
                pinSpacing: true,
                pinSpacer: false,
                smooth: 1,
                effects: true,
            },
        });
        tl.from(".tile-image-2", 3, { top: "70%" }, 'start');
        tl.from(".tile-image-3", 3, { y: "90px" }, 'start');
        tl.from(".tile-image-4", 3, { y: "180px" }, 'start');
        tl.to(".inner-circle", 3, { rotation: 360 }, 'start')
        tl.to(".outer-circle", 1, { "backdrop-filter": " blur(0px)" }, 'start')
        tx.to(".decen", { display: "none" }, 'center')
        tx.to(".no-code", { display: "none" }, 'center')
        tx.to(".customize-para", { display: "none" }, 'center')
        tx.to(".organi", 1, { ease: "slow(0.5, 1, false)", display: "block", duration: 2 }, 'start')
        tx.to(".creatives", 1, { rotationX: '-=360', display: "block", duration: 2, ease: "slow(0.5, 1, false)" }, 'start')
        tx.to(".customize-para-one", 1, { rotationX: '-=360', display: "block", duration: 2, ease: "slow(0.5, 1, false)" }, 'start')
        tx.to(".animated-section", 1, { autoAlpha: 0 }, 'center')
        tx.to(".grap", 1, { bottom: "25%" }, 'start')
        tx.to(".grap h3", 1, { top: "-30%" }, 'start')
        tx.to(".globe-1", 1, { top: "0%" }, 'start')
        tx.to(".globe-2", 1, { top: "16%" }, 'start')
        tx.to(".globe-3", 1, { top: "34%", right: "-8%" }, 'start')
        tx.to(".globe-4", 1, { top: "6%", right: "-19%" }, 'start')
        tx.to(".globe-5", 1, { bottom: "60%" }, 'start')
        function myFunction(x) {
            if (x.matches) {
                tx.to(".globe-1", 1, { left: "48%" }, 'start')
                tx.to(".globe-2", 1, {}, 'start')
                tx.to(".globe-3", 1, { right: "6%" }, 'start')
                tx.to(".globe-4", 1, { right: "0%" }, 'start')
                tx.to(".globe-5", 1, { left: "43%", bottom: "63%" }, 'start')
                tx.to(".globe-1 img", 1, { width: "90px" }, 'start')
                tx.to(".globe-2 img", 1, { width: "90px" }, 'start')
                tx.to(".globe-3 img", 1, { width: "90px" }, 'start')
                tx.to(".globe-4 img", 1, { width: "90px" }, 'start')
                tx.to(".globe-5 img", 1, { width: "90px" }, 'start')
            }
            else {
                tx.to(".globe-1 img", 1, { width: "105px" }, 'start')
                tx.to(".globe-2 img", 1, { width: "112px" }, 'start')
                tx.to(".globe-3 img", 1, { width: "105px" }, 'start')
                tx.to(".globe-4 img", 1, { width: "112px" }, 'start')
                tx.to(".globe-5 img", 1, { width: "112px" }, 'start')
            }
        }
        function myFunctionOne(z) {
            if (z.matches) {
                tx.to(".grap", 1, { bottom: "39%" }, 'start')
                tx.to(".globe-1", 1, { top: "4%", left: "27%" }, 'start')
                tx.to(".globe-2", 1, { top: "17%", left: "52%" }, 'start')
                tx.to(".globe-3", 1, { right: "6%", top: "31%" }, 'start')
                tx.to(".globe-4", 1, { right: "4%" }, 'start')
                tx.to(".globe-5", 1, { left: "25%", bottom: "60%" }, 'start')
            }
        }
        myFunction(x)
        x.addListener(myFunction)
        myFunctionOne(z)
        z.addListener(myFunctionOne)


    }, [])
    const options = {
        animationData: groovyWalkAnimation,
        loop: true
    };
    return (

        <section className='banner'>
            <Container>
                <Row>
                    <h1 className='text-center decen text-uppercase'>DECENTRALIZED</h1>
                    <h1 className='text-center organi text-uppercase'>Collaboration</h1>
                    <Col lg={6} md={12}>
                        <div className='animated-section d-flex'>
                            <div className='circle'>
                                <div className='outer-circle'>
                                    <img src={outerCircle} alt='circle-image' />
                                    <div className='inner-circle'><img src={innerCircle} alt='circle-image' /></div>
                                </div>
                            </div>
                            <div className='tile-image-1'><img src={tileFour} className='img-fluid' alt='image' /></div>
                            <div className='tile-image-2'><img src={tileOne} className='img-fluid' alt='image' /></div>
                            <div className='tile-image-3'><img src={tileThree} className='img-fluid' alt='image' /></div>
                            <div className='tile-image-4'><img src={tileTwo} className='img-fluid' alt='image' /></div>
                        </div>
                        <div className='content'>
                            <h3 className='no-code'>No Code</h3>
                            <h3 className='creatives'>Creatives</h3>
                                <div className='groovy-lottie d-flex align-items-center justify-content-end'>
                                    <Lottie animationData={groovyWalkAnimation} loop={true} />
                                    <Link to="/app" className='d-flex align-items-center'><div className='lottie-content text-light'>Launch App</div></Link>
                                </div>
                            <p className='customize-para'>MutoDAO is an open-source project advancing pain-free collaboration, decentralized governance, and seamless voting through self-enforcing digital rules.  </p>
                            <p className='customize-para-one'>MutoDAO is designed to unite broad coalitions to work together without hierarchical management and make effective decisions at scale through a frictionless voting solution</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='animated-hand'>
                            <div className='globe-1'><img src={globeOne} className="img-fluid" alt='image' /></div>
                            <div className='globe-2'><img src={globeTwo} className="img-fluid" alt='image' /></div>
                            <div className='globe-3'><img src={globeThree} className="img-fluid" alt='image' /></div>
                            <div className='globe-4'><img src={globeFour} className="img-fluid" alt='image' /></div>
                            <div className='globe-5'><img src={globeFive} className="img-fluid" alt='image' /></div>
                            <div className='grap'>
                                <div className='hand-image'><img src={grapImage} className="" alt='image' /></div>
                                <h3>Govern Better, Together</h3>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Banner