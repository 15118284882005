import { BEFORE_MEMBER, FETCH_MEMBERS, } from "../types";

const initialState = {
    getMembersAuth: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_MEMBERS:
            return {
                ...state,
                getMembersAuth: true,
                membersList:action.payload,
            }    
        case BEFORE_MEMBER:
            return {
                ...state,
                getMembersAuth:false
            }
        default:
            return {
                ...state
            }
    }
}