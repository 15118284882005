import React from "react";
import Like from '../../assets/images/like.svg'
import DisLike from '../../assets/images/dislike.svg'
import QuestionMark from '../../assets/images/message-question.svg'
import '../../pages/proposals/proposal.css'

function  VotingThreshold({ like, dislike, abstain }){
    return(

        <div className="voting-section">
            <ul className="list-unstyled d-flex justify-content-center align-items-center flex-wrap mb-0">
                <li className="d-flex align-items-baseline me-4"><span className="me-2"><img src={Like} alt="" /></span> <span className="text-success">{like}% Yes</span></li>
                <li className="d-flex align-items-baseline me-3"><span className="me-2"><img src={DisLike} alt="" /></span> <span className="text-danger">{dislike}% No</span></li>
                <li className="d-none align-items-baseline me-3"><span className="me-2"><img src={QuestionMark} alt="" /></span> <span className="abstain">{abstain}% Abstain</span></li>
            </ul>
        </div>
    )
}
export default VotingThreshold