import React from "react";
import { Col, Row } from "react-bootstrap";
import ChatList from "../../components/chatList/chatList";
import GroupChat from "../../components/groupChat/groupChat";
import PrivateChat from "../../components/privateChat/privateChat";
import MainChat from "../home/mainChat/mainChat";
import './chatting.css'

function Chatting(){
    return(
        <div className="chatting">
            <div className="d-flex">
                <ChatList />
                {/* <GroupChat /> */}
                <PrivateChat />
            </div>
        </div>
    )
}
export default Chatting