import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Lottie from "lottie-react";
import lottieOne from "../../../utils/lottie-2.json";
import lottieTwo from "../../../utils/lottie-1.json";
import lottieThree from "../../../utils/lottie-3.json";
import lottieFour from "../../../utils/lottie-4.json";
import './dao-things.css';
const DaoThings = () => {
    const daoCard = [
        { id: 1, title: "Cohesive Governance", description: "MutoDAO is built and run by the communities, giving direct power to the people, and where power is shared by all. In addition, it ensures flexible governance, which refers to the ability for members to propose and vote on changes to the organization's decision-making processes and governance structure.", lottie: lottieOne },
        { id: 2, title: "Financial Management", description: "MutoDAO holds the community funds and distributes them through proposals. This includes revenue collection, allocating funds for specific projects or expenses, and managing any reserves or investments. The decentralized voting solution allows for a transparent and democratic process for managing the organization's funds and assets", lottie: lottieThree },
        { id: 3, title: "Voting & Proposals", description: "Get a universally verifiable voting solution for web3. You can also create and manage proposals for all organizational decisions in a decentralized, cost-effective, and secure manner. DAO members can propose and vote on proposals to perform any on-chain action, and the smart contract will automatically execute the decision once it is passed.", lottie: lottieTwo ,iconThree:true},
        { id: 4, title: "Verify Communities", description: "Verify the community's identity using Know Your Customer (KYC) checks, ensuring it’s not involved in any illegal activities. You can also add and remove DAO members through proposals, and anyone whose wallet address is stored in MutoDAO can access and participate in the proposals and voting infrastructure. Only those with a vested interest in the organization can participate.", lottie: lottieFour },

    ]
    return (
        <section className='dao-things'>
            <Container>
                <Row>
                    <div className='dao-content'>
                        <h4 className='custom-heading text-center mb-5'>Get Into The Vision</h4>
                        <Row>
                        {daoCard.map((e) =>
                            <Col lg={6} md={12}>
                                <div className='dao-card d-flex align-items-center justify-content-start  '>
                                    <div className={`icon-box ${e.iconThree ? "icon-box-3" :""}`}>
                                        <Lottie animationData={e.lottie} loop={true} />
                                    </div>
                                    <div className='dao-card-content'>
                                        <h5>{e.title}</h5>
                                        <p>{e.description}</p>
                                    </div>
                                </div>
                            </Col>
                        )}
                        </Row>
                    </div>
                </Row>
            </Container>
        </section>
    )
}
export default DaoThings