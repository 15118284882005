//importing layouts....
import Layout1 from './layouts/Layout1';
import Layout2 from "./layouts/Layout2";
import Layout3 from './layouts/Layout3';
//importing pages
import Home from './components/home/Home';
import Search from './pages/search/search';
import CreateCommunity from './pages/communities/createCommunity';
import Communities from './pages/communities/communities';
import MemberRequest from "./pages/memberRequest/memberRequest";
import CommunityDetail from './pages/communities/communityDetail';
import CreateProposal from './pages/proposals/createProposal';
import ProposalListing from './pages/proposals/proposalListing';
import Vote from './pages/proposals/Voting';
import UserCommunities from "./pages/communities/userCommunities";
import ProposalDetails from './pages/proposals/proposalDetails';
import Chatting from './pages/chatting/chatting';
import Profile from './pages/profile/profile';
import Reward from './pages/reward/reward';
import Economy from './pages/economy/economy'
import TermsConditions from './pages/communities/terms-conditions';
import PrivacyPolicy from './pages/communities/privacyPolicy';
import Mission from './components/home/mission/Mission';
import OurInvestors from "./components/home/ourInvestors/ourInvestors"
import InvestorSignUp from './components/investor-signup/investor-signup';
import Faqs from './components/home/faqs/Faqs';
import Guids from './components/home/guides/Guids';

var routes = [
  {
    path: "/",
    layout: Layout3,
    name: "Home",
    access: true,
    exact: true,
    component: Home
  },
  {
    path: "/app", 
    layout: Layout1,
    name: "Dashboard",
    access: true,
    exact: true,
    component: Communities
  },
  {
    path: "/home",
    layout: Layout3,
    name: "Home",
    access: true,
    exact: true,
    component: Home
  },
  {
    path: "/mission",
    layout: Layout3,
    name: "Mission",
    access: true,
    exact: true,
    component: Mission
  },
  {
    path: "/our-investors",
    layout: Layout3,
    name: "Our Investors",
    access: true,
    exact: true,
    component: OurInvestors
  },
  {
    path: "/investor-signup",
    layout: Layout3,
    name: "Investor Signup",
    access: true,
    exact: true,
    component: InvestorSignUp
  },
  {
    path: "/faqs",
    layout: Layout3,
    name: "Faqs",
    access: true,
    exact: true,
    component: Faqs
  },
  {
    path: "/guids",
    layout: Layout3,
    name: "Guids",
    access: true,
    exact: true,
    component: Guids
  },

  {
    path: "/search",
    layout: Layout1,
    name: "Search Results",
    access: true,
    exact: true,
    component: Search
  },
  {
    path: "/create-community",
    layout: Layout1,
    name: "Create Community",
    access: true,
    exact: true,
    component: CreateCommunity
  },
  {
    path: "/communities",
    layout: Layout1,
    name: "Communities",
    access: true,
    exact: true,
    component: Communities
  },



  {
    path: "/member-request/:slug/:address",
    layout: Layout1,
    name: "MemberRequest",
    access: true,
    exact: true,
    component: MemberRequest
  },
  {
    path: "/user-communities",
    layout: Layout1,
    access: true,
    exact: true,
    component: UserCommunities
  },
  {
    path: "/community/:address",
    layout: Layout1,
    name: "Communities-details",
    access: true,
    exact: true,
    component: CommunityDetail
  },
  {
    path: "/create-proposal/:addressComm/:_daoId", //proposalType (optional query search)
    layout: Layout1,
    name: "Create-proposals",
    access: true,
    exact: true,
    component: CreateProposal
  },
  {
    path: "/proposals",
    layout: Layout1,
    name: "Proposals",
    access: true,
    exact: true,
    component: ProposalListing
  },
  {
    path: "/proposal-vote/:slug",
    layout: Layout1,
    name: "Proposals-vote",
    access: true,
    exact: true,
    component: Vote
  },
  {
    path: "/proposal-detail/:slug",
    layout: Layout1,
    name: "Proposals-detail",
    access: true,
    exact: true,
    component: ProposalDetails
  },
  // {
  //   path: "/chat",
  //   layout: Layout1,
  //   name: "Chatting",
  //   access: true,
  //   exact: true,
  //   component: Chatting
  // },
  {
    path: "/profile",
    layout: Layout1,
    name: "Profile Page",
    access: true,
    exact: true,
    component: Profile
  },

  {
    path: "/terms-condition",
    layout: Layout2,
    name: "Term & Condition",
    access: true,
    exact: true,
    component: TermsConditions
  },
  {
    path: "/privacy-policy",
    layout: Layout2,
    name: "Privacy Policy",
    access: true,
    exact: true,
    component: PrivacyPolicy
  },

  // {
  //   path: "/reward",
  //   layout: Layout1,
  //   name: "Reward Page",
  //   access: true,
  //   exact: true,
  //   component: Reward
  // },
  // {
  //   path: "/economy",
  //   layout: Layout1,
  //   name: "Economy Page",
  //   access: true,
  //   exact: true,
  //   component: Economy
  // }

];

export default routes;
