import React from 'react'
import './dao.css'

const Dao = (props) => {
    return (
        <div className="search-banner d-flex align-items-center">
            <div className="search-img">
                <img src={props.image} alt='' className='img-fluid' />
            </div>
            <div className="search-data">
                <span className="small-tag">{props.smallTag}</span>
                <h2>{props.head}</h2>
                <p>{props.para}</p>
            </div>
        </div>
    )
}

export default Dao