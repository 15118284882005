import { LOGIN_USER, BEFORE_LOGIN, BEFORE_PROFILE, UPDATE_PROFILE, GET_PROFILE_DATA, SET_WALLET_ERROR, DISCONNECT_USER } from "../../redux/types";

const initialState = {
    userData: null,
    loginAuth: false,
    profileData: {}, // profile data
    getProfileDataAuth: false,
    editProfileDataAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                userData: action.payload,
                loginAuth: true
            }
        case BEFORE_LOGIN:
            return {
                ...state,
                userData: null,
                loginAuth: false,
            }
        case GET_PROFILE_DATA:
            return {
                ...state,
                getProfileDataAuth: true,
                profileData: action.payload
            }
        case UPDATE_PROFILE:
            return {
                ...state,
                editProfileDataAuth: true,
                profileData: action.payload,
                userData: action.payload
            }
        case BEFORE_PROFILE:
            return {
                ...state,
                // profileData: {}, // profile data
                getProfileDataAuth: false,
                editProfileDataAuth: false,
                // userData: null,
                // loginAuth: false,
            }
        case DISCONNECT_USER: {
            return {
                ...state,
                profileData: {}, // profile data
                getProfileDataAuth: false,
                editProfileDataAuth: false,
                userData: null,
                loginAuth: false,
            }
        }
        case SET_WALLET_ERROR:
            return {
                ...state,
                walletError: action.payload
            }
        default:
            return {
                ...state
            }
    }
}