import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Form, Row, Col } from 'react-bootstrap'
import { Images } from '../../assets/assets';
import { getUserCommunities, beforeCommunity } from "../../redux/communities/community.action";
import Image1 from '../../assets/images/community-01.png';
import moment from "moment";
import { ENV } from "../../config/config";
import './community.css'
import data from '../../components/communityItem/communityItem';
import { connect } from 'react-redux';
import FullPageLoader from '../../components/full-page-loader/full-page-loader';

function UserCommunities(props) {

    const [userCommunities, setUserCommunities] = useState()
    const [communityName, setCommunityName] = useState('')
    const [resetButton, setResetButton] = useState(false)
    const [loader, setLoader] = useState(true)
    const currentTime = moment();
    const { _id } = ENV.getUserKeys();

    useEffect(() => {
        let payload = { userId: _id }
        let qs = ENV.objectToQueryString(payload)
        props.getUserCommunities(qs)
    }, [])

    useEffect(() => {
        if (props.community.getUserCommunityAuth) {
            setLoader(false)
            props.beforeCommunity()
            const { userDaos } = props.community.userCommunities;
            setUserCommunities(userDaos)
        }
    }, [props.community.getUserCommunityAuth])

    const checkKeyCode = (e) => {
        if (e.keyCode === 13) {
            searchUserCommunities();
            e.preventDefault()
        }
    }

    const searchUserCommunities = () => {
        let payload = { name: communityName, userId: _id }
        let qs = ENV.objectToQueryString(payload)
        props.getUserCommunities(qs)
        props.beforeCommunity()
        setResetButton(true)
    }

    const reset = () => {
        setResetButton(false)
        setCommunityName('')
        let payload = { userId: _id }
        let qs = ENV.objectToQueryString(payload)
        props.getUserCommunities(qs)
    }

    return (
        <>
            {loader ?
                <FullPageLoader />
                :
                <div className="explore-communities">
                    <div className="create-community">
                        <div className="page-header d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-0 mb-md-4">
                            <h2 className="me-0 me-md-2 mb-3 mb-md-0 font-weight-bold">My Communities</h2>
                            <div className="d-flex btns-holder flex-column flex-sm-row">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <span>Sort By</span>
                                        <svg className="ms-2" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.5 7.75H3.5C3.09 7.75 2.75 7.41 2.75 7C2.75 6.59 3.09 6.25 3.5 6.25H21.5C21.91 6.25 22.25 6.59 22.25 7C22.25 7.41 21.91 7.75 21.5 7.75Z" />
                                            <path d="M18.5 12.75H6.5C6.09 12.75 5.75 12.41 5.75 12C5.75 11.59 6.09 11.25 6.5 11.25H18.5C18.91 11.25 19.25 11.59 19.25 12C19.25 12.41 18.91 12.75 18.5 12.75Z" />
                                            <path d="M14.5 17.75H10.5C10.09 17.75 9.75 17.41 9.75 17C9.75 16.59 10.09 16.25 10.5 16.25H14.5C14.91 16.25 15.25 16.59 15.25 17C15.25 17.41 14.91 17.75 14.5 17.75Z" />
                                        </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Popularity</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Members</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Posts</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Link to="/create-community" className="freedom-btn freedom-solid-btn transition ms-md-3">Create A Community</Link>
                            </div>
                        </div>
                    </div>
                    <div className="community-search-form">
                        <Row>
                            <Col md={6}>
                                <Form className="search-form position-relative">
                                    <span className="search-icon">
                                        <img src={Images.seacrhIcon} alt="" onClick={() => searchUserCommunities()} />
                                    </span>
                                    <div className="input-holder">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            onKeyDown={(e) => checkKeyCode(e)}
                                            value={communityName}
                                            onChange={(e) => setCommunityName(e.target.value)}
                                        />
                                    </div>
                                </Form>
                            </Col>
                            <Col md={2}>
                                {resetButton && <button onClick={() => reset()} className="freedom-btn freedom-outline-btn transition ms-3">Reset</button>}
                            </Col>
                        </Row>
                    </div>
                    <div className="communities-list">
                        <Row>
                            {userCommunities.length > 0 ? userCommunities.map((item) =>
                                <Col md={6}>
                                    <div className="community-item mb-4 d-flex flex-column flex-sm-row">
                                        <div className="image-holder mb-3 mb-md-0 me-3">
                                            <img src={Image1} alt="Community Image" />
                                        </div>
                                        <div className="community-detail">
                                            <strong className="community-name d-block mb-3">
                                                <Link className="transition" to="community-detail">{item.name}</Link>
                                            </strong>
                                            <p>{item.description}</p>
                                            <ul className="list-unstyled d-flex flex-wrap community-stats">
                                                <li className="mb-4">
                                                    <span className="stat-icon me-2">
                                                        <img src={item.IconFlag} alt="" />
                                                    </span>
                                                    <span className="stat-text">
                                                        Founded <strong>{currentTime.diff(item.createdAt, "days") > 0 ? `${currentTime.diff(item.createdAt, "days")} days ago` : `${currentTime.diff(item.createdAt, "hours")} hours ago`}</strong>
                                                    </span>
                                                </li>
                                                <li className="mb-4 mr-0">
                                                    <span className="stat-icon me-2">
                                                        <img src={item.IconMembers} alt="" />
                                                    </span>
                                                    <span className="stat-text">
                                                        <strong>{item.members ? item.members : 0}</strong> members
                                                    </span>
                                                </li>
                                                <li className="mb-4">
                                                    <span className="stat-icon me-2">
                                                        <img src={item.IconPosts} alt="" />
                                                    </span>
                                                    <span className="stat-text">
                                                        <strong>{item.posts ? item.posts : 0}</strong> Posts
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            ) : <div className='d-flex justify-content-center align-items-center not-found'><span >No Communities Found.</span></div>}
                        </Row>
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = state => ({
    community: state.community
});

export default connect(mapStateToProps, { getUserCommunities, beforeCommunity })(UserCommunities);