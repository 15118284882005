import React, { useEffect } from 'react'
import Banner from '../../components/home/banner/Banner'
import '../../../src/assets/css/style.css'
import ProportionalCard from './proportional/ProportionalCard'
import GetInvolved from './get-involved/GetInvolved'
import Community from './community/Community'
import DaoThings from './dao-things/DaoThings'
import Ecosystem from './ecosystem/Ecosystem'
import '../../assets/css/style.css'
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Banner />
      <div className='custom-bg'>
        <ProportionalCard />
        <GetInvolved />
        <Community />
        <DaoThings />
        <Ecosystem />
      </div>
    </div>
  )
}

export default Home