import React, { useState } from "react";
import Like from '../../assets/images/like.svg'
import DisabledLike from "../../assets/images/disabled-like.svg"
import DisLike from '../../assets/images/dislike.svg'
import DisabledDisLike from "../../assets/images/disabled-dislike.png"
import QuestionMark from '../../assets/images/message-question.svg'
import DisabledQuestionMark from "../../assets/images/disabled-questionMark.png"
import "../../pages/proposals/proposal.css"
import { useNavigate } from "react-router-dom"

function VoteCast({ voteNow, loader}) {

    const navigate = useNavigate()
    const [voteVal, setVoteVal] = useState(2)

    const submitVote = () => {
        if(voteVal !== 2){
            voteNow(voteVal)  
        }
    }

    return (
        <div className="vote-cast">
            <h2>Your Cast Vote?</h2>
            <div className="d-flex justify-content-center align-items-center boxes flex-wrap">
                <div className="box">
                    <div className="mb-2">
                        <div className="form-check"><input className="form-check-input cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={()=>setVoteVal(1)} /></div>
                        {/* <Form.Check  type="radio" aria-label="radio 1" /> */}
                    </div>
                    <div className="mb-2"> <img className="default" src={Like} alt='' />
                        <img className="disable" src={DisabledLike} alt='' />
                        <img className="active" src={Like} alt='' />
                    </div>
                    <span>Yes</span>
                </div>
                <div className="box">
                    <div className="form-check mb-2"><input className="form-check-input cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={()=>setVoteVal(0)} /></div>
                    <div className="mb-2"> <img className="default" src={DisLike} alt='' />
                        <img className="disable" src={DisabledDisLike} alt='' />
                        <img className="active" src={DisLike} alt='' />
                    </div>
                    <span>No</span>
                </div>
                {/* <div className="box d-none">
                    <div className="form-check mb-2"><input className="form-check-input cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onChange={()=>setVoteVal(2)} /></div>
                    <div className="mb-2"> <img className="default" src={QuestionMark} alt='' />
                        <img className="disable" src={DisabledQuestionMark} alt='' />
                        <img className="active" src={QuestionMark} alt='' />
                    </div>
                    <span>Abstain</span>
                </div> */}
            </div>
            <div className="d-flex justify-content-center align-items-center flex-wrap">
            <button className=" cancel-btn freedom-outline-btn transition ms-3 mb-3" onClick={() => navigate("/proposals")}>Cancel</button>
            <button className=" cancel-btn freedom-solid-btn transition ms-3 mb-3 d-flex justify-content-center align-items-center" disabled={loader || voteVal === 2 ? true : false} onClick={() => submitVote()} >{loader && <div className="loader me-2"></div>} Submit</button>
       
            </div>
        </div>

    )
}
export default VoteCast