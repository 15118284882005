import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { ENV } from "../config/config";
const {requiredChainId, networks} = ENV;

const chains = [requiredChainId];
const POLLING_INTERVAL = 15000;

export const injected = new InjectedConnector({
  supportedChainIds: chains,
});

export const walletconnect = new WalletConnectConnector({
  rpc: networks,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});


const connectors = {
  injected: {
    provider: injected,
    name: 'MetaMask',
  },
  walletConnect: {
    provider: walletconnect,
    name: 'Wallet Connect',
  },
};

export default connectors;