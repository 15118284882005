import React from 'react'
import { Images } from '../../assets/assets'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, Form, Button } from 'react-bootstrap';
import './chat.css'

const Chat = (props) => {
    const callShowChat = (status) =>{
        props.onMessageClick(status)
    }
    return (
        <div className="message-chat-section">
            <div className="chat-area-heading text-center">
                <button onClick={()=>callShowChat(false)} className="arrow"><img src={Images.arrowLeft} alt='' className='img-fluid' /></button>
                <span className="chat-tag">Chat</span>
            </div>
            <div className="chat-area">
                <div className="chat-area-banner d-flex align-items-center">
                    <div className="chat-area-img">
                        <img src={Images.personOne} alt='' className='img-fluid' />
                    </div>
                    <div className="chat-area-data">
                        <span className="name">Courtney Henry</span>
                        <span className="time"><span className="green">Online</span> - Last seen, 1m ago</span>
                    </div>
                    <Dropdown className="options">
                        <Dropdown.Toggle className="options-button" id="dropdown-basic">
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="">Media Gallery</Dropdown.Item>
                            <Dropdown.Item href="">Share Contact</Dropdown.Item>
                            <Dropdown.Item href="">Block Contact</Dropdown.Item>
                            <Dropdown.Item href="">Report</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="typing-msg d-flex align-items-center">
                    <span className="type-img"><img src={Images.typingImg} className="img-fluid" /></span>
                    <span className="type-msg ms-2">Henry is typing</span>
                </div>
                <div className="input-section">
                    <Form className="form-area">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="input" placeholder="Write your message..." />
                        </Form.Group>
                        <Button type="submit" className="submit-button">
                            <img className="img-fluid" src={Images.submitIcon}  />
                        </Button>
                        <span className="pin-icon"><img src={Images.pinIcon} className="img-fluid" /></span>
                        <span className="emoji-icon"><img src={Images.emojiIcon} className="img-fluid" /></span>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Chat