const proposalTypes = [
    { value: 0, label: <span id="disable-style">Transfer/Add Bounty</span>,isdisabled: true, fields: [{name: "", val: ""}] ,}, 
    { value: 1, label: 'Propose a Transfer', name: "Transfer", fields: [{name: "Amount", val: "amount"}, {name: "Target Account", val: "targetAcc"},] }, // amount, targetAcc,
    { value: 2, label: 'Propose to Create Bounty', name: "Create Bounty", fields: [{name: "Bounty Amount", val: "bountyAmount"}, {name: "Available Claim Amount", val: "availableClaims"}, {name: "Days to complete", val: "daystoComplete"}]}, // bountyAmount, availableClaims, daystoComplete,
    { value: 0, label: <span id="disable-style">Governance</span>, isdisabled: true, fields: [{name: "", val: ""}] }, 
    { value: 3, label: 'Propose to Change DAO Name', name: "Change DAO Name", fields: [{name: "Proposed DAO Name", val: "proposedDaoName"}] }, // proposedDaoName,
    { value: 4, label: 'Propose to Change DAO Purpose', name: "Change Dao Purpose", fields: [{name: "Proposed Purpose", val: "proposedPurpose"}] }, // proposedPurpose
    { value: 5, label: 'Propose to Change DAO Links', name: "Change Dao Links", fields: [{name: "Proposed Links", val: "links"}] }, // links
    { value: 6, label: 'Propose to Change DAO Cover and Logo', name: "Flag and Logo", fields: [{name: "Proposed Cover Image", val: "proposedCover"},{name: "Proposed Logo", val: "proposedLogo"}]}, // proposedCover, proposedLogo
    { value: 7, label: 'Propose to Change DAO Legal Status and Doc', name: "Change DAO Legal Status and Doc", fields: [{name: "Proposed Legal Status", val: "proposedLegalStatus"},{name: "Proposed Legal Docs", val: "proposedLegalDocs"}]  }, // proposedLegalStatus, proposedLegalDocs
    { value: 0, label:  <span id="disable-style">Claim Bounty</span>, isdisabled: true, isSubHeading: true, fields: [{name: "", val: ""}] }, 
    { value: 8, label: <span id="disable-subtype">Propose to Create a Claim</span>, isdisabled: true, name: "Create a Claim", fields: [] }, // bondsToCreateProposal, timeBeforeProposalExpires, bondsToClaimBounty, timeToUnclaimBounty {name: "Bonds", val: "bondsToCreateProposal"},{name: "Expiry", val: "timeBeforeProposalExpires"}, {name: "Bonds to claim bounty", val: "bondsToClaimBounty"}, {name: "Time to UnClaim Bounty", val: "timeToUnclaimBounty"}
    { value: 0, label: <span id="disable-style">Create Group/ Change Member of DAO</span>, isdisabled: true, fields: [{name: "", val: ""}] }, 
    { value: 9, label: 'Propose to Create a Group', name: "Create a Group", fields: [{name: "New Group Name", val: "newGroupName"}, {name: "Initial Member Account", val: "intialMemberAccount"},]  }, // newGroupName, intialMemberAccount,
    { value: 10, label: 'Propose to Add Member To Group', name: "Add Member", fields: [{name: "Target Group Id", val: "targetGroupId"}, {name: "Member To Add ", val: "memberToAdd"}]}, // targetGroupName, memberToAdd
    { value: 11, label: 'Propose to Remove Member from Group', name: "Remove Member", fields: [{name: "Target Group Id", val: "targetGroupId"}, {name: "Member To Remove", val: "memberToRemove"}] }, // targetGroupName, memberToRemove
    { value: 0, label: <span id="disable-style">Vote</span>, isdisabled: true, fields: [{name: "", val: ""}] }, 
    { value: 12, label: 'Propose a Poll', name: "Create Poll", fields: [] }, // with this type basic name and description would come
    { value: 0, label: <span id="disable-style">Funtion Call</span>, isdisabled: true, fields: [{name: "", val: ""}] },
    { value: 13, label:  'Custom Function Call', name: "Custom Function Call", fields: [{name: "Contract Address", val: "contractAddrFnCall"}, {name: "Function", val: "fnCallName"}, {name: "JSON ABI", val: "fnCallAbi"}]  } // contractAddrFnCall, fnCallName, fnCallAbi
]

export default proposalTypes