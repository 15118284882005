import React from 'react';
import ReactDOM from 'react-dom/client';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';
import getLibrary from './utils/getLibrary';
// import TermsConditions from './pages/communities/terms-conditions';


const NetworkContextName = "NETWORK";
const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3ProviderNetwork getLibrary={getLibrary}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
          {/* <Route path="/terms-conditions">
            <TermsConditions />
          </Route> */}
        </BrowserRouter>
      </Provider>
    </Web3ProviderNetwork>
  </Web3ReactProvider>
);
