import React, { useState, useEffect } from "react";
import './proposal.css'
import VoteCast from "../../components/voteCast/voteCast";
import Proposal from "../../components/proposal/proposal";
import VoteRecorded from "../../components/voteRecorded/voteRecorded";
import { connect } from 'react-redux';
import { beforeVote, addVote } from '../../redux/voting/vote.action'
import { beforeProposal, getProposal } from '../../redux/proposal/proposal.action'
import { useParams } from 'react-router-dom';
import { voteCast } from '../../utils/web3';
import AlertModal from "../../components/alertModal/alertModal";
import FullPageLoader from "../../components/full-page-loader/full-page-loader";
import { useNavigate, useLocation} from 'react-router-dom'
import WalletConnect from "../../components/walletConnect/walletConnect";

const Vote = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    let { slug } = useParams();
    const [showAlert, setShowAlert] = useState()
    const [showVoteCast, setShowVoteCast] = useState(true)
    const [showVoteSuccess, setShowVoteSuccess] = useState(false)
    const [loader, setLoader] = useState(false)
    const [proposal, setProposal] = useState({})
    const [votes, setVotes] = useState([])
    const [user, setUser] = useState()
    const [userAuth, setUserAuth] = useState(false)
    const [permissions, setPermissions] = useState([])
    const [alertMsg, setAlertMsg] = useState()
    const [alreadyVoted, setAlreadyVoted] = useState(true)
    const [showWalletConnect, setShowWalletConnect] = useState()
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(()=> {
        // props.beforeProposal()
        // props.getProposal(`proposalId=${slug}&fetchVotes=true&walletAddress=${props?.userData?.userData?.address}`)
        setUser(props.userData.userData)
        setUserAuth(props.userData.loginAuth)
    }, [])

    useEffect(()=> {
        if(props.userData.loginAuth){
            setLoader(true)
            props.beforeProposal()
            props.getProposal(`proposalId=${slug}&fetchVotes=true&walletAddress=${props?.userData?.userData?.address}`)
            setUser(props.userData.userData)
            setUserAuth(props.userData.loginAuth)
        }else {
            navigate("/proposals")
        }
    }, [props.userData.loginAuth])

    useEffect(()=> {
        if(props.proposal.getProposalAuth){
            setAlreadyVoted(props.proposal?.proposal?.alreadyVoted)
            setProposal(props.proposal?.proposal?.proposal[0])
            setVotes(props.proposal?.proposal?.votes)
            setPermissions(props.proposal?.proposal?.permissions)
            setLoader(false)
        }
    }, [props.proposal.getProposalAuth])

    useEffect(()=> {
        if(props.vote.addVoteAuth){
            setLoader(false)
            setShowVoteSuccess(true); setShowVoteCast(false);
            props.beforeVote()
        }
    }, [props.vote.addVoteAuth])
    
    const checkPermission = () => {
        let haveValidPermission = false
        Promise.all(permissions?.map((e)=> {
            Promise.all(e?.votingPermission.map((e)=> {
                if(e == proposal.type){
                    haveValidPermission = true
                    return
                }
            }))
        }))
        return haveValidPermission
    }

    const voteNow = async ( voteStatus ) => {
        if(!props.userData.loginAuth){
            // setShowAlert(true)
            setShowWalletConnect(true)
            // setAlertMsg("Please Connect with Wallet.")
        }else if(alreadyVoted){
            setShowAlert(true)
            setAlertMsg("You have already voted on this proposal.")
        }else if(!checkPermission()){
            setShowAlert(true)
            setAlertMsg("You dont have permission to do this action.")
        }else {
            setLoader(true)
            props.beforeVote()
            let payload = {
                proposalId: slug,
                voterId : user?._id,
                voteStatus 
            }
            let voteVal = voteStatus == 1 ? true : false
            const address = props.userData?.userData?.address
            const tx = await voteCast(proposal?._daoId, proposal?._proposalId, voteVal, address)
            if(tx){
                props.addVote(payload)
            }else {
                setLoader(false)
                return
            }
        }
    }
    return (
        <>
            { loader && <FullPageLoader />}
            <div className="proposal-listing">
                <div className="top-heading d-flex justify-content-between flex-wrap">
                    <div className="page-header d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-0 mb-md-4">
                        <h2 className="me-0 me-md-2 mb-3 mb-md-0 font-weight-bold">Proposals - MUTO Network</h2>
                    </div>
                </div>
                <div className=" vote-pool" key={proposal?._id}>
                    <div className="voting"><div className="content"><Proposal data={proposal} /></div></div>
                        {
                            proposal?.status === 1 ?
                                <>
                                    {
                                        showVoteCast && <VoteCast voteNow={voteNow} loader={loader}/>
                                    }
                                    {
                                        showVoteSuccess && <VoteRecorded proposalId={slug} />
                                    }
                                </> : ''
                        }
                </div>
            </div>
            <AlertModal show={showAlert} setShow={setShowAlert} msg={alertMsg}/>
            <WalletConnect show={showWalletConnect} setShow={setShowWalletConnect} />

        </>
    )
}

const mapStateToProps = state => ({
    proposal: state.proposal,
    userData: state.loginKey,
    vote: state.vote,
    state: state
})

export default connect(mapStateToProps, {getProposal, beforeProposal, addVote, beforeVote})(Vote)
