import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { ENV } from "../../config/config";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import defaultImage from '../../assets/images/placeholder.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import DummyProfile from "../../assets/images/dummy-dp.png"
// import  {toast}  from 'react-toastify';
import { updateProfile, getProfileData, beforeProfileData } from '../../redux/profile/profile.action'
import FullPageLoader from "../../components/full-page-loader/full-page-loader";
import AlertModal from '../../components/alertModal/alertModal';
import { setError } from "../../redux/shared/error/error.action";
import { useNavigate } from "react-router-dom"
import WalletConnect from '../../components/walletConnect/walletConnect';

function Profile(props) {

    const navigate = useNavigate()
    const [loader, setLoader] = useState(true);
    const [updatePicLoader, setUpdatePicLoader] = useState(false);
    const [updateFormLoader, setUpdateFormLoader] = useState(false);
    const [showAlert, setShowAlert] = useState();
    const [errorMsg, setErrorMsg] = useState({ name: '' });
    const [message, setMessage] = useState(null)
    const [profileData, setProfileData] = useState({
        username: '', email: '', profileImage: '',
    })
    const [alertType, setAlertType] = useState(false)
    const [showWalletConnect, setShowWalletConnect] = useState()

    const { _id } = ENV.getUserKeys()

    useEffect(() => {
        window.scroll(0, 0)
        if (_id) {
            setLoader(true)
            props.getProfileData(_id)
        } else {
            navigate("/app")
        }
    }, [])

    const [pic, setPic] = useState({
        profileImage: null,
    })

    useEffect(() => {
        if (props.userData.getProfileDataAuth) {
            setLoader(false)
            setProfileData(props?.userData.userData)
        }
    }, [props.userData.getProfileDataAuth])

    useEffect(() => {
        if (props.userData.editProfileDataAuth) {
            const { profileData } = props.userData
            setProfileData(profileData)
            setLoader(false)
            setShowAlert(true)
            setAlertType(true)
            setMessage("Profile is updated successfully")
            setUpdatePicLoader(false);
            setUpdateFormLoader(false)
            props.beforeProfileData()
        }
    }, [props.userData.editProfileDataAuth])

    const onFileChange = (e) => {
        let file = e.target.files[0]
        if (file) {
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
                let reader = new FileReader();
                reader.readAsDataURL(file)
                reader.onloadend = function () {
                    setProfileData({ ...profileData, profileImage: reader.result })
                }
                setPic({ ...pic, profileImage: file })
                let err = errorMsg
                err["img"] = ""
                setErrorMsg({ ...err })
            } else {
                setProfileData({ ...profileData, profileImage: props.userData.profileData.profileImage })
                setPic({ ...pic, profileImage: null })
                let err = errorMsg
                err["img"] = "Image must be of type jpg/jpeg/png."
                setErrorMsg({ ...err })
            }
        }
    }

    const submitPic = (e) => {
        e.preventDefault()
        if (!props.userData.loginAuth) {
            // setShowAlert(true)
            setShowWalletConnect(true)
            // setMessage("Please Connect wallet")
        } else {
            if (!pic.profileImage) {
                let err = errorMsg
                err["img"] = "Please select Profile image."
                setErrorMsg({ ...err })
            }
            else {
                let formData = new FormData()
                formData.append("profileImage", pic["profileImage"])
                formData.append("_id", profileData._id)
                props.updateProfile(formData)
                setUpdatePicLoader(true)
                document.querySelector('#imageUploader').value = null
                setPic({ ...pic, profileImage: null })
            }
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        setErrorMsg({})
        let error = {}
        if (!props.userData.loginAuth) {
            setShowAlert(true)
            setMessage("Please Connect wallet")
        } else {
            let valid = true
            let error = {}
            var regName = /^[a-zA-Z][a-zA-Z ]*$/;
            if (!profileData.username) {
                error = { ...error, name: "Name is required." }
                valid = false
            } else if (profileData.username.length > 30) {
                error = { ...error, name: "Name must not be more than 30 character long." }
                valid = false
            } else if (!regName.test(profileData.username)) {
                error = { ...error, name: "Invalid name." }
                valid = false
            }
            else {
                error = { ...error, name: "" }
            }

            if (!profileData.email) {
                error = { ...error, email: "Email is required." }
                valid = false
            } else {
                error = { ...error, email: "" }
            }
            setErrorMsg({ ...error })

            if (valid) {
                let formData = new FormData()
                for (const key in profileData)
                    formData.append(key, profileData[key])
                props.updateProfile(formData)
                setUpdateFormLoader(true)
            }
        }
    }

    return (
        <>
            {
                loader ? <FullPageLoader />
                    :
                    <div className=" profile-page">
                        <Container fluid>
                            <Row>
                                <Col xl="9" md="6" className="mb-2">
                                    <Form onSubmit={(e) => { submitForm(e) }}>
                                        <Card style={{ borderRadius: "12px", minHeight: "400px" }} className="pb-4">

                                            <Card.Body>
                                                <h2 className="mb-4">Profile</h2>
                                                <Row>
                                                    <Col className="mb-3" xl="6">
                                                        <Form.Group>
                                                            <label className="mb-2">Name<span className="text-danger"> *</span></label>
                                                            <Form.Control value={profileData?.username} placeholder="Name" type="text" onChange={(e) => { setProfileData({ ...profileData, username: e.target.value }); setErrorMsg({ ...errorMsg, name: "" }) }}></Form.Control>
                                                            {errorMsg && errorMsg.name ? < label className={`text-danger pl-1`}>{errorMsg.name}</label> : ''}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="pl-3 mb-3" xl="6">
                                                        <Form.Group>
                                                            <label className="mb-2">Email<span className="text-danger"> *</span></label>
                                                            <Form.Control value={profileData?.email} onChange={(e) => { setProfileData({ ...profileData, email: e.target.value }); setErrorMsg({ ...errorMsg, email: "" }) }} placeholder="Email" type="email"></Form.Control>
                                                            {errorMsg && errorMsg.email ? < label className={`text-danger pl-1`}>{errorMsg.email}</label> : ''}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <button className="freedom-btn freedom-solid-btn transition  d-flex justify-content-center align-items-center " type="submit" variant="info">
                                                    {updateFormLoader && <div className="loader me-2"></div>}
                                                    Update Profile
                                                </button>
                                            </Card.Body>
                                        </Card>
                                    </Form>
                                </Col>
                                <Col xl="3" md="6" className="mb-2">
                                    <Card style={{ borderRadius: "12px", minHeight: "400px" }} className="card-user">
                                        <Card.Body>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <div className="position-relative">
                                                    <div class="image-upload">
                                                        <label for="imageUploader">
                                                            <div className="edit-img cursor-pointer">
                                                                <FontAwesomeIcon icon={faCamera} />
                                                            </div>
                                                        </label>

                                                        <input style={{ display: "none" }} id="imageUploader" type="file" varient="info" accept=".png,.jpeg,.jpg" onChange={(e) => onFileChange(e)} />
                                                    </div>
                                                    <div className="author">

                                                        <img alt="..." className="avatar border-gray img-fluid" src={profileData?.profileImage ? profileData?.profileImage : DummyProfile}></img>
                                                    </div>
                                                </div>

                                            </div>
                                            <Form className="d-flex justify-content-center align-items-center" onSubmit={(e) => submitPic(e)}>
                                                <Form.Group className="pl-3 pr-3 update-button">

                                                    <div className="d-flex justify-content-center">
                                                        <button className="freedom-btn freedom-solid-btn transition  mb-2 update-btn d-flex justify-content-center align-items-center" type="submit" variant="info">
                                                            {updatePicLoader && <div className="loader me-2"></div>}
                                                            Update Picture
                                                        </button>
                                                    </div>
                                                    {errorMsg["img"] && <p className="error-msg text-danger">{errorMsg["img"]}</p>}
                                                </Form.Group>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            }
            <WalletConnect show={showWalletConnect} setShow={setShowWalletConnect} />
            <AlertModal show={showAlert} setShow={setShowAlert} msg={message} success={alertType} />
        </>
    )

}
const mapStateToProps = state => ({
    userData: state.loginKey,
})

export default connect(mapStateToProps, { updateProfile, getProfileData, beforeProfileData })(Profile);