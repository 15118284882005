import { CLEAR_PROPOSALS, ADD_PROPOSAL, BEFORE_PROPOSAL, GET_PROPOSAL, GET_PROPOSALS, DELETE_PROPOSAL, EDIT_PROPOSAL, GET_ERRORS, CHECK_VALIDATION, BEFORE_CHECK_VALIDATION } from "../../redux/types";
import { ENV } from "../../config/config";
import { emptyError } from '../../redux/shared/error/error.action';

const { address } = ENV.getUserKeys('address');

export const beforeProposal = () => {
    return {
        type: BEFORE_PROPOSAL
    }
}

export const clearProposal = () => {
    return {
        type: CLEAR_PROPOSALS
    }
}

export const addProposal = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}proposal/create/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: ADD_PROPOSAL,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const checkProposalValidation = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}proposal/check-validation`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: CHECK_VALIDATION,
                payload: {isValid: data.isValid, message: data.message}
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const beforeCheckValidation = () => {
    return {
        type: BEFORE_CHECK_VALIDATION
    }
}

export const getProposals = (qs = null) => dispatch => { // all proposals
    dispatch(emptyError());
    let url = `${ENV.url}proposal/fetch-proposals/`;

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_PROPOSALS,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const getProposal = (qs = null) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}proposal/fetch-proposal/`;

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_PROPOSAL,  
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const deleteProposal = (id) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}proposal/delete/`;

    if (id)
        url += `${id}`

    fetch(url, {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: DELETE_PROPOSAL,  
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const editProposal = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}proposal/edit/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: payload
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: EDIT_PROPOSAL,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};
