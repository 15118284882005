import { BEFORE_PROFILE, UPDATE_PROFILE, GET_ERRORS, GET_PROFILE_DATA, DISCONNECT_USER} from "../types";
import { ENV } from "../../config/config";
import { emptyError } from '../shared/error/error.action';
import AlertModal from '../../components/alertModal/alertModal';

const { address } = ENV.getUserKeys('address');

export const disconnectApp = () => {
    return {
        type: DISCONNECT_USER
    } 
}

export const beforeProfileData = () => {
    return {
        type: BEFORE_PROFILE
    }
}

export const updateProfile = (body, method = 'PUT') => dispatch => {
    dispatch(emptyError());
    // get id from formData
    let id = body.get('_id');
    let userId = id
    const url = `${ENV.url}users/${userId}`;
    fetch(url, {
        method,
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': (localStorage.getItem('accessToken') ? window.atob(localStorage.getItem('accessToken')) : '')
        },
        body
    }).then(res => res.json()).then(data => {
        if (data) {
            ENV.encryptUserData(data.data)
            dispatch({
                type: UPDATE_PROFILE,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};
export const getProfileData = (userId = '') => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}users/${userId}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_PROFILE_DATA,  
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};