import { ADD_VOTE, BEFORE_VOTE, EDIT_VOTE } from "../../redux/types";

const initialState = {
    vote: {}, // vote data
    addVoteAuth: false,
    editVoteAuth: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_VOTE:
            return {
                ...state,
                addVoteAuth: true,
                vote: action.payload,
            }
        case EDIT_VOTE:
            return {
                ...state,
                editVoteAuth: true,
                vote: action.payload,
            }
        case BEFORE_VOTE:
            return {
                ...state,
                vote: {},
                addVoteAuth: false,
                editVoteAuth: false
            }
        default:
            return {
                ...state
            }
    }
}