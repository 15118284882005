import { BEFORE_INVESTORS, INVESTOR_SIGNUP, GET_INVESTORS, CHECK_OTP_VALIDATION } from "../types";

const initialState = {
 
    getInvestorDataAuth: false,
    createInvestorAuth: false,
    checkValidationAuth: false,
    otpValidated: false,
    investors: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_INVESTORS:
        console.log(action.payload)
        return {
            ...state,
            getInvestorDataAuth:true,
            investors:action.payload
        }
        case INVESTOR_SIGNUP:
        return {
            ...state,
            createInvestorAuth: true,
        }
        case CHECK_OTP_VALIDATION:
        return {
            ...state,
            checkValidationAuth: true, 
            otpValidated: action.payload
        }
        case BEFORE_INVESTORS:
            return {
                ...state,
                investors: {},
                getInvestorDataAuth: false,
                createInvestorAuth: false,
                checkValidationAuth: false,
                otpValidated: false,
            }
        default:
            return {
                ...state
            }
    }
}