import React, { useState, useEffect } from "react";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ENV } from "../../config/config";

const ManageGroups = ({ setGroups }) => {

    const [groupNames, setGroupNames] = useState(['council']);
	const [groupCount, setGroupCount] = useState(1)
    const [isValidGroups, setIsValidGroups] = useState(true)
    const [error, setError] = useState([])

    useEffect(()=>{
        let arr = []
        for (let index = 0; index < groupNames.length; index++) {
            const e = groupNames[index];
            if(e){
                arr.push(e)
            }   
        }
        setGroups([...arr])
        checkValidation()
    }, [groupCount, groupNames])
    
    const checkValidation = async() => {
        const isValid = await ENV.isUniqueArray(groupNames)
        if(isValid){
            setIsValidGroups(true)
        }else{
            setIsValidGroups(false)
        }
    }
    const addGroup = (name, index) => {
        name = name.trim()
        if (name.length > 50) {
        }else{
            let group_names = groupNames
            group_names[index]= name
            setGroupNames([...group_names])
        }
    }

    const removeGroup = (val) => {
        let groups_name = groupNames
        const groups = []
        Promise.all(groups_name.map((e, index)=> {
            if(index !== val){
                groups.push(e)
            }
        }))

        setGroupCount(groups.length)
        setGroupNames([...groups])
    }

    const pushGroup = () => {
        setGroupCount((prevValue) => prevValue+1);
        addGroup("", groupCount)
    }

    return (
        <>
            {/* add groups */}
            {
                groupNames.map((item, index)=> {
                    return (
                        <div className='d-flex align-items-center' id={`group_${index}`} key={`group_${index}`}>
                            <div className="input-holder">
                                <input className="form-control mb-2"
                                    placeholder="Group Name"
                                    value={item}
                                    onChange={(e) => {addGroup(e.target.value, index)}}
                                    disabled={index === 0 ? true : false}
                                />
                            </div>
                            {
                                index !== 0  ? <span style={{color:"#2b56f5", cursor: "pointer"}} className="ms-2" onClick={()=> { removeGroup(index);}}><FontAwesomeIcon  icon={faCircleMinus} /></span> : ""
                            }
                        </div>
                    )
                })
            }
            <span style={{fontWeight:"600",marginLeft:"5px", cursor: "pointer"}} onClick={() => {pushGroup()}} > <FontAwesomeIcon  style={{color:"#2b56f5"}}  icon={faCirclePlus } /></span>
            {
                !isValidGroups && <p className='text-danger ms-2 mb-0'>Community must have unique groups.</p>
            }
        </>
    )
}

export default ManageGroups