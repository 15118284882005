import React, { useState, useEffect } from "react";
import './proposal.css'
import { useNavigate } from 'react-router-dom';
import VotingDetail from "../../components/votingDetail/votingDetail";
import Proposal from "../../components/proposal/proposal";
import { useParams } from 'react-router-dom';
import { beforeProposal, getProposal } from '../../redux/proposal/proposal.action'
import { connect } from 'react-redux'
import FullPageLoader from "../../components/full-page-loader/full-page-loader";

function ProposalDetails(props){

    let { slug } = useParams();
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [proposal, setProposal] = useState({})
    const [votes, setVotes] = useState([])
 
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    useEffect(()=> {
        setLoader(true)
        props.beforeProposal()
        props.getProposal(`proposalId=${slug}&fetchVotes=true`)
    }, [])

    useEffect(()=> {
        if(props.proposal.getProposalAuth){
            setLoader(false)
            setProposal(props.proposal?.proposal?.proposal[0])
            setVotes(props.proposal?.proposal?.votes)
        }
    }, [props.proposal.getProposalAuth])
    
    return(
        <>
        {
            loader && <FullPageLoader />
        }
        <div className="proposal-listing">
            <div className="top-heading d-flex justify-content-between flex-wrap">
                <div className="page-header d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-0 mb-md-4">
                    <h2 className="me-0 me-md-2 mb-3 mb-md-0 font-weight-bold">Proposals - Muto Network</h2>
                </div>
               
            </div>
            <div className=" vote-pool">
            <div className="voting"> <div className="content" key={proposal?._id}>
                <Proposal data={proposal}/>
            </div></div>
                <VotingDetail votes={votes}/>
            </div>
        </div>
    </>
    )
}
const mapStateToProps = state => ({
    proposal: state.proposal,
    userData: state.loginKey,
    vote: state.vote
})

export default connect(mapStateToProps, {getProposal, beforeProposal })(ProposalDetails)
