import React from 'react'
import { Images } from '../../assets/assets'
import MessagesBanner from './messagesBanner'

import './messages.css'

const Messages = (props) => {
  return (
    <div className="messsages-section">
        <span className="messages-tag">Private Messages</span>
        <div className="all-messages">
            <MessagesBanner onMessageClick={props.onMessageClick} image={Images.personOne} name="Courtney Henry" time="11:15" para="I will send the document for the testing purpose." />
            <MessagesBanner onMessageClick={props.onMessageClick} image={Images.personTwo} name="Wade Warren" time="11:15" para="I will send the document for the testing purpose." />
            <MessagesBanner onMessageClick={props.onMessageClick} image={Images.personThree} name="Leslie Alexander" time="11:15" para="I will send the document for the testing purpose." />
            <MessagesBanner onMessageClick={props.onMessageClick} image={Images.personFour} name="Darrell Steward" time="11:15" para="I will send the document for the testing purpose." />
            <MessagesBanner onMessageClick={props.onMessageClick} image={Images.personFive} name="Floyd Miles" time="11:15" para="I will send the document for the testing purpose." />
            <MessagesBanner onMessageClick={props.onMessageClick} image={Images.personThree} name="Courtney Henry" time="11:15" para="I will send the document for the testing purpose." />
            <MessagesBanner onMessageClick={props.onMessageClick} image={Images.personOne} name="Floyd Miles" time="11:15" para="I will send the document for the testing purpose." />
        </div>
    </div>
  )
}

export default Messages