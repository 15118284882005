import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Form, FormControl } from 'react-bootstrap'
import smallMissionImage from '../../assets/images/small-mission-img.png';
import './investor-signup.css'
import { Images } from '../../assets/assets';
import { uploadToCloudinary } from '../../utils/upload'
import { beforeInvestors, createInvestor, checkOtpValidation } from "../../redux/investor/investor.action";
import { connect } from 'react-redux';

const InvestorSignUp = (props) => {

    const [data, setData] = useState({ firstName: '', lastName: '', email: '', phone: '', role: '', profileImage: '' })
    const [err, setErr] = useState({})
    const [profileImg, setprofileImg] = useState();
    const [isValidProfileImg, setIsValidProfileImg] = useState(true)
    const [loader, setLoader] = useState(false)
    const [otpCheckLoader, setOptCheckLoader] = useState(false)
    const [investorCreated, setInvestorCreated] = useState(false)
    const [code, setCode] = useState({ one: '', two: '', three: '', four: '', five: '', six: '' });
    const [codeErr, setCodeErr] = useState({ one: false, two: false, three: false, four: false, five: false, six: false })
    const [codeVerified, setCodeVerified] = useState(0)

    const digitOne = useRef()
    const digitTwo = useRef()
    const digitThree = useRef()
    const digitFour = useRef()
    const digitFive = useRef()
    const digitSix = useRef()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (code.one) {
            digitTwo.current.focus()
        }
    }, [code.one])

    useEffect(() => {
        if (code.two) {
            digitThree.current.focus()
        }
    }, [code.two])

    useEffect(() => {
        if (code.three) {
            digitFour.current.focus()
        }
    }, [code.three])

    useEffect(() => {
        if (code.four) {
            digitFive.current.focus()
        }
    }, [code.four])

    useEffect(() => {
        if (code.five) {
            digitSix.current.focus()
        }
    }, [code.five])

    useEffect(() => {
        if (props.investor.createInvestorAuth) {
            setData({ firstName: '', lastName: '', email: '', phone: '', role: '', profileImage: '' })
            setInvestorCreated(true)
            setLoader(false)
        }
    }, [props.investor.createInvestorAuth])

    useEffect(() => {
        if (props.investor.checkValidationAuth) {
            if (props.investor.otpValidated) {
                setCodeVerified(1)
            } else {
                setCodeVerified(2)
            }
            setOptCheckLoader(false)
        }
    }, [props.investor.checkValidationAuth])

    const changeProfileImg = (e) => {
        const file = e.target.files[0]
        setData({ ...data, profileImage: file })
        if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            setIsValidProfileImg(true)
            return new Promise(resolve => {
                let baseURL = "";
                // Make new FileReader
                let reader = new FileReader();

                // Convert the file to base64 text
                reader.readAsDataURL(file);

                // on reader load somthing...
                reader.onload = () => {
                    // Make a fileInfo Object
                    baseURL = reader.result;
                    setData({ ...data, profileImage: baseURL })
                    const imgUrl = URL.createObjectURL(e.target.files[0])
                    setprofileImg(imgUrl)
                    resolve(baseURL);
                };
            });
        } else {
            setIsValidProfileImg(false)
        }
    }

    const validation = () => {
        const error = {}
        let isValid = true;

        if (!data.firstName) {
            error.firstName = "First name is required."
            isValid = false;
        }

        if (!data.lastName) {
            error.lastName = "Last name is required."
            isValid = false;
        }

        if (!data.email) {
            error.email = "Email is required."
            isValid = false;
        }

        if (!data.phone) {
            error.phone = "Phone is required."
            isValid = false;
        }

        if (!data.role) {
            error.role = "Role is required."
            isValid = false;
        }

        setErr({ ...error })
        return isValid;
    }

    const submitForm = async (e) => {
        e.preventDefault()
        setLoader(true)
        if (validation()) {
            if (isValidProfileImg && data?.profileImage) {
                let res = await uploadToCloudinary(data?.profileImage)
                data.profileImage = res.secure_url
            }
            props.beforeInvestors();
            props.createInvestor(data);
        } else {
            setLoader(false)
        }
    }

    const checkCodeValidation = () => {
        const err = { one: false, two: false, three: false, four: false, five: false, six: false }
        let isValid = true
        if (!code.one) {
            err.one = true
            isValid = false
        }
        if (!code.two) {
            err.two = true
            isValid = false
        }
        if (!code.three) {
            err.three = true
            isValid = false
        }
        if (!code.four) {
            err.four = true
            isValid = false
        }
        if (!code.five) {
            err.five = true
            isValid = false
        }
        if (!code.six) {
            err.six = true
            isValid = false
        }
        setCodeVerified(0)
        setCodeErr(err)
        return isValid
    }

    const checkCode = (e) => {
        e.preventDefault();
        console.log('code = ', code);
        setOptCheckLoader(true)
        if (checkCodeValidation()) {
            props.beforeInvestors()
            props.checkOtpValidation({ code: `${code.one}${code.two}${code.three}${code.four}${code.five}${code.six}` })
        } else {
            setOptCheckLoader(false)
        }
    }

    return (
        <>
            <section className='mission-page'>
                {/* <div className='rocket-small-image'>
                    <img className='img-fluid' src={smallMissionImage} alt='image' />
                </div> */}
                <div className="mission-content">
                    {
                        !investorCreated ?
                            <Container>
                                {
                                    codeVerified == 0 || codeVerified == 2 ?
                                        <div className="code-container text-center otp-requirements">
                                            <h3>Verification Code</h3>
                                            <p>Please provide the verification code sent to you</p>

                                            <form onSubmit={(e) => checkCode(e)}>
                                                <div className="code-holder py-3  d-flex justify-content-center mb-3 mb-md-5">
                                                    <div className={`code-block px-1 px-sm-2 ${codeErr.one ? 'code-error' : ''}`}>
                                                        <input ref={digitOne} type="text" placeholder='' maxLength={1} onChange={(e) => setCode({ ...code, one: e.target.value })} />
                                                    </div>
                                                    <div className={`code-block px-1 px-sm-2 ${codeErr.two ? 'code-error' : ''}`} >
                                                        <input ref={digitTwo} type="text" placeholder='' maxLength={1} onChange={(e) => setCode({ ...code, two: e.target.value })} />
                                                    </div>
                                                    <div className={`code-block px-1 px-sm-2 ${codeErr.three ? 'code-error' : ''}`}>
                                                        <input ref={digitThree} type="text" placeholder='' maxLength={1} onChange={(e) => setCode({ ...code, three: e.target.value })} />
                                                    </div>
                                                    <div className={`code-block px-1 px-sm-2 ${codeErr.four ? 'code-error' : ''}`}>
                                                        <input ref={digitFour} type="text" placeholder='' maxLength={1} onChange={(e) => setCode({ ...code, four: e.target.value })} />
                                                    </div>
                                                    <div className={`code-block px-1 px-sm-2 ${codeErr.five ? 'code-error' : ''}`}>
                                                        <input ref={digitFive} type="text" placeholder='' maxLength={1} onChange={(e) => setCode({ ...code, five: e.target.value })} />
                                                    </div>
                                                    <div className={`code-block px-1 px-sm-2 ${codeErr.six ? 'code-error' : ''}`}>
                                                        <input ref={digitSix} type="text" placeholder='' maxLength={1} onChange={(e) => setCode({ ...code, six: e.target.value })} />
                                                    </div>
                                                    
                                                </div>
                                                {codeVerified === 2 ?<p className='text-danger mb-3'>Incorrect OTP, Please try again.</p>: ''}

                                                <div className="d-flex justify-content-center verify">
                                                    <button type='submit' className="btn custom-btn py-2">Verify OTP{otpCheckLoader && <div className="loader me-2"></div>}</button>
                                                </div>
                                            </form>

                                        </div> : ""

                                }
                                {
                                    codeVerified == 1 ?
                                        <div className="form-container text-center pt-5">
                                            <h3>Investor Sign Up</h3>
                                            <p>Please fill the form below to sign up for investor</p>
                                            <Form className="investor-signup-form pt-3 pt-md-5" onSubmit={(e) => submitForm(e)}>

                                                <div className=" mb-lg-0 upload-content-area">
                                                    <div className='upload-area'>
                                                        <div className="picture-holder mb-3">
                                                            <img src={profileImg || Images.Default_Logo} alt='' />
                                                        </div>
                                                        <div className="upload-btn-area">
                                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pencil" class="svg-inline--fa fa-pencil text-white" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M421.7 220.3l-11.3 11.3-22.6 22.6-205 205c-6.6 6.6-14.8 11.5-23.8 14.1L30.8 511c-8.4 2.5-17.5 .2-23.7-6.1S-1.5 489.7 1 481.2L38.7 353.1c2.6-9 7.5-17.2 14.1-23.8l205-205 22.6-22.6 11.3-11.3 33.9 33.9 62.1 62.1 33.9 33.9zM96 353.9l-9.3 9.3c-.9 .9-1.6 2.1-2 3.4l-25.3 86 86-25.3c1.3-.4 2.5-1.1 3.4-2l9.3-9.3H112c-8.8 0-16-7.2-16-16V353.9zM453.3 19.3l39.4 39.4c25 25 25 65.5 0 90.5l-14.5 14.5-22.6 22.6-11.3 11.3-33.9-33.9-62.1-62.1L314.3 67.7l11.3-11.3 22.6-22.6 14.5-14.5c25-25 65.5-25 90.5 0z"></path></svg>
                                                            <input type="file" varient="info" accept=".png,.jpeg,.jpg" onChange={(e) => changeProfileImg(e)} />
                                                        </div>
                                                    </div>
                                                    
                                                    {!isValidProfileImg ? <label className={`text-danger mb-5`}>Logo must be of type jpeg/jpg/png.</label> : ''}

                                                </div>

                                                <Row>
                                                    <Col lg={12}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-4">
                                                                    <FormControl type="text" placeholder='First Name' onChange={(e) => setData({ ...data, firstName: e.target.value })} />
                                                                    {
                                                                        err?.firstName && <p className='text-danger'>{err?.firstName}</p>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-4">
                                                                    <FormControl type="text" placeholder='Last Name' onChange={(e) => setData({ ...data, lastName: e.target.value })} />
                                                                    {
                                                                        err?.lastName && <p className='text-danger'>{err?.lastName}</p>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-4">
                                                                    <FormControl type="email" placeholder='Email' onChange={(e) => setData({ ...data, email: e.target.value })} />
                                                                    {
                                                                        err?.email && <p className='text-danger'>{err?.email}</p>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-4">
                                                                    <FormControl type="tel" placeholder='Phone' onChange={(e) => setData({ ...data, phone: e.target.value })} />
                                                                    {
                                                                        err?.phone && <p className='text-danger'>{err?.phone}</p>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-4">
                                                                    <FormControl type="text" placeholder='Role' onChange={(e) => setData({ ...data, role: e.target.value })} />
                                                                    {
                                                                        err?.role && <p className='text-danger'>{err?.role}</p>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="btn-holder">
                                                                    <button className="btn custom-btn">Submit{loader && <div className="loader me-2"></div>}</button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                        : ""
                                }
                            </Container>
                            :
                            <div className="profile-created">
                                <span>Your profile has been created, successfully!</span>
                            </div>
                    }
                </div>
            </section>
        </>
    )
}

const mapStateToProps = state => ({
    investor: state.investor,
});

export default connect(mapStateToProps, { beforeInvestors, createInvestor, checkOtpValidation })(InvestorSignUp);