import React, { useState, useEffect } from 'react'
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ENV } from '../../config/config'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AddLinks = ({ setLinksValidated, setLinksArray }) => {

    const [links, setLinks] = useState([]);// {url, validated}
    const [linkCount, setLinkCount] = useState(0)

    useEffect(() => {
        pushLink()
    }, [])

    useEffect(() => {
        let arr = []
        setLinksValidated(true)
        for (let index = 0; index < links.length; index++) {
            const e = links[index];
            if (e.url) {
                arr.push(e.url)
            }
            if (e.validationMsg === "Invalid Url") {
                setLinksValidated(false)
            }
        }
        setLinksArray([...arr])

    }, [linkCount, links])

    const addLink = async (url, index) => {
        const validated = await ENV.isValidURL(url)
        let links_url = links
        links_url[index] = { url, validationMsg: validated || !url ? "" : "Invalid Url" }
        setLinks([...links])
    }

    const removeLinks = (val) => {
        let links_url = links
        const urls = []
        Promise.all(links_url.map((e, index) => {
            if (index !== val) {
                urls.push(e)
            }
        }))

        setLinkCount(urls.length)
        setLinks([...urls])
    }

    const pushLink = () => {
        setLinkCount((prevValue) => prevValue + 1);
        addLink("", linkCount)
    }

    return (
        <>
            {/* add links */}
            {
                links.map((item, index) => {
                    return (
                        <div className='d-flex align-items-center' id={`link_${index}`} key={`link_${index}`}>
                            <div className="input-holder">
                                <input className="form-control mb-2"
                                    placeholder="Add Links/Urls"
                                    value={item.url}
                                    onChange={(e) => { addLink(e.target.value, index) }}
                                // disabled={index === 0 ? true : false}
                                />
                                {item.validationMsg && <p className='text-danger ms-2 mb-0'>{item.validationMsg}</p>}
                            </div>

                           
                        <span style={{ color: "#2b56f5", cursor: "pointer" }} className="ms-2" onClick={() => { removeLinks(index); }}><FontAwesomeIcon icon={faCircleMinus} /></span>

                        </div>
                    )
                })
            }
            <span style={{ fontWeight: "600", marginLeft: "5px", cursor: "pointer" }} onClick={() => { pushLink() }} > <FontAwesomeIcon style={{ color: "#2b56f5" }} icon={faCirclePlus} /></span>

        </>
    )
}

export default AddLinks